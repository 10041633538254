.product-top {
  position: relative;
  z-index: 1;
  text-align: center;
  color: $white;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  &.tansome-visual,
  &.tansome-visual .visual {
    background-image: url("../images/product-tansome-visual.png");
  }
  &.tire-visual,
  &.tire-visual .visual {
    background-image: url("../images/tire-visual.jpg");
  }
  &.alkex-visual,
  &.alkex-visual .visual {
    background-image: url("../images/alkex-visual.jpg");
  }
  &.gst-visual,
  &.gst-visual .visual {
    background-image: url("../images/gst-visual.jpg");
  }
  &.carpet-visual,
  &.carpet-visual .visual {
    background-image: url("../images/carpet-visual.jpg");
  }
  &.other-products-visual,
  &.other-products-visual .visual {
    background-image: url("../images/other-products-visual.jpg");
  }
  &.industrial-yarn-visual,
  &.industrial-yarn-visual .visual {
    background-image: url("../images/industrial-yarn-visual.jpg");
  }

  .product-top-text {
    font-size: 26px;
    line-height: 32px;
    font-family: "Brand New Hyosung OTF";
    span {
      font-weight: 200;
      display: block;
      opacity: 0;
      transform: translateY(150px);
    }
    strong {
      font-weight: 700;
      display: block;
      opacity: 0;
      transform: translateY(150px);
    }
    @include media-breakpoint-down(lg) {
      padding: 0 14px;
    }
  }
  .product-top-desc {
    margin-top: 50px;
    .line {
      position: relative;
      display: block;
      width: 48px;
      height: 2px;
      background: $white;
      margin-bottom: 40px;
      left: 50%;
      opacity: 0;
      left: 50%;
      transform: translate(-50%, 150px);
    }
    p {
      color: $white;
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      opacity: 0;
      transform: translateY(150px);
    }
    @include media-breakpoint-down(lg) {
      padding: 0 14px;
    }
  }
  .visual {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @include media-breakpoint-down(lg) {
    background: none !important;
    .visual {
      aspect-ratio: 375/200;
      margin-top: 50px;
    }
    .product-top-text {
      color: $dark-gray-2;
    }
    .product-top-desc {
      .line {
        background: $primary;
      }
      p {
        color: $dark-gray-3;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    height: 1080px;
    .container {
      position: sticky;
      top: 120px;
    }
    .product-top-text {
      font-size: 66px;
      line-height: 79px;
      padding-top: 105px;
      font-family: "Brand New Hyosung OTF";
      font-weight: 300;
    }
    .product-top-desc {
      margin-top: 80px;
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

.product-visual {
  .tire {
  }
}

.product-wrap {
  section {
    padding: 80px 0 100px;
    @include media-breakpoint-up(lg) {
      padding: 160px 0 240px;
    }
  }
}

.business-nav {
  display: flex;
  a {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    height: 100px;
    position: relative;
  }
  strong {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: $white;
    font-size: 14px;
    font-weight: 600;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include media-breakpoint-down(lg) {
    strong {
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%);
      padding: 0 10px;
    }
    span {
      display: none;
    }
  }
  @include media-breakpoint-up(lg) {
    a {
      height: 200px;
    }
    strong {
      font-size: 16px;
      left: auto;
      right: 123px;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      gap: 20px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: $white;
      }
    }
    a:nth-of-type(2) {
      strong {
        left: 123px;
        right: auto;
      }
    }
  }
}

.product-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2875;
  margin-bottom: 50px;
  font-family: "Brand New Hyosung OTF";
  @include media-breakpoint-up(lg) {
    font-size: 48px;
    line-height: 1.1875;
    margin-bottom: 80px;
  }
  &[data-theme="dark"] {
    color: $white;
  }
  &:not(:first-of-type) {
    margin-top: 100px;
    @include media-breakpoint-up(lg) {
      margin-top: 240px;
    }
  }
}

.product-title-desc {
  font-size: 16px;
  color: $dark-gray-3;
  line-height: 1.625;
  margin-top: -30px;
  margin-bottom: 50px;
  @include media-breakpoint-up(lg) {
    font-size: 18px;
    margin-top: -50px;
    margin-bottom: 80px;
  }
}

/* 제품 특장점 */
.product-features {
  background: $dark-gray-1;
  > div {
    padding: 80px 0 100px;
    @include media-breakpoint-up(lg) {
      padding: 180px 0 240px;
    }
  }

  .product-features-2 {
    .product-features-head + .product-features-body {
      @include media-breakpoint-down(lg) {
        margin-top: 40px;
      }
    }
  }
}
.product-features-body {
  width: 100%;
}
.product-features-1 {
  background: $dark-gray-1;
  .product-features-body {
    overflow: hidden;
  }
  @include media-breakpoint-up(lg) {
    .product-features-head {
      position: sticky;
      top: calc(180px + $header-height-lg-expand);
    }
  }
  .product-features-list {
    margin-top: 60px;
    li {
      position: relative;
    }
    .img {
      img {
        width: 100%;
      }
    }
    .text {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: $white;
      strong {
        font-size: 20px;
        line-height: 1.6;
        font-family: "Brand New Hyosung OTF";
      }
      span {
        font-size: 16px;
        line-height: 1.6;
        font-weight: 300;
      }
      @include media-breakpoint-up(lg) {
        gap: 20px;
        strong {
          font-size: 38px;
        }
        span {
          font-size: 18px;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      li:nth-child(2) {
        .img img {
          content: url("../images/tire-1-2-mo.jpg");
        }
      }
      .img {
        aspect-ratio: 339/180;
        img {
          height: 100%;
          object-fit: cover;
        }
        &.case-1 {
          aspect-ratio: 339/258;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      margin-top: 140px;
    }
    &.gst {
      .text-content {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        strong {
          font-family: "Brand New Hyosung OTF";
          font-weight: 700;
          font-size: 38px;
          line-height: 53px;
          color: #ffffff;
        }
        span {
          font-size: 18px;
          line-height: 32px;
          color: #ffffff;
          margin-top: 20px;
        }
        &.right {
          left: inherit;
          right: 0;
        }
      }
      @include media-breakpoint-down(lg) {
        .text-content {
          width: 100%;
          height: 50%;
          top: 0;
          left: 0;
          strong {
            font-size: 20px;
            line-height: 32px;
          }
          span {
            font-size: 16px;
            line-height: 26px;
            margin-top: 10px;
          }
          &.right {
            left: inherit;
            right: 0;
            bottom: 0;
            top: inherit;
          }
        }
        .img {
          aspect-ratio: 339/360;
        }
      }
    }
  }
}
.product-features-2 {
  background: $black;
  .product-features-body {
    overflow: hidden;
  }
  .product-features-head {
    .text {
      line-height: 1.6;
      color: $gray-1;
      p + p {
        margin-top: 16px;
      }
      strong {
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        font-family: "Brand New Hyosung OTF";
      }
      > ul {
        margin-top: 20px;
        margin-bottom: 40px;
        > li {
          font-size: 16px;
          line-height: 1.625;
          color: #fff;
          display: flex;
          &::before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #fff;
            border-radius: 50%;
            flex-shrink: 0;
            margin: 13px;
          }
          &.none {
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .product-features-head {
      position: sticky;
      top: calc(180px + $header-height-lg-expand);
      .text {
        margin-top: 200px;
        strong {
          font-size: 34px;
        }
        > ul {
          margin-top: 30px;
          margin-bottom: 60px;
          > li {
            font-size: 18px;
            line-height: 1.77;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .product-features-body.case-1 {
      margin-top: 20px;
      overflow: unset;
    }
    .product-features-head {
      .text {
        margin-top: 40px;
      }
    }
  }
  .product-features-list {
    .img {
      img {
        width: 100%;
      }
    }
    @include media-breakpoint-down(lg) {
      .img {
        margin-right: -18px;
      }
    }
  }
}
.product-features-3 {
  background: $dark-gray-1;
  &.bg-black {
    background: #000;
  }
  .product-features-body {
    //overflow: hidden;
  }
  @include media-breakpoint-up(lg) {
    .product-features-head {
      position: sticky;
      top: calc(180px + $header-height-lg-expand);
    }
  }
  .feature-head-text {
    width: 50%;
    line-height: 1.6;
    color: $gray-1;
    margin-top: 200px;
    p + p {
      margin-top: 16px;
    }
    strong {
      font-size: 22px;
      font-weight: 600;
      color: #fff;
      font-family: "Brand New Hyosung OTF";
    }
    > ul {
      margin-top: 20px;
      margin-bottom: 40px;
      > li {
        font-size: 16px;
        line-height: 1.625;
        color: #fff;
        display: flex;
        font-weight: 300;
        &::before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background: #fff;
          border-radius: 50%;
          flex-shrink: 0;
          margin: 13px;
        }
        &.none {
          &::before {
            content: none;
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-top: 40px;
    }
    @include media-breakpoint-up(lg) {
      strong {
        font-size: 34px;
      }
      > ul {
        margin-top: 30px;
        margin-bottom: 60px;
        > li {
          font-size: 18px;
          line-height: 1.77;
        }
      }
    }
  }
  .product-features-list {
    margin-top: 40px;
    ul {
      display: flex;
      flex-direction: column;
      gap: 100px;
    }
    .text {
      font-size: 20px;
      line-height: 1.6;
      color: $gray-1;
      strong {
        color: $white;
        font-weight: 600;
      }
      small {
        color: $primary;
        font-size: 14px;
        font-weight: 700;
        margin-left: 10px;
        display: inline-block;
        transform: translateY(-2px);
      }
      p + p {
        margin-top: 16px;
      }
    }
    .img {
      aspect-ratio: 950/480;
      max-width: 950px;
      img {
        width: 100%;
      }
    }
    @include media-breakpoint-down(lg) {
      .text {
        margin-top: 40px;
      }
      .img {
        margin-right: -18px;
        margin-left: -18px;
      }
    }
    @include media-breakpoint-between(lg, xxl) {
      .img {
        max-width: 60%;
      }
    }
    @include media-breakpoint-up(lg) {
      margin-top: 80px;
      gap: 240px;
      .text {
        font-size: 34px;
        line-height: 1.352;
        margin-top: 120px;
        small {
          font-size: 22px;
        }
        p + p {
          margin-top: 40px;
        }
      }
      .img {
        &.long-txt {
          margin-bottom: 500px;
        }
      }
    }
  }
  &.white-bg {
    background-color: #fff;
    padding: 160px 0 80px 0;
    .product-features-title {
      span {
        font-weight: 700;
        font-size: 48px;
        line-height: 57px;
        color: #161618;
        font-family: "Brand New Hyosung OTF";
      }
    }
    .product-features-desc {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
    }
    .product-features-body {
      margin-top: 100px;
    }
    .alkex-features-history {
      margin-top: 120px;
      p {
        font-size: 34px;
        line-height: 46px;
        color: #777777;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          font-weight: 600;
          color: #161618;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 100px 0 0 0;
      .product-features-title {
        span {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 20px;
        }
      }
      .product-features-desc {
        font-size: 16px;
        line-height: 26px;
        p {
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .product-features-body {
        margin-top: 60px;
      }
      .alkex-features-history {
        margin-top: 40px;
        p {
          font-size: 20px;
          line-height: 32px;
          margin-bottom: 16px;
        }
      }
      .product-features-list {
        ul {
          gap: 60px;
        }
        li {
          display: flex;
          align-items: inherit;
          flex-direction: column;
          gap: 30px;
          margin-right: -18px;
          .txt-box {
            flex: 100% 0;
            order: 2;
          }
          .txt-box-title {
            font-size: 18px;
            line-height: 22px;
          }
          .txt-box-desc {
            font-size: 16px;
            line-height: 26px;
          }
          .txt-box-img {
            width: 100%;
            height: 120px;
            max-width: 100%;
            order: 1;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  &.tansome-features-1 {
    background-color: #fff;
    padding: 160px 0 240px;
    .product-features-title {
      span {
        font-weight: 700;
        font-size: 48px;
        line-height: 57px;
        color: #161618;
        font-family: "Brand New Hyosung OTF";
      }
    }
    .product-features-desc {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
    }
    .product-features-list {
      li {
        display: flex;
        align-items: center;
        gap: 13%;
        // .txt-box {
        //   flex: calc(100% - 276px) 0;
        // }
        .txt-box {
          flex: calc(100% - 276px - 13%);
        }
        .txt-box-title {
          font-weight: 600;
          font-size: 28px;
          line-height: 38px;
          color: #161618;
        }
        .txt-box-desc {
          font-size: 18px;
          line-height: 32px;
          color: #333333;
          margin-top: 10px;
        }
        .txt-box-img {
          aspect-ratio: 276/200;
          max-width: 276px;
          img {
            width: 100%;
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 100px 0 0 0;
      .product-features-title {
        span {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 20px;
        }
      }
      .product-features-desc {
        font-size: 16px;
        line-height: 26px;
        p {
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .product-features-list {
        ul {
          gap: 60px;
        }
        li {
          display: flex;
          align-items: inherit;
          flex-direction: column;
          gap: 30px;
          margin-right: -18px;
          .txt-box {
            flex: 100% 0;
            order: 2;
          }
          .txt-box-title {
            font-size: 18px;
            line-height: 22px;
          }
          .txt-box-desc {
            font-size: 16px;
            line-height: 26px;
          }
          .txt-box-img {
            width: 100%;
            height: 120px;
            max-width: 100%;
            order: 1;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

.product-features-4 {
  padding: 100px 0 20px !important;
  .product-introduction-list {
    li {
      gap: 60px;
    }
  }
  .title {
    font-weight: 700;
    color: $dark-gray-1;
    margin-top: 30px;
    font-size: 22px;
    line-height: 32px;
  }
  .desc {
    color: $dark-gray-3;
    margin-top: 20px;
    font-size: 16px;
    line-height: 26px;
  }
  @include media-breakpoint-up(lg) {
    .product-introduction-list {
      li {
        gap: 0;
      }
    }
    .title {
      font-size: 34px;
      line-height: 41px;
    }
    .desc {
      font-size: 18px;
      line-height: 32px;
      margin-top: 30px;
    }
  }
}

.product-features-title {
  font-size: 28px;
  line-height: 1.2875;
  margin-bottom: 40px;
  span {
    display: block;
    color: $primary;
    display: block;
    margin-bottom: 6px;
    font-family: "Brand New Hyosung OTF";
    font-weight: 300;
  }
  strong {
    display: block;
    color: $white;
    font-weight: 600;
    font-family: "Brand New Hyosung OTF";
  }
  @include media-breakpoint-up(lg) {
    font-size: 66px;
    line-height: 79px;
    margin-bottom: 60px;
    span {
      margin-bottom: 16px;
      font-weight: 300;
    }
  }
}
.product-features-desc {
  font-size: 16px;
  line-height: 1.625;
  color: $gray-1;
  font-weight: 500;
  p + p {
    margin-top: 20px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 22px;
    line-height: 26px;
    width: 35.9281437%;
    &.type-1 {
      width: 100%;
    }
    p + p {
      margin-top: 32px;
    }
  }
}

/* 제품소개 */
.product-introduction {
  .nav {
    margin-bottom: 50px;
    position: sticky;
    z-index: 1;
    top: calc($header-height + 48px);
    transition: top 0.5s 0.1s ease;
  }
  @include media-breakpoint-down(lg) {
    html.scroll-down & {
      .nav {
        top: 48px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .nav {
      // margin-bottom: 100px;
      top: $header-height-lg-expand;
    }
  }
}
.product-introduction-list {
  > ul {
    display: flex;
    flex-direction: column;
    gap: 60px;
    .img {
      img {
        width: 100%;
      }
    }
    .text {
      padding-top: 30px;
      .title {
        font-size: 22px;
        line-height: 1.4545;
        font-weight: 700;
        font-family: "Brand New Hyosung OTF";
      }
      .sub {
        font-size: 14px;
        line-height: 1.57;
        font-weight: 500;
        color: $gray-2;
        margin-top: 6px;
      }
      .desc {
        font-size: 16px;
        line-height: 1.6;
        margin-top: 20px;
        color: $dark-gray-3;
        p + p {
          margin-top: 14px;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      gap: 120px;
      .text {
        padding-top: 0;
        .title {
          font-size: 28px;
          line-height: 1.2;
        }
        .sub {
          font-size: 22px;
          margin-top: 10px;
        }
        .desc {
          line-height: 1.7777;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      .text {
        .title {
          font-size: 34px;
        }
        .desc {
          font-size: 18px;
        }
      }
    }
  }
  &[data-theme="dark"] {
    color: $white;
    > ul {
      .text {
        .sub,
        .desc {
          color: $white;
        }
      }
    }
  }
}
.eco-tire-code {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  color: $dark-gray-2;
  margin-top: 40px;
  li {
    width: 100%;
    background: $light-gray-3;
  }
  .item {
    padding: 40px 28px;
  }
  .title {
    font-size: 18px;
    line-height: 1.222;
    margin-bottom: 20px;
    font-weight: 700;
    color: $dark-gray-4;
    .title-sub {
      color: #777777;
      padding-top: 10px;
      font-weight: 300;
      @include media-breakpoint-down(lg){
        font-size: inherit;
        color: #777777;
        padding-top: 10px;
        font-weight: 300;
      }
    }
  }
  .content {
    font-size: 16px;
    line-height: 1.625;
    color: $gray-3;
    margin-bottom: 12px;
  }
  @include media-breakpoint-up(lg) {
    li {
      width: calc((100% - 40px) / 3);
    }
    .item {
      padding: 40px;
    }
    .title {
      font-size: 20px;
    }
  }
  @include media-breakpoint-up(xl) {
    .content {
      font-size: 18px;
    }
  }
}

/* 용도 */
.product-use-list {
  > ul {
    .title {
      font-size: 22px;
      line-height: 1.4545;
      font-weight: 700;
      font-family: "Brand New Hyosung OTF";
    }
    .sub {
      font-size: 14px;
      line-height: 1.57;
      font-weight: 500;
      color: $gray-2;
      margin-top: 6px;
    }
    .desc {
      font-size: 16px;
      line-height: 1.625;
      color: $dark-gray-3;
      margin-top: 20px;
    }
    .img {
      margin-top: 30px;
      width: 100%;
    }
    @include media-breakpoint-down(lg) {
      .img img {
        width: 100%;
      }
    }
    @include media-breakpoint-up(lg) {
      > li {
        &:nth-child(even) {
          margin-top: 140px;
        }
      }
      .title {
        font-size: 34px;
      }
      .sub {
        font-size: 22px;
        margin-top: 10px;
      }
      .desc {
        font-size: 18px;
      }
    }
  }
}

/* 품질과 공급 안정 */
.product-quality {
  background: $dark-gray-3;

  // 에어백
  .another-product {
    margin-top: 80px;
    .col-12 {
      &:last-child {
        .another-product-box {
          border-bottom: 0;
        }
      }
    }
    .another-product-box {
      background-color: #202022;
      padding: 50px 0;
      border-bottom: 1px solid #161618;
    }
    .another-product-title {
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      margin-top: 20px;
      margin-bottom: 0;
    }
    .another-product-txt {
      text-align: center;
      font-weight: 700;
      font-size: 48px;
      line-height: 57px;
      color: #ffffff;
    }
    .another-product-desc {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      margin-top: 10px;
    }
  }
  .move-homepage {
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    margin-top: 50px;
    .btn {
      background-color: #333 !important;
      border: 1px solid #fff;
    }
  }
  @include media-breakpoint-down(lg) {
    .another-product {
      margin-top: 40px;
      .another-product-box {
        padding: 55px 0;
      }
      .another-product-title {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
      }
      .another-product-txt {
        font-size: 42px;
        line-height: 50px;
      }
      .another-product-desc {
        font-size: 18px;
        line-height: 21px;
      }
    }
    .move-homepage {
      gap: 10px;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      margin-top: 30px;
      .btn {
        width: 30px;
        height: 30px;
      }
    }
  }
}

/* 다운로드 센터 */
.product-download-list {
  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    color: $dark-gray-2;
    > li {
      background: $light-gray-3;
      padding: 30px;
      width: 100%;
      min-height: 184px;
      position: relative;
      display: flex;
      flex-direction: column;
    }
    .category {
      font-size: 12px;
      line-height: 1.8;
      margin-bottom: 10px;
      color: $gray-3;
    }
    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5714;
      margin-bottom: 20px;
      color: $dark-gray-2;
    }
    .date {
      font-size: 12px;
      color: $dark-gray-4;
    }
    .btn-download {
      width: 60px;
      height: 60px;
      border: 1px solid #ddd;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @include media-breakpoint-up(lg) {
      > li {
        width: calc((100% - 60px) / 4);
        aspect-ratio: 1/1;
      }
      .category {
        font-size: 14px;
      }
      .title {
        font-size: 20px;
      }
      .date {
        font-size: 14px;
      }
      .btn-download {
        width: 48px;
        height: 48px;
      }
    }
    @include media-breakpoint-up(xl) {
      > li {
        padding: 50px 40px;
      }
    }
  }
}

/* 관련 뉴스 */
.product-news {
  padding-top: 0 !important;
}
.product-news-list {
  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    > li {
      width: 100%;
    }
    a {
      display: block;
    }
    .thumb {
      aspect-ratio: 339/191;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text {
      padding: 20px 0;
      .title {
        font-weight: 700;
        color: $dark-gray-2;
        @include ellipsis-multiline(20, 1.3, 2, false);
      }
      .content {
        color: $gray-3;
        margin-top: 18px;
        @include ellipsis-multiline(14, 1.5714, 2, false);
      }
    }
    @include media-breakpoint-up(lg) {
      gap: 20px;
      > li {
        width: calc((100% - 40px) / 3);
        gap: 20px;
      }
      .text {
        padding: 30px 0 26px;
        .title {
          @include ellipsis-multiline(20, 1.2, 2, false);
        }
        .content {
          margin-top: 18px;
          @include ellipsis-multiline(16, 1.5, 2, false);
        }
      }
    }
  }
  .bottom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    @include media-breakpoint-up(lg) {
      margin-top: 80px;
    }
  }
}

/* 최하단 제품 Navigation 버튼 */

/* 담당자 문의 */
.product-contact {
  height: 460px;
  padding: 0 18px;
  background: #000 url("../images/product-contact-bg-mobile.jpg") no-repeat 50%
    50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 70px;
  p {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.416;
    color: #fff;
    font-family: "Brand New Hyosung OTF";
    text-align: center;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 20%;
    .btn {
      display: block;
      width: 100%;
    }
  }
  @include media-breakpoint-up(lg) {
    height: 400px;
    gap: 48px;
    background: #000 url("../images/product-contact-bg.jpg") no-repeat 50% 50%;
    p {
      font-size: 32px;
    }
  }
}

// 최적화된 글로벌 생산

.product-overseas {
  .product-overseas-content {
    display: flex;
    align-items: center;
    gap: 20px;
    .overseas-list {
      width: calc(33.3% - 12px);
    }
    .overseas-title {
      font-weight: 700;
      font-size: 38px;
      line-height: 53px;
      color: #161618;
      margin-bottom: 30px;
      font-family: "Brand New Hyosung OTF";
    }
  }
  .global-homepage {
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #161618;
    margin-top: 40px;
  }
}
@include media-breakpoint-down(md) {
  .product-overseas {
    padding: 80px 0 0 !important;
    .product-overseas-content {
      width: auto;
      height: 100%;
      white-space: nowrap;
      overflow-x: auto;
      gap: 16px;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: 0 18px;
      .overseas-list {
        width: 80%;
        flex: 0 0 auto;
      }
      .overseas-title {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 20px;
      }
    }
  }
  .product-overseas-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    margin-left: -18px;
    margin-right: -18px;
  }
}

// 추가
.product-features-body {
  &.case-1 {
    overflow: hidden;
  }
}

.offset-lg-5 {
  &.case-1 {
    margin-left: 0;
    padding-left: 41.66666667%;

    @include media-breakpoint-down(lg) {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}

.offset-lg-6 {
  &.case-1 {
    margin-left: 0;
    padding-left: 50%;

    @include media-breakpoint-down(lg) {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}
