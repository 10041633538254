.page-top {
  position: relative;
  display: flex;
  flex-direction: column;
  .inner {
    position: relative;
    width: 100%;
    height: 100vh;
    @include media-breakpoint-down(lg) {
      height: 467px;
    }
  }
  .txt-box {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .txt {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 180px 0 0;
    transition: color 0.5s;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: $dark-gray-3;
    animation: clip-height 2s linear forwards paused;
    animation-delay: calc(var(--progress) * -1.8s);
    &.position-static {
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 0;
      animation-delay: calc(var(--progress) * -1.25s);
    }
    .page-top-text {
      margin-bottom: 80px;
      font-size: 28px;
      font-weight: 200;
      line-height: 38px;
      text-align: left;
      font-family: "Brand New Hyosung OTF";
      span {
        display: block;
        opacity: 0;
        transform: translateY(150px);
      }
      strong {
        display: block;
        font-weight: 700;
        opacity: 0;
        transform: translateY(150px);
        font-family: 'Brand New Hyosung OTF';
      }
      @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 50px;
        color: #000;
        span {
          font-weight: 500;
        }
        &.type-1 {
          font-size: 20px;
          line-height: 32px;
        }
      }
      @include media-breakpoint-down(md) {
        strong {
          font-weight: 700;
        }
      }
    }
    .page-top-desc {
      display: inline-block;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      @include media-breakpoint-down(lg) {
        // padding-left: 18px;
        font-size: 14px;
        line-height: 22px;
      }
      .line {
        display: block;
        background-color: $primary;
        width: 48px;
        height: 2px;
        margin-bottom: 40px;
        opacity: 0;
        transform: translateY(150px);
      }
      p {
        opacity: 0;
        transform: translateY(150px);
      }
    }
    &:nth-child(1) {
      z-index: 20;
      background: $white;
      color: $dark-gray-3;
    }
    &:nth-child(2) {
      z-index: 10;
      background-repeat: no-repeat;
      background-position: 50% 100%;
      background-size: auto 100vh;
      color: $white;
      background-image: url("../images/ir-visual.png");
      @include media-breakpoint-down(lg) {
        background-size: auto 467px;
      }
      .page-top-text {
        strong,
        span {
          color: $white;
        }
      }
      .page-top-desc {
        .line {
          background-color: $white;
        }
      }
    }
  }
  &[data-theme="dark"] {
    .txt {
      .page-top-text {
        strong {
          color: $white;
        }
      }
      .page-top-desc {
        p {
          color: $white;
          &.type-1 {
            color: #aaaaaa;
          }
        }
      }
    }
  }
  &.page-top-ir {
    .txt:nth-child(2) {
      background-image: url("../images/ir-visual.png");
    }
    @include media-breakpoint-down(lg) {
      .inner {
        height: auto;
      }
      .txt {
        position: static;
        height: auto;
        &:nth-child(1) {
          --progress: 0 !important;
        }
        &:nth-child(2) {
          aspect-ratio: 375/290;
          margin-top: 50px;
          background-image: url("../images/ir-visual-mo.jpg");
          background-size: auto 100%;
          .container {
            display: none;
          }
        }
      }
    }
  }
  &.page-top-recruitment {
    .txt:nth-child(2) {
      background-image: url("../images/job-introduction-banner.png");
    }
  }
  &.talent {
    padding-bottom: 600px;
    .img-box {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @include media-breakpoint-down(lg) {
      padding-bottom: 250px;
      .img-wrap {
        height: 200px;
      }
      .img-box {
        margin-bottom: 50px;
        height: 200px;
      }
    }
  }
  .img-wrap {
    width: 100%;
    height: 450px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .img-box {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: auto;
  }
}
@keyframes clip-height {
  0% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    /* 엘리먼트 박스 모양 변경 */
  }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    /* 엘리먼트 박스 모양 변경 */
  }
}
