.lifestory-container {
  color: $dark-gray-3;
  .body-scroll {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  .lifestory-section {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
  }

  .section01 {
    padding-bottom: 54px;
    padding-top: 120px;
    margin-top: -80px;
    .container {
      position: relative;
      height: 100%;
    }
    .content-01 {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      height: 100%;
      z-index: 3;
      transition: all 0.7s ease;
    }
    .content-02 {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      height: 100%;
      z-index: 2;
      transition: all 0.7s ease;
    }

    .content-03 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      .sentence {
        position: relative;
        display: inline-block;
        width: 433px;
        height: 100%;
        text-align: left;
      }
      strong {
        position: relative;
        top: 50%;
        display: inline-block;
        font-weight: 700;
        font-size: 80px;
        line-height: 95px;
        color: $dark-gray-2;
        transform: translateY(200%);
        opacity: 0;
        transition: all 0.8s ease;
        font-family: "Brand New Hyosung OTF";

        white-space: nowrap;
      }
      .text-02 {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        display: inline-block;
        margin-top: 340px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        opacity: 0;
        transform: translateY(-50%);
        transition: all 0.8s ease;
      }
    }
    &.action01 {
      .h1-par {
        margin-top: 500px;
        opacity: 1;
      }
      .text-01 {
        opacity: 1;
        font-size: 16px;
      }
    }
    &.action02 {
      .content-01 {
        opacity: 0;
        top: -60vh;
      }
      .content-02 {
        opacity: 0;
        top: -80vh;
      }
      .content-03 {
        transition: all 0.7s ease;
        strong {
          transform: translateY(-50%);
          opacity: 1;
        }
        .text-02 {
          margin-top: 140px;
          opacity: 1;
        }
      }
    }
    &.action03 {
      .content-02 {
        top: -100vh;
      }
      .content-03 {
        width: 45%;
      }
      .section02 {
        left: 45%;
      }
    }

    .content-head {
      transition: all 0.8s ease;
    }
    .h1-par {
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      margin-top: 700px;
      transition: all 1s ease;
      opacity: 0;
      font-family: "Brand New Hyosung OTF";
      color: #161618;
    }
    .text-01 {
      position: relative;
      padding-top: 42px;
      margin-top: 80px;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      opacity: 0;
      width: 288px;
      margin-left: 113px;
      color: #333333;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 48px;
        height: 2px;
        background-color: $red;
      }
    }
    .inner-section {
      position: absolute;
      left: 100%;
      top: 0;
      width: 100vw;
      box-sizing: border-box;
      height: 100vh;
      padding-top: 120px;
      overflow: hidden;
      transition: all 0.8s ease;
      display: flex;
      background-color: $dark-gray-1;
      &.action01 {
        left: 0%;
        .img-list {
          opacity: 1;
        }
        .title {
          width: 50vw;
          strong {
            opacity: 1;
            left: 50%;
          }
        }
      }
      &.action02 {
        .title strong {
          opacity: 0;
        }
        .img-list {
          margin-left: -50vw;
          li:hover,
          li:focus {
            width: 45vw;
            &:after {
              background-position: 100% 50%;
            }
            &:before {
              opacity: 0.6;
            }
            .inner {
              left: 100px;
              transform: translateX(0%);
              .inner-strong {
                display: inline-block;
              }
              .text-wrap {
                margin-right: 0px;
                opacity: 1;
              }
            }
          }
        }
      }
      .title {
        position: relative;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: 100%;
        width: 70vw;
        transition: all 0.8s ease;
        z-index: 0;
        strong {
          position: absolute;
          left: 80%;
          font-weight: 700;
          font-size: 38px;
          line-height: 53px;
          color: $white;
          transform: translateX(-50%);
          white-space: nowrap;
          opacity: 0;
          transition: all 0.8s ease;
          font-family: "Brand New Hyosung OTF";
        }
      }
      .img-list {
        position: relative;
        z-index: 1;
        opacity: 0;
        transition: all 0.8s ease;
        ul {
          display: flex;
          width: 100vw;
          height: 100%;
          overflow: hidden;
        }
        li {
          position: relative;
          width: 25vw;
          height: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          transition: all 0.8s ease;
          &:before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: 0.3;
            z-index: 1;
            transition: all 0.8s ease;
          }
          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 50vw;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 250px;
            background-size: cover;
            z-index: 0;
            transition: all 0.8s ease;
          }
        }
        .inner {
          position: relative;
          z-index: 2;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.8s ease;
          strong {
            font-weight: 700;
            font-size: 58px;
            line-height: 68px;
            color: $white;
            font-family: "Brand New Hyosung OTF";
          }
          .inner-strong {
            display: none;
          }
          .text-wrap {
            position: absolute;
            opacity: 0;
            transition: all 0.8s ease;
            margin-right: -100px;
            p {
              font-weight: 700;
              font-size: 36px;
              line-height: 43px;
              color: $white;
              margin-top: 60px;
              font-family: "Brand New Hyosung OTF";
              &.desc {
                width: 422px;
                font-size: 24px;
                line-height: 35px;
                color: $white;
                margin-top: 30px;
                font-weight: 400;
                font-family: 'Pretendard';
              }
            }
          }
        }
      }
    }
    .section02 {
      .img-list {
        li {
          &:nth-child(1) {
            &:after {
              background-image: url("../images/lifestyle-section02-img01.png");
            }
          }
          &:nth-child(2) {
            &:after {
              background-image: url("../images/lifestyle-section02-img02.png");
              background-position: 400px;
            }
          }
          &:nth-child(3) {
            &:after {
              background-image: url("../images/lifestyle-section02-img03.png");
            }
          }
          &:nth-child(4) {
            &:after {
              background-image: url("../images/lifestyle-section02-img04.png");
            }
          }
        }
      }
      &.action02 {
        .img-list {
          li:nth-child(2) {
            &:hover,
            &:focus {
              &:after {
                background-position: 100px 50%;
              }
            }
          }
        }
      }
    }

    .section03 {
      z-index: 1;
      .img-list {
        li {
          &:nth-child(1) {
            &:after {
              background-image: url("../images/lifestyle-section03-img01.png");
            }
          }
          &:nth-child(2) {
            &:after {
              background-image: url("../images/lifestyle-section03-img02.png");
            }
          }
          &:nth-child(3) {
            &:after {
              background-image: url("../images/lifestyle-section03-img03.png");
            }
          }
          &:nth-child(4) {
            &:after {
              background-image: url("../images/lifestyle-section03-img04.png");
            }
          }
        }
      }
    }

    .section04 {
      z-index: 2;
      .img-list {
        li {
          &:nth-child(1) {
            &:after {
              background-image: url("../images/lifestyle-section04-img01.png");
            }
          }
          &:nth-child(2) {
            &:after {
              background-image: url("../images/lifestyle-section04-img02.png");
            }
          }
          &:nth-child(3) {
            &:after {
              background-image: url("../images/lifestyle-section04-img03.png");
            }
          }
          &:nth-child(4) {
            &:after {
              background-image: url("../images/lifestyle-section04-img04.png");
            }
          }
        }
      }
    }

    .section05 {
      z-index: 2;
      .img-list {
        li {
          &:nth-child(1) {
            &:after {
              background-image: url("../images/lifestyle-section05-img01.png");
            }
          }
          &:nth-child(2) {
            &:after {
              background-image: url("../images/lifestyle-section05-img02.png");
            }
          }
          &:nth-child(3) {
            &:after {
              background-image: url("../images/lifestyle-section05-img03.png");
            }
          }
          &:nth-child(4) {
            &:after {
              background-image: url("../images/lifestyle-section05-img04.png");
            }
          }
        }
      }
    }
  }

  .section06 {
    position: relative;
    height: 100vh;
    color: $white;
    height: 100vh;
    &.action01 {
      .text-01 {
        opacity: 1;
      }
      .bg {
        background-position: 100% 100%;
      }
    }
    &.action02 {
      .bg {
        background-position: 100% 100%;
      }
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 1;
      transition: all 0.8s ease;
      background: url("../images/lifestyle-section06-bg2.png") no-repeat 100%
        0px;
      background-size: cover;
    }
    .container {
      position: relative;
      z-index: 2;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text-01 {
      text-align: center;
      font-weight: 700;
      font-size: 38px;
      line-height: 53px;
      opacity: 0;
      transition: all 0.8s ease;
      font-family: "Brand New Hyosung OTF";
    }
  }

  @media (max-width: 1873px) {
    .section01 {
      .section02 {
        &.inner-section {
          .img-list {
            li:nth-child(2) {
              &:after {
                background-position: 250px;
              }
              @include media-breakpoint-down(lg) {
                &:after {
                  background-position: 80% 50%;
                }
              }
            }
          }
        }
      }
      .inner-section {
        .img-list {
          .inner {
            strong {
              font-size: 40px;
            }
            .text-wrap {
              p {
                font-size: 30px;
                &.desc {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .section01 {
      padding-bottom: 0px;
      padding-top: 0px;
      margin-top: 0px;
      overflow: hidden;
      height: auto;
      .container {
        height: calc(100vh - 108px);
      }
      .content-01,
      .content-02 {
        padding-left: 18px;
        padding-right: 18px;
      }

      .content-03 {
        position: relative;
        color: $white;
        .sentence {
          width: 100%;
          height: calc(50vh);
          padding-left: 18px;
          padding-right: 18px;
        }
        strong {
          font-size: 42px;
          line-height: 48px;
          color: $white;
        }
        .text-02 {
          left: 18px;
          color: $white;
          width: 100%;
          margin-top: 20px;
          font-size: 14px;
          line-height: 22px;
        }
      }
      &.action01 {
        .h1-par {
          margin-top: 240px;
        }
        .text-01 {
        }
      }
      &.action02 {
        .content-03 {
          margin-top: -60vh;
          background-color: $dark-gray-1;
          transition-property: margin-top;
          .text-02 {
            margin-top: 75px;
          }
        }
      }
      &.action03 {
        .content-02 {
          top: -100vh;
        }
        .content-03 {
          width: 100%;
        }
        .section02 {
          left: 0%;
        }
      }

      .h1-par {
        width: 100%;
        font-size: 26px;
        line-height: 120%;
        strong {
          display: block;
        }
      }
      .text-01 {
        position: relative;
        margin-top: 50px;
        font-size: 14px;
        line-height: 22px;
        width: 100%;
        margin-left: 0px;
      }
      .inner-section {
        position: relative;
        width: 100vw;
        height: auto;
        padding-top: 0px;
        display: block;
        background-color: $dark-gray-1;
        top: auto;
        left: 0;
        overflow: visible;
        &.action01 {
          left: 0%;
          .img-list {
            opacity: 1;
          }
          .title {
            width: 45vw;
            strong {
              opacity: 1;
              left: 50%;
            }
          }
        }
        &.action02 {
          .title strong {
            opacity: 0;
          }
          .img-list {
            margin-left: -35vw;
            &:hover {
              margin-left: -45vw;
            }
            li:hover,
            li:focus {
              width: 50vw;
              &:after {
                background-position: 100% 50%;
              }
              &:before {
                opacity: 0.6;
              }
              .inner {
                left: 100px;
                transform: translateX(0%);
                p {
                  margin-right: 0px;
                  opacity: 1;
                }
              }
            }
          }
        }
        .title {
          align-items: flex-start;
          width: 100vw;
          height: auto;
          padding: 100px 0 80px;
          strong {
            position: relative;
            left: 18px;
            font-size: 34px;
            line-height: 41px;
            transform: translateX(0%);
            opacity: 1;
          }
        }
        .img-list {
          width: 100vw;
          opacity: 1;
          ul {
            display: flex;
            flex-wrap: wrap;
            width: 100vw;
            height: 100%;
          }
          li {
            width: 50vw;
            height: 440px;
            display: flex;
            align-items: center;
            overflow: hidden;
            flex-shrink: 0;
            &:before {
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: $black;
              opacity: 0.3;
              z-index: 1;
              transition: all 0.8s ease;
            }
            &:after {
              height: 100%;
              background-position: 80% 50%;
              background-size: cover;
              z-index: 0;
            }
          }
          .inner {
            left: 50%;
            transform: translateX(-50%);
            padding: 0 36px;
            text-align: center;
            strong {
              font-size: 34px;
              line-height: 41px;
            }

            .text-wrap {
              position: relative;
              opacity: 1;
              margin-right: 0;
              p {
                font-size: 20px;
                line-height: 26px;
                margin-top: 20px;
                &.desc {
                  width: 100%;
                  font-size: 14px;
                  line-height: 22px;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }

    .section06 {
      height: 600px;
      .bg {
        height: 100%;
        background: url("../images/lifestyle-section06-bg2-mo.png") no-repeat
          100% 100%;
        background-size: cover;
      }
      .text-01 {
        font-size: 24px;
        line-height: 34px;
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .section01 {
      .inner-section {
        .img-list {
          width: 100vw;
          opacity: 1;
          ul {
            width: 100vw;
            overflow: visible;
            flex-direction: column;
            flex-wrap: nowrap;
          }
          li {
            width: 100vw;
            &:after {
              width: 100vw;
            }
          }
        }
      }

      .section02 {
        .img-list {
          li {
            &:nth-child(1) {
              &:after {
                transform: scale(1.5);
              }
            }
          }
        }
      }
    }
  }
}
