.content-head {
  width: 100%;
  padding: 170px 0 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @at-root {
    .breadcrumb {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      gap: 16px;
      .breadcrumb-item {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: $gray-2;
        position: relative;
        &.active {
          color: $primary !important;
        }

        &::after {
          content: "/";
          position: absolute;
          right: -10px;
        }

        &:last-child {
          &::after {
            content: none;
          }
        }
      }
    }
    .content-title {
      font-size: 80px;
      line-height: 96px;
      font-weight: 700;
      color: $dark-gray-1;
      position: relative;
      font-family: "Brand New Hyosung OTF";
      .up-text {
        font-size: 40px;
        line-height: 48px;
        vertical-align: text-top;

        @include media-breakpoint-down(lg) {
          font-size: 12px;
          line-height: 15px;
          vertical-align: text-top;
        }
      }
    }
  }
  &[data-theme] {
    background-color: $dark-gray-1;
    .breadcrumb-item {
      color: #e0e0e0;
    }
    .content-title {
      color: $white;
    }
  }
}
@include media-breakpoint-down(lg) {
  .content-head {
    padding: 100px 0;
    &.case-1 {
      padding: unset;
      padding-top: 25px;
    }
    @at-root {
      .breadcrumb {
        display: none;
        align-items: center;
        margin-bottom: 28px;
        gap: 16px;
        .breadcrumb-item {
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          color: $gray-2;
          position: relative;
          &::after {
            content: "/";
            position: absolute;
            right: -10px;
          }
        }
      }
      .content-title {
        font-size: 34px;
        line-height: 40px;

        text-align: center;
      }
    }
  }
}
