.btn {
  display: inline-block;
  font-weight: 600;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 11px 26px;
  font-size: 0.875rem;
  line-height: 23px;
  font-size: 19px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.round-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;

    // 라운드 버튼이랑 일반 버튼이랑 배경 보더 컬러가 달라서 분리 ----------
    &.btn-download {
      background-color: transparent !important;
      border-color: $light-gray-1 !important;
      &:hover {
        background-color: $white !important;
        border-color: $white !important;
        .icon-download {
          background-image: url("../images/icon-download-hover.svg");
        }
      }
    }
    &.btn-outline-dark {
      color: $dark-gray-2 !important;
      background-color: $light-gray-3 !important;
      border-color: $dark-gray-2 !important;
    }
    // ---------------------------------------------------------------
  }
  &.btn-download {
    background-color: $white !important;
    border-color: $light-gray-1 !important;
    display: inline-flex;
    align-items: center;
    &:hover {
      background-color: $primary !important;
      color: $white !important;
      border-color: $primary !important;
      .icon-download {
        background-image: url("../images/icon-download-hover-white.svg");
      }
    }
  }
  &.btn-sm {
    padding: 8px 24px;
    max-height: 40px;
    font-size: 14px;
  }
  &.btn-md {
    padding: 12px 26px;
    max-height: 48px;
    font-size: 16px;
  }
  &.btn-lg {
    padding: 19px 40px;
    max-height: 60px;
    font-size: 18px;
    line-height: 21px;
  }

  &.btn-primary {
    color: $white;
    background-color: $primary;
    border-color: $primary;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
  &.btn-outline-primary {
    color: $primary;
    background-color: $white;
    border-color: $primary;
    &:hover {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
    &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }
  &.btn-gray {
    color: $white !important;
    background-color: $light-gray-3 !important;
    border-color: $light-gray-3 !important;
    &:hover {
      background-color: $light-gray-3 !important;
    }
  }
  &.btn-outline-gray {
    color: $dark-gray-2 !important;
    background-color: $white !important;
    border-color: $light-gray-1 !important;
    &:hover {
      color: $white !important;
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }
  &.btn-dark {
    color: $white !important;
    background-color: $dark-gray-1 !important;
    border-color: $dark-gray-1 !important;
    &.round-btn {
      &:hover {
        color: $white !important;
        background-color: $primary !important;
        border-color: $primary !important;
        .icon-left-arrow {
          background-image: url("../images/icon-w-left-arrow.svg");
        }
        .icon-right-arrow {
          background-image: url("../images/icon-w-right-arrow.svg");
        }
        .icon-close {
          background-image: url("../images/icon-w-close.svg");
        }
        .icon-top-arrow {
          background-image: url("../images/icon-w-top-arrow.svg");
        }
        .icon-plus {
          background-image: url("../images/icon-w-plus.svg");
        }
      }
    }
    &:hover {
      background-color: $dark-gray-1 !important;
      color: $white !important;
      border-color: $dark-gray-1 !important;
    }
    &.active {
      color: $white !important;
      background-color: $dark-gray-1 !important;
      border-color: $dark-gray-1 !important;
    }
  }
  &.btn-outline-dark {
    color: $dark-gray-2 !important;
    background-color: $white !important;
    border-color: $dark-gray-2 !important;
    &:hover {
      color: $dark-gray-2 !important;
    }
  }
  &.btn-white {
    color: $light-gray-1 !important;
    background-color: $white !important;
    border-color: $light-gray-1 !important;
    &:hover {
      color: $white !important;
      background-color: $primary !important;
      border-color: $primary !important;
      .icon-left-arrow {
        background-image: url("../images/icon-w-left-arrow.svg");
      }
      .icon-right-arrow {
        background-image: url("../images/icon-w-right-arrow.svg");
      }
      .icon-close {
        background-image: url("../images/icon-w-close.svg");
      }
      .icon-top-arrow {
        background-image: url("../images/icon-w-top-arrow.svg");
      }
      .icon-plus {
        background-image: url("../images/icon-w-plus.svg");
      }
    }
  }
  &.btn-outline-white {
    color: $white !important;
    background-color: transparent !important;
    border-color: $white !important;
    &:hover {
      color: $white !important;
    }
    &.case-1 {
      &:hover {
        color: $white !important;
        background-color: $primary !important;
        border-color: $primary !important;
      }
    }
  }
  &:disabled {
    color: $white !important;
    border-color: $gray-1 !important;
    background-color: $gray-1 !important;
    cursor: default;
    &:hover {
      color: $white !important;
      border-color: $gray-1 !important;
      background-color: $gray-1 !important;
    }
  }
  &.disabled {
    color: $gray-1 !important;
    border-color: $gray-2 !important;
    background-color: $gray-2 !important;
    &:hover {
      color: $gray-1 !important;
      border-color: $gray-2 !important;
      background-color: $gray-2 !important;
    }
  }
}
@include media-breakpoint-down(lg) {
  .btn {
    &.round-btn {
      &.plus {
        width: 30px;
        height: 30px;
      }
      &.minus {
        width: 30px;
        height: 30px;
      }
    }
    &.btn-md {
      max-height: 44px;
      font-size: 14px;
      line-height: 100%; //230705 홍수기 추가
      font-weight: 600;
    }
    &.btn-lg {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      &.type-1 {
        text-align: left;
        max-height: unset;
        padding: 13px 11.4%;
      }
    }
  }
}

.btn-flex-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  @include media-breakpoint-down(lg){
    flex-direction: column;
  }
}