//통합검색
.total-search-top {
  padding: 140px 0 115px;
  background-color: $dark-gray-1;
  .total-search-box {
    position: relative;
    width: 658px;
    padding: 0 10px 24px 10px;
    border-bottom: 3px solid $dark-gray-4;
    .search-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 68px);
    }
    .search-text {
      width: calc(100% - 41px);
      font-weight: 700;
      font-size: 32px;
      color: $white;
      line-height: 38px;
      background-color: transparent;
      border: 0;
      outline: none;
    }
    .icon-btn-del {
      display: none;
      width: 24px;
      height: 24px;
      background: url("../images/icon-total-srch-del.svg");
    }
    .btn-search {
      position: absolute;
      right: 10px;
      bottom: 24px;
      width: 38px;
      height: 38px;
      background: url("../images/icon-total-srch.png");
      background-size: 100%;
    }
  }
}
.total-head {
  padding: 40px 0 100px;
}
@include media-breakpoint-down(lg) {
  .total-search-top {
    padding: 110px 0 50px;
    background-color: $dark-gray-1;
    .total-search-box {
      width: calc(100% - 36px);
      padding: 0 10px 12px 10px;
      .search-label {
        width: calc(100% - 44px);
        height: 24px;
      }
      .search-text {
        font-size: 18px;
        line-height: 21px;
      }
      .btn-search {
        bottom: 13px;
        width: 24px;
        height: 24px;
      }
    }
  }
  .total-head {
    padding: 50px 0 100px;
  }
}

.total-search-wrap {
  .search-tab {
    margin-bottom: 100px;
    .nav-tabs {
      &.normal-type {
        gap: 12px;
        justify-content: center;
        .nav-item {
          .nav-link {
            padding: 8px 24px;
            border-radius: 20px;
            &.active {
              background-color: $dark-gray-1;
              color: #fff;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .search-tab {
      height: 35px;
      padding: 0 18px;
      overflow-x: scroll;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .nav-tabs {
        display: block;
        width: auto;
        height: 100%;
        padding-right: 20rem;
        white-space: nowrap;
        overflow-x: auto;
        &.normal-type {
          gap: 0;
          justify-content: flex-start;
          .nav-item {
            display: inline-block;
            width: auto;
            .nav-link {
              padding: 8px 16px;
            }
          }
        }
      }
    }
  }
  .total-search-contents {
    padding-bottom: 180px;
    .title {
      margin-bottom: 30px;
      color: $dark-gray-1;
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      font-family: "Brand New Hyosung OTF";
      span {
        display: inline-block;
        margin-left: 10px;
        font-weight: 500;
        font-family: 'Pretendard';
      }
    }
    .result-page {
      margin-top: 100px;
      padding-top: 50px;
      border-top: 2px solid $dark-gray-4;
      ul {
        li {
          margin-top: 15px;
          padding: 40px 60px;
          background-color: $light-gray-3;
          a {
            span {
              color: #ff233d;
            }
            p {
              font-size: 24px;
              line-height: 35px;
              font-weight: 700;
            }
            .desc-title {
              margin-bottom: 19px;
              color: #161618;
              &::after {
                content: "";
                display: inline-block;
                vertical-align: inherit;
                background: url("../images/icon_outlink.svg");
                width: 16px;
                height: 16px;
                margin-left: 6px;
              }
            }
            .desc {
              display: -webkit-box;
              word-break: break-word;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              color: #444444;
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
            }
            // &:hover{
            //   background-color: $light-gray-3;
            //   border-color:$light-gray-2;
            // }
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      padding-bottom: 200px;
      .title {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 34px;
        span {
          margin-left: 6px;
        }
      }
      .result-page {
        padding-top: 40px;
        ul {
          li {
            margin-top: 7px;
            padding: 20px;
            a {
              p {
                font-size: 14px;
                line-height: 22px;
              }
              .desc-title {
                margin-bottom: 3px;
                &::after {
                  background: url("../images/icon_outlink-mo.svg");
                  width: 8px;
                  height: 8px;
                  margin-left: 3px;
                }
              }
              .desc {
                -webkit-line-clamp: 4;
                font-size: 12px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
    .result-brand {
      /*margin-top:100px;*/
      padding-top: 50px;
      border-top: 2px solid $dark-gray-4;
      li {
        margin-bottom: 50px;
        .img {
          position: relative;
        }
        .date {
          position: absolute;
          bottom: 20px;
          right: 20px;
          color: $white;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
        }
        .box {
          padding: 30px 79px 0 0;
          .card-title {
            margin: 0 0 10px 0;
            font-weight: 700;
            font-size: 20px;
            line-height: 160%;
            color: $dark-gray-3;
          }
          .card-text {
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $gray-3;
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .result-brand {
        padding-top: 40px;
        li {
          margin-bottom: 66px;
          .img {
            width: 100%;
            img {
              width: 100%;
              -o-object-fit: cover;
              object-fit: cover;
            }
          }
          .date {
            bottom: 18px;
            right: 18px;
            font-size: 13px;
            line-height: 16px;
          }
          .box {
            padding: 20px 40px 0 0;
            .card-title {
              margin: 0 0 18px 0;
              line-height: 26px;
            }
            .card-text {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
    .result-ir {
      margin-top: 100px;
      padding-top: 50px;
      border-top: 2px solid $dark-gray-4;
      dl {
        position: relative;
        dt {
          padding: 28px 0 35px;
          font-weight: 700;
          font-size: 24px;
          line-height: 38px;
          span {
            display: inline-block;
            margin-left: 10px;
            font-weight: 500;
            color: $primary;
          }
        }
        dd {
          li {
            display: flex;
            align-items: center;
            position: relative;
            padding: 38px 20px 31px;
            border-top: 1px solid $light-gray-3;
            .year {
              display: inline-block;
              margin-right: 20px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: $gray-3;
            }
            p {
              width: calc(100% - 119px);
              color: $dark-gray-3;
              font-weight: 600;
              font-size: 18px;
              line-height: 32px;
            }
            button {
              width: 60px;
              height: 60px;
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
            }
            &:last-child {
              border-bottom: 1px solid $light-gray-3;
            }
          }
        }
      }
      @include media-breakpoint-down(lg) {
        padding-top: 40px;
        dl {
          margin-bottom: 40px;
          dt {
            padding: 0 0 20px 10px;
            font-size: 14px;
            line-height: 22px;
            span {
              margin-left: 4px;
            }
          }
          dd {
            li {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 38px 20px 31px;
              .year {
                display: block;
                margin-right: 0;
                font-size: 12px;
                line-height: 22px;
              }
              span {
                display: block;
              }
              p {
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
        .btn-more {
          margin-top: 0;
        }
      }
    }

    //nodata
    ul {
      li {
        &.nodata {
          margin: 0;
          padding: 30px 0 100px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #777;
          text-align: center;
          background-color: #fff;

          .icon-caution-gray {
            width: 38px;
            height: 38px;
            margin-bottom: 10px;
            background-image: url("../images/icon-caution-gray.svg");
          }
        }

        @include media-breakpoint-down(lg) {
          &.nodata {
            padding: 32px 0 50px;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .btn-wrap {
    display: flex;
    justify-content: center;
    .btn-more {
      margin: 50px 0 182px;
      padding: 20px 40px;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      @include media-breakpoint-down(lg) {
        margin: 40px 0 100px;
        padding: 16px 20px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
