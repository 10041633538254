.page-top {
  &.rnd-bg {
    .txt {
      &:nth-child(2) {
        background: url("../images/rnd-bg.jpg") no-repeat 50% 50%;
      }
      .page-top-desc {
        p {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
        }
        .btn {
          margin-top: 60px;
          background: rgba(255, 255, 255, 0.2) !important;
          border-radius: 25px;
        }
      }
      .img {
        display: none;
      }
      @include media-breakpoint-down(lg) {
        padding: 53px 0 0;
        background-size: cover !important;
        height: auto;
        .page-top-desc {
          padding: 0;
          p {
            font-size: 16px;
            line-height: 26px;
          }
          .btn {
            margin-top: 40px;
            padding: 8px 12px;
            font-size: 14px;
            line-height: 17px;
            border-radius: 16.5px;
          }
        }
        .img {
          display: block;
          margin: 50px -18px 0;
        }
      }

      .col-lg-5 {
        width: 40.666667%;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .inner {
        height: auto;
      }
      .txt {
        position: static;
        height: auto;
        &:nth-child(1) {
          --progress: 0 !important;
          padding-top: 0;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

.rnd-wrap {
  padding-bottom: 200px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 180px;
  }

  .col-lg-8 {
    width: 63.666667%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .btn-rnd-more {
    &.btn-rnd-more1 {
      &.style-1 {
        width: 273px;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }
    &.btn-rnd-more2 {
      &.style-1 {
        width: 273px;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }
    &.style-1 {
      width: 273px;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }

  .grid-box {
    .row {
      .col-6 {
        .item {
          &.item02 {
            .hover-box {
              left: calc(-100% - 16px);
            }
          }
        }
      }
    }
  }
}

.rnd-section {
  padding-top: 120px;
  @include media-breakpoint-up(lg) {
    padding-top: 240px;
  }
  .title {
    padding-bottom: 20px;
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 36px;
    position: relative;
    border-bottom: 2px solid $dark-gray-1;
    font-weight: 700;
    font-family: 'Brand New Hyosung OTF';
    &::before {
      content: "";
      width: 0;
      height: 2px;
      background-color: $dark-gray-1;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: width 0.4s ease-in-out;
    }
    &.active {
      &::before {
        width: 100%;
      }
    }
    span {
      margin-left: 4px;
      font-size: 12px;
      line-height: 22px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 45px;
      margin-bottom: 60px;
      font-weight: 700;
      font-size: 80px;
      line-height: 95px;
      span {
        margin-left: 12px;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        vertical-align: text-top;
      }
    }
  }
  .text {
    font-size: 16px;
    line-height: 26px;
    p {
      color: #333;
    }
    p + p {
      margin-top: 14px;
    }
    @include media-breakpoint-up(lg) {
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
    }
  }
}
.btn-rnd-more {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 32px;
  span {
    display: inline-block;
    margin-right: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  .btn-white {
    width: 30px;
    height: 30px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    span {
      margin-right: 20px;
      font-size: 18px;
      line-height: 21px;
    }
    .btn-white {
      width: 48px;
      height: 48px;
    }
  }
}
.grid-box-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
  @include media-breakpoint-up(lg) {
    margin-top: 120px;
  }
  .grid-box {
    position: relative;
    overflow: hidden;
    $gap: 16px;
    $gap-lg: 20px;

    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #fff;
    }
    > ul {
      margin: calc($gap / 2 * -1);
      position: relative;
      > li {
        border: calc($gap / 2) solid #fff;
      }
      @include media-breakpoint-up(lg) {
        margin: calc($gap-lg / 2 * -1);
        > li {
          border-width: calc($gap-lg / 2);
        }
      }
    }
    .item {
      display: block;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      perspective: 200px;
      span {
        color: #fff;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        text-align: center;
        position: relative;
      }
      .inner {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        &::before {
          z-index: 1;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          content: "";
          transition: 0.3s;
          z-index: 0;
        }
        &::after {
          display: inline-block;
          position: absolute;
          z-index: 2;
          bottom: 0;
          content: "";
          border-bottom: 24px solid transparent;
          border-right: 224px solid transparent;
          transition: 0.3s;
        }
      }
      &:focus,
      &:hover {
        .inner {
          outline: none;
          border-color: transparent;
        }
      }
      @include media-breakpoint-up(lg) {
        span {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.active {
        @include media-breakpoint-down(lg) {
          perspective: none;
        }
        .hover-box {
          opacity: 1;
        }
        &:focus {
          outline: none;
          border-color: transparent;
          .inner {
            outline: none;
            border-color: transparent;
          }
        }
        .inner {
          &::before {
            background: rgba(0, 0, 0, 0.6);
          }
          &::after {
            border-bottom: 24px solid #fff;
          }
          @include media-breakpoint-down(lg) {
            &::after {
              border-right: 437px solid transparent;
            }
          }
        }
      }
      &.triangle {
      }
    }

    .hover-box {
      background: $light-gray-3;
      position: absolute;
      top: calc(100% + $gap - 1px);
      z-index: 2;
      width: calc(300% + $gap*2);
      padding: 40px 28px;
      opacity: 0;
      transition: 0.3s;
      > ul {
        > li {
          position: relative;
          font-size: 16px;
          color: $gray-3;
          line-height: 26px;
          padding-left: 15px;
          &::before {
            display: inline-block;
            position: absolute;
            left: 3px;
            top: 10px;
            transform: translate(-50%, 0);
            border-radius: 100%;
            width: 3px;
            height: 3px;
            content: "";
            background-color: $gray-3;
          }
        }
      }
      @include media-breakpoint-down(lg) {
        &.even {
          left: calc((100% + $gap) * -1);
        }
      }
      @include media-breakpoint-up(lg) {
        left: 0;
        top: calc(100% + $gap-lg);
        width: calc(300% + $gap-lg*2);
        height: 100%;
        > ul {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          > li {
            line-height: 24px;
          }
        }
        &.even {
          left: calc(-100% - $gap-lg);
          right: auto;
        }
        &.position-top-right {
          left: auto;
          right: 0;
        }
      }
    }
  }
}
// pop
.pop-performance {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: $dark-gray-1;
  display: none;
  > .container {
    height: 100%;
    position: relative;
  }
  h2 {
    font-weight: 700;
    font-size: 28px;
    color: $white;
    line-height: 36px;
    margin-bottom: 100px;
    padding-top: 80px;
    font-family: 'Brand New Hyosung OTF';
    @include media-breakpoint-up(lg) {
      margin-bottom: 120px;
      padding-top: 100px;
    }
  }
  .year-scroll {
    > li {
      .year {
        margin-bottom: 20px;
        font-weight: 700;
        color: $white;
        font-size: 22px;
        line-height: 32px;
        font-family: 'Brand New Hyosung OTF';
      }
      .list {
        li {
          margin: 0 0 8px 21px;
          font-size: 14px;
          line-height: 22px;
          color: $light-gray-1;
          text-indent: -24px;
          &::before {
            display: inline-block;
            width: 3px;
            height: 3px;
            margin: 0 10px;
            content: "";
            background: $light-gray-1;
            border-radius: 100%;
            vertical-align: middle;
          }
          &:last-child {
            margin: 0 0 0 21px;
          }
        }
      }
    }
  }

  .btn-wrap {
    position: relative;
    height: 64px;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 12px;
    span {
      color: #fff;
    }
    @include media-breakpoint-down(lg) {
      position: fixed;
      left: 18px;
      right: 19px;
      bottom: 22px;
      width: auto;
      z-index: 2;
    }
  }

  .btn-clse {
    position: absolute;
    z-index: 99999;
    top: 18px;
    right: 18px;
    border-color: $light-gray-1 !important;
    @include media-breakpoint-up(lg) {
      top: 100px;
      right: 30px;
    }
  }

  @include media-breakpoint-down(lg) {
    .swiper {
      height: 100%;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 48px;
    height: 48px;
    top: auto;
    bottom: 16px;
    .btn {
      border-color: #dcdcdc;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev .btn-dark,
  .swiper-button-next .btn-dark {
    border-color: $light-gray-1 !important;
  }
  .swiper-horizontal > .swiper-pagination-progressbar,
  .swiper-pagination-progressbar.swiper-pagination-horizontal,
  .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
  .swiper-vertical
    > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    height: 4px;
    background-color: $dark-gray-3;
    border-radius: 10rem;
    overflow: hidden;
    bottom: 0;
    top: auto;
    @include media-breakpoint-down(lg) {
      position: fixed;
      bottom: 18px;
      left: 18px;
      right: 18px;
      width: auto;
    }
  }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: $primary;
    border-radius: 2px;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    content: none;
  }

  @include media-breakpoint-up(lg) {
    width: 100vw;
    h2 {
      font-size: 48px;
      line-height: 57px;
      font-family: 'Brand New Hyosung OTF';
    }
    .year-scroll {
      padding: 60px 0 120px 0;
      > li {
        width: 357px;
        margin-right: 120px;
        .year {
          margin-bottom: 30px;
          font-size: 32px;
          line-height: 38px;
          font-family: 'Brand New Hyosung OTF';
        }
        &.large {
          transform: translateY(-47px);
          .year {
            margin-bottom: 30px;
            font-size: 48px;
            line-height: 57px;
            font-family: 'Brand New Hyosung OTF';
          }
        }
        .list {
          li {
            margin: 0 0 10px 21px;
            font-size: 16px;
            line-height: 24px;
            text-indent: -24px;
            &::before {
              margin: 0 10px;
            }
          }
        }
      }
    }
    .swiper {
      overflow: visible;
    }
    .swiper-horizontal > .swiper-pagination-progressbar,
    .swiper-pagination-progressbar.swiper-pagination-horizontal,
    .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
    .swiper-vertical
      > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
      height: 6px;
      border-radius: 4px;
    }
    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      border-radius: 4px;
    }
  }
}
