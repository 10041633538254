.container,
.container-fluid{
  padding-left: 18px;padding-right: 18px;max-width: 1396px;
  @include media-breakpoint-up(lg){
    padding-left: 30px;padding-right: 30px;
  }
}

#wrap{
  padding-top: $header-height;
  @include media-breakpoint-up(xl){
    padding-top: $header-height-lg-expand;
  }
}

html:not(.gnb-only-dep1-show){
  @include media-breakpoint-down(xl){
    #container{padding-top: 48px;min-height: calc(40vh)}
  }
}

html.gnb-only-dep1-show{
  @include media-breakpoint-up(xl){
    #wrap{
      padding-top: $header-height-lg;
    }
  }
}