.history-container {
  background: $dark-gray-1;
  .content-head {
    position: relative;
  }
  @include media-breakpoint-down(lg) {
    .page-top {
      padding-top: 80px;
    }
  }

  .col-lg-6 {
    width: 46%;

    @include media-breakpoint-down(lg) {
      width: 75%;
    }
  }

  .mo-li {
    display: block;
    height: 24px;
  }

  .fixed-indicator .title {
    width: 124px;
    letter-spacing: -0.42px;

    @include media-breakpoint-down(lg) {
      width: auto;
    }
  }

  .fixed-indicator[data-theme="dark"] li.active .title {
    @include media-breakpoint-down(lg) {
      width: auto;
    }
  }

  .fixed-indicator li:last-child .title {
    @include media-breakpoint-down(lg) {
      font-size: 10px;
    }
  }
}

@keyframes background-scale {
  0% {
    background-size: 125% 125%;
  }
  100% {
    background-size: 100% 100%;
  }
}

.history-visual {
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("../images/history-visual-bg.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    animation: background-scale 0.8s ease-in Alternate;
  }
}

.history-wrap {
  position: relative;
  // padding-top: 150px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 200px;
    /* .fixed-indicator{
      transition: transform .35s;
      &.before{transform: translateX(-300px);}
    } */
  }
}

.history-top {
  padding-top: 40px;
  .history-top-text {
    font-size: 20px;
    line-height: 32px;
    color: $white;
    span {
      font-weight: 200;
      display: block;
      opacity: 0;
      transform: translateY(150px);
    }
    strong {
      font-weight: 500;
      display: block;
      opacity: 0;
      transform: translateY(150px);
    }
  }
  .history-top-desc {
    margin-top: 50px;
    .line {
      display: block;
      width: 48px;
      height: 2px;
      background: $primary;
      margin-bottom: 40px;
      opacity: 0;
      transform: translateY(150px);
    }
    p {
      color: $white;
      font-size: 14px;
      line-height: 22px;
      opacity: 0;
      transform: translateY(150px);
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 167px;
    .history-top-text {
      font-size: 40px;
      line-height: 50px;
    }
    .history-top-desc {
      p {
        font-size: 28px;
        line-height: 38px;
        color: $gray-1;
      }
    }
  }
}

.history-section {
  position: relative;
  padding-top: 200px;
  h2 {
    color: $white;
    font-weight: 600;
    font-size: 44px;
    font-family: 'Brand New Hyosung OTF';
    & + .section-img-01 {
      margin-top: 80px;
      margin-bottom: 157px;
      position: relative;
      z-index: 1;
    }
  }
  // & + .history-section {
  //   padding-top: 200px;
  // }
  &::after {
    content: "";
    width: calc((100vw - 1351px) / 2);
    height: 100%;
    background: $dark-gray-1;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.history-item {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  transition: all 0.2s;
  h3 {
    display: inline-block;
    color: $gray-2;
    font-size: 37px;
    font-weight: 600;
    transition: all 0.16s ease-in;
    font-family: 'Brand New Hyosung OTF';
  }
  ol {
    li {
      color: $light-gray-1;
      transition: all 0.1s ease;
      font-size: 20px;
      line-height: 24px;
      text-indent: -58px;
    }
    li + li {
      margin-top: 21px;
    }
  }
  .row {
    position: relative;
    padding: 76px 0;
  }
  .container {
    &::after {
      content: "";
      width: 100vw;
      height: 1px;
      background: $dark-gray-3;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    &.active {
      background-color: #fff;
      h3 {
        color: $dark-gray-1;
        transform: scale(2.16);
        transform-origin: left;
      }
      ol {
        li {
          color: $dark-gray-1;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .history-wrap {
    padding-top: 0;
  }
  .history-section {
    padding-top: 100px;
    .history-section-title {
      padding-left: 0px;
    }
    h2 {
      font-size: 28px;
      line-height: 120%;
      & + .section-img-01 {
        margin-top: 30px;
        margin-bottom: 34px;
        img {
          aspect-ratio: 339/130;
        }
      }
    }
    & + .history-section {
      padding-top: 120px;
    }
    &::after {
      width: 0;
      display: none;
    }
  }
  .history-item {
    padding-left: 0px;
    h3 {
      font-size: 22px;
      line-height: 31px;
      transform: translateY(-3px);
    }
    ol {
      li {
        font-size: 14px;
        line-height: 22px;
        text-indent: 0px;
        & + li {
          margin-top: 14px;
        }
        &.month {
          padding-left: 38px;
          text-indent: -38px;
        }
        .mo-li {
          margin-top: 14px;
        }
      }
    }
    .row {
      padding: 50px 0;
    }
  }
}
