;#header{
  position: fixed;width: 100%;top: 0;z-index: $header-z-index;background: #fff;transition: height 0.2s 0s ease, transform .5s .1s ease, mix-blend-mode 0.4s 0.2s ease;
  @include media-breakpoint-down(xl){
    height: $header-height !important;
  }
  @include media-breakpoint-up(xl){
    height: $header-height-lg-expand;
  }
}

.logo{
  line-height: 1;margin-right: 55px;
  a{display: inline-block;background: url('../images/logo.svg') no-repeat 0 0;background-size: contain;width: 159px;height: 15px;vertical-align: top;  transition: background 0.5s;}
}
.logo-svg{display: none;}

.tooltip-menu{
  position: absolute;top:34px;left: 50%;z-index: 1;transform: translateX(-50%);background: #fff;border: 1px solid $light-gray-2;border-radius: 8px;padding: 14px 17px;text-align: center;display: none;
  @include media-breakpoint-down(xl) {left: 0; transform: translateX(0);}
  a{font-size: 14px;color: $gray-2;display: block;line-height: 1;}
  li + li{margin-top: 14px;}
  li.active{
    a{color: $primary;}
  }
  .active ~ &,
  .active > &{
    display: block;
  }

  .is-main &{
    border-color:  #1a1a1a;
    background-color: #000000;
    a {color: #666666;}
    li.active {
      a {
        color: #00dcc2;
      }
    }
  }
  .fixed-header &, .mix-inherit &{
    border-color:  $light-gray-2;
    background-color: #fff;
    a {color:  $gray-2;}
    li.active {
      a {
        color: $primary;
      }
    }
  }
}

.menu-select{
  position: relative;
  button{
    span{
      position: relative;font-size: 20px;font-weight: 700;line-height: 24px;vertical-align: top;
      &::after{
        content: '';width: 8px;height: 6px;background: url('../images/icon-select-menu-arrow.svg') no-repeat 0 0;background-size: contain;position: absolute;right: -21px;top: 50%;margin-top: -4px;transition: 0.3s;
        @include media-breakpoint-down(xl){
          background-image: url('../images/icon-select-menu-arrow-mobile.svg');width: 20px;height: 20px;margin-top: -10px;right: -30px;
        }
      }
    }
    &.active{
      span{
        &::after{transform: rotate(180deg);}
      }
    }
  }
  .gnb-hidden &{display: none;}
}

.quick{
  display: flex;align-items: center;margin-right: 60px;
  > li{
    position: relative;
    & + li{
      margin-left: 23px;
      &::before{content: '';display: block;width: 1px;height: 12px;background: #D9D9D9;position: absolute;left: -12px;top: 50%;transform: translateY(-50%);}
    }
    > a{
      color: $dark-gray-4;font-size: 14px;
      > strong{font-weight: 600;color: $black;}
    }
    .icon-search-header{margin-top: -2px;}
  }
  [data-theme=dark] &{
    @include media-breakpoint-up(xl){
      > li{
        & + li{
          &::before{background: $white;}
        }
        > a{
          color: $white;
          > strong{color: $white;}
        }
      }
    }
  }
}

.btn-allmenu{
  display: flex;align-items: center;font-weight: 700;font-size: 14px;color: $dark-gray-1;
  .circle{display: block;width: 20px;height: 20px;border-radius: 50%;background: $dark-gray-1;margin-left: 4px;}
  @include media-breakpoint-up(xl){
    .circle{margin-left: 6px;transform: translateY(.5px);}
  }
  [data-theme=dark] &{
    @include media-breakpoint-up(xl){
      color: $white;
      .circle{background: $white;}
    }
  }
}

.header{
  display: flex;justify-content: space-between;width: 100%;
  .inner{padding: 0 18px;width: 100%;height: $header-height;background: #fff;}
  @include media-breakpoint-up(xl){
    .inner{padding: 0 40px;height: $header-height-lg;}
  }
}

.gnb-wrap{text-align: center;}
.gnb{
  border-bottom: 1px solid $light-gray-3;display: inline-block;
  @include media-breakpoint-down(xl){
    background: #fff;height: 48px;display: block;text-align: left;
  }
  @include media-breakpoint-up(xl){
    margin-top: -25px;/* padding-bottom: 21px; */border-bottom: 0;
  }
}

.gnb-only-dep1-show{
  .menu-select{display: none;}
  @include media-breakpoint-down(xl){
    .gnb{display: none;}
    .logo-svg{display: none;}
    .logo{
      a{
        background: url(../images/logo.svg) no-repeat 0 0;
      }
    }
  }
  @include media-breakpoint-up(xl){
    #header {height: $header-height-lg;}
    .gnb{
      position: absolute;left: 50%;top: 0;transform: translateX(-54%);margin-top: 0;
    }
    .dep1{
      display: block;
      > ul > li > a{
        span{vertical-align: top;display: none;}
        &:hover{
          // rect{stroke: $dark-gray-1;fill: $dark-gray-1;}
          // path{fill: $white}
        }
      }
    }
    .dep2{display: none;}
    .svg-stroke-white{stroke: $dark-gray-3;}
    .svg-fill-white{fill: $dark-gray-1;}
  }
}

html.scroll-down{
  @include media-breakpoint-down(lg){
    #header{
      transform: translateY(-$header-height);
    }
  }
}

html.is-main{
  .svg-fill-white{fill:$white;}
  .svg-stroke-white{stroke:$white;}
  .svg-fill-black{fill:$black;}
  .svg-stroke-black{stroke:$black;}
  .svg-bg-white{fill:$white;}
  .svg-bg-white{stroke:$white;}
  .svg-bg-black{fill:$black;}
  .svg-bg-black{stroke:$black;}
  .mix-inherit{mix-blend-mode: inherit!important;}
  @include media-breakpoint-down(xl){
    #header{
      background-color: #fff;
    }
    .logo{
      a{
        background: url(../images/logo.svg) no-repeat 0 0;
      }
    }
    .btn-allmenu{
      color:$black;
      font-weight: 700;
      .circle{
        background: $black;
      }
    }
  }
  @include media-breakpoint-up(xl){
    .logo a{
      background: inherit;
    }
    .quick > li {
      line-height: 1;
      & + li::before{
        background: #2a2a2a;
      }
      >a{
        color:#cacaca;
        strong{
          color: #fff;
        }
      }
      .btn-allsearch{
        circle{stroke: $white;}
        rect{fill: $white;}
      }
    }
    .dep1 > ul > li > a{
      display: block;
      height: 100%;
      &:hover{
        svg{
          rect{stroke: $white;fill: $white;}
        }
      }
    }
    .logo-svg{display: block;}
    .btn-allmenu{
      color:#c9c9c9;
      .circle{
        background: $white;
      }
    }

    .mix-inherit{
      .quick > li {
        & + li::before{
          background: $white;
        }
        >a{
          color:$white;
          strong{
            color: $white;
          }
        }
      }
      .btn-allmenu{
        color:$white;
      }
    }

    #header.fixed-header{
      background-color: $white!important;
      mix-blend-mode: inherit!important;
      transition: .15s;
      .logo-svg{display: none;}
      .logo{
        a{
          background: url(../images/logo.svg) no-repeat 0 0;
        }
      }
      .btn-allmenu{
        color:$dark-gray-1;
        .circle{
          background: $dark-gray-1;
        }
      }
      .svg-fill-white{
        fill:$dark-gray-1;
      }
      .svg-stroke-white{
        stroke: $dark-gray-3;
      }
      .quick > li{
        & + li::before{
          background: #D9D9D9;
        }
        > a{
          color:$dark-gray-4;
          strong{
            color:$black;
            font-weight: 600;
          }
        }
      }
      .btn-allsearch{
        circle{stroke: $dark-gray-4;}
        rect{fill: $dark-gray-4;}
      }
      .dep1 > ul > li > a{
        &:hover{
          svg{
            rect{stroke: $black;fill: $black;}
            path{fill:$white;}
          }
        }
      }
    }
    #wrap{padding-top: 0;}
    #header,
    .header .inner{background: transparent;}
    .logo{
      a{background-image: url('../images/logo-white.svg');}
    }
    #header{
      z-index: 1030;
      background-color: transparent;
      mix-blend-mode: difference;
      isolation: isolate;
      &.hidden{
        transform: translateY(-$header-height-lg);opacity: 1;transition: transform .5s eade, opacity .2s ease;
      }
    }
    .header {
      .inner {
        background-color: transparent;
      }
    }
  }
}

html:not(.gnb-only-dep1-show){
  @include media-breakpoint-down(xl){
    .logo{display: none;}
  }
}

.dep1{
  display: none;
  > ul{
    display: flex;align-items: center;justify-content: center;
    > li{
      margin: 0 12px;
      > a{
        span{vertical-align: top;display: none;}
        .svg-fill-white,
        .svg-stroke-white,
        .svg-fill-black,.svg-stroke-black{transition: fill 0.15s, stroke 0.15s;}
        &:hover{
          .svg-fill-white{fill:$black;}
          .svg-stroke-white{stroke:$black;}
          .svg-fill-black{fill:$white;}
          .svg-stroke-black{stroke:$white;}
        }
      }
    }
  }
  @include media-breakpoint-up(xl){
    padding-top: 19px;
  }
  [data-theme=dark] &{
    @include media-breakpoint-up(xl){
      > ul{
        > li{
          > a{
            border-color: $white;color: $white;
            &:hover{border-color: $dark-gray-3;}
          }
        }
      }
    }
  }
}

.dep2{
  > ul{
    display: none;
    &.show{
      display: block;
    }
    > li{
      > a{
        display: block;padding: 14px 18px;position: relative;font-size: 16px;line-height: 20px;font-weight: 600;
      }
    }
  }
  @include media-breakpoint-down(xl){
    > ul{
      > li{
        &:not(.active){
          display: none;
        }
        > a{
          &::after{content: '';background: url('../images/icon-gnb-arrow.svg') no-repeat 0 0;width: 16px;height: 10px;position: absolute;right: 18px;top: 50%;margin-top: -5px;transition: all .15s .05s;}
          &.open{
            &::after{transform: rotate(180deg);}
            & + ul{display: block;}
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl){
    padding-top: 19px;
    > ul{
      > li{
        padding: 0 20px 21px;
        text-align: center;position: relative;min-width: 20px;transition: min-width .45s ease-in-out;
        > a{
          display: inline-block;color: $gray-2;font-weight: 700;line-height: 19px;white-space: nowrap;padding-bottom: 1px;padding: 6px 0 7px;border: 1px solid transparent;
          span{vertical-align: top;}
        }
        &.active{
          > a{color: $dark-gray-1;border-color: $gray-1;border-radius: 50rem;padding-left: 20px;padding-right: 20px;}
        }
      }

      &.show{display: flex;justify-content: center;}
    }
  }
}

.dep3{
  padding: 15px 0;opacity: 0;visibility: hidden;display: none;
  > li{
    &.active{
      > a{color: $primary;}
    }
    > a{display: block;color: $gray-2;padding: 9px 38px;}
  }
  @include media-breakpoint-down(xl){
    height: auto !important;opacity: 1;visibility: visible;background: $white;
  }
  @include media-breakpoint-up(xl){
    border-right: 1px solid $light-gray-3;width: 100%;margin-top: 20px;padding-top: 22px;padding-bottom: 22px;transition: opacity 0.1s;position: absolute;left: 0;
    > li{
      text-align: center;
      > a{
        font-weight: 600;color: $dark-gray-4;line-height: 19px;white-space: nowrap;transition: 0.15s;display: block;padding: 12px 0;
      }
    }
  }
}

.copied-dep2{
  opacity: 0;visibility: hidden;display: none;
  > li{
    > a{display: block;padding: 14px 18px;font-size: 16px;font-weight: 600;line-height: 20px;}
    & + li{border-top: 1px solid $light-gray-3;}
    &.active{
      display: none;
    }
  }
  @include media-breakpoint-down(xl){
    height: auto !important;opacity: 1;visibility: visible;background: $white;
  }
  @include media-breakpoint-up(xl){
    display: none;
  }
}


@include media-breakpoint-up(xl){
  .gnb-hover{
    transition-delay: 0.45s !important;transition-duration: 0.3s !important;overflow: hidden;
    .header{
      &::before{content: '';background: $light-gray-3;position: absolute;left: 0;top: calc($header-height-lg-expand - 1px);width: 100%;height: 1px;}
      &::after{content: '';background: $light-gray-3;position: absolute;left: 0;bottom: 0;width: 100%;height: 1px;}
    }
    .gnb{height: 100%;}
    .gnb-wrap{
      height: 100%;
    }
    .gnb-wrap:not([data-is-dep1="Business"]){
      .dep2{
        > ul{
          > li{
            min-width: 219px;
          }
        }
      }
    }
    .dep3{opacity: 1;visibility: visible;transition: opacity 0.3s 0.45s;display: block;}
  }
}
