.allmenu{
  ::-webkit-scrollbar{
    width: 4px;
    height: 4px;
    background-color: transparent;
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar-track{
    border-radius: 0;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb{
    border-radius: 9px;
    background-color: $primary;
  }
  ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
  }
  background: $dark-gray-1;position: fixed;left: 0;top: 0;bottom: 0;z-index: 999;width: 100vw;
  flex-direction: column;overflow: hidden;display: none;

  .active ~ &{display: flex;}
  .btn-close{
    color: $white;display: flex;align-items: center;font-weight: 700;font-size: 12px;display: flex;position: fixed;right: 18px;top: 18px;
    span{margin-right: 4px;}
    @media (hover: none) and (pointer: coarse) {
      &:focus-visible{outline: 0;}
    }
    @include media-breakpoint-up(lg){
      top: 25px;right: 40px;font-size: 14px;font-weight: 700;
      span{margin-right: 6px;}
      .icon-close-white{background-image: url('../images/icon-close-circle-white.svg');width: 20px;height: 20px;}
    }
  }
  @include media-breakpoint-down(lg){
    -webkit-clip-path: circle(10px at calc(100% - 28px) 30px);
    clip-path: circle(10px at calc(100% - 28px) 30px);
    transition: -webkit-clip-path .6s ease-out, clip-path .6s ease-out;
    &.active {
      -webkit-clip-path: circle(200% at calc(100% - 28px) 30px);
      clip-path: circle(200% at calc(100% - 28px) 30px);
    }
  }
  @include media-breakpoint-up(lg){
    -webkit-clip-path: circle(10px at calc(100% - 50px) 35px);
    clip-path: circle(10px at calc(100% - 50px) 35px);
    transition: -webkit-clip-path .6s ease-out, clip-path .6s ease-out;
    &.active {
      -webkit-clip-path: circle(200% at calc(100% - 50px) 35px);
      clip-path: circle(200% at calc(100% - 50px) 35px);
    }
  }
}

.allmenu-header{
  flex: 0 0 $header-height;display: flex;justify-content: space-between;align-items: center;padding: 0 18px;
  .logo-in-layer{
    background: url('../images/logo-white.svg') no-repeat 0 0;background-size: contain;width: 140px;height: 14px;
  }
  @include media-breakpoint-up(lg){
    flex: 0 0 $header-height-lg;padding: 0 40px;
    .logo-in-layer{width: 159px;height: 15px;display: block;}
  }
}

.allmenu-body{
  flex-grow: 1;position: relative;display: flex;overflow-y: auto;
  @include media-breakpoint-up(lg){
    display: block;padding-top: 20px;
  }
  .quick{
    margin: 0 auto 44px;
    > li{
      & + li{
        margin-left: 40px;
        &::before{background: $dark-gray-3;left: -20px;}
      }
      > a{
        color: $gray-2;font-size: 14px; line-height: 22px;
        > strong{color: $light-gray-2;}
      }
    }
  }
  .lang-in-layer{
    display: flex;align-items: center;padding: 0 18px;margin-top: 12px;margin-bottom: 20px;
    a{
      color: $gray-3;font-size: 12px;font-weight: 500;
      & + a{margin-left: 24px;}
      &.active{color: $primary;text-decoration: underline;}
    }
  }
}

.allmenu-index{
  @include media-breakpoint-down(lg){
    height: 100%;display: flex;flex-direction: column;width: 100vw;
    .lang-in-layer{
      flex: 0 0 auto;
    }
  }
  @include media-breakpoint-up(lg){
    margin: 0 auto;padding-left: 40px;padding-right: 40px;max-width: $container-max-width;
  }
}

.dep1-menu{
  height: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;padding: 20px 0;
  > ul{
    > li{
      > a{display: block;font-size: 40px;line-height: 50px;font-weight: 700;color: $white;text-align: center;padding: 16px 0;}
    }
  }
  @include media-breakpoint-up(lg){
    display: block;height: auto;
    > ul{
      > li{
        display: flex;
        & + li{margin-top: 100px;}
        > a{font-size: 36px;margin: 0;font-weight: 500;pointer-events: none;cursor: text;flex: 0 0 160px;margin-right: 177px;padding: 0;}
        .allmenu-sub{flex-grow: 1;padding-top: 12px;}
      }
    }
  }
}

.allmenu-sub{
  @include media-breakpoint-down(lg){
    width: 100vw;position: fixed;left: 0;right: 0;top: $header-height;bottom: 0;z-index: 1;background: $dark-gray-1;display: flex;flex-direction: column;display: none;
  }
  .btn-close{
    transform: translateY(-$header-height);
    @include media-breakpoint-up(lg){
      transform: translateY(-$header-height-lg);
    }
  }
  .allmenu-sub-header{
    flex: 0 0 45px;text-align: center;position: relative;height: 45px;
    .back{
      display: block;background: url('../images/icon-allmenu-back.svg') no-repeat 50% 50%;width: 24px;height: 24px;background-size: 12px 20px;position: absolute;left: 12px;top: 50%;margin-top: -12px;
    }
    h1{color: $primary;font-size: 14px;font-weight: 600;line-height: 45px;}
    @include media-breakpoint-up(lg){
      display: none;
    }
  }
  .allmenu-sub-body{
    flex-grow: 1;overflow-y: auto;overflow-x: hidden;text-align: center;
    $padding-bottom: 2rem;
    padding-bottom: $padding-bottom;
    padding-bottom: calc(constant(safe-area-inset-bottom) + $padding-bottom);
    padding-bottom: calc(env(safe-area-inset-bottom) + $padding-bottom);

    h2{
      color: $white;font-size: 24px;line-height: 30px;font-weight: 600;margin-bottom: 20px;margin-top: 40px;
      a{color: $white;}
    }
    ul{
      > li{
        padding: 9px 0;
        a{display: block;font-size: 16px;color: $light-gray-2;line-height: 20px;}
        a[target="_blank"]{
          &::after{content: '';display: inline-block;vertical-align: top;background: url('../images/icon-outlink.svg');width: 8.68px;height: 8.68px;background-size: contain;margin-left: 6px;transform: translateY(2px);}
        }
      }
      & + h2{margin-top: 70px;}
    }
    @include media-breakpoint-up(lg){
      text-align: left;
      a{transition: all .15s;}
      h2{
        color: $white;font-weight: 400;margin-top: 0;display: inline-block;
        margin-right: 60px;
        a:hover{color: $primary;}
      }
      ul{
        display: flex;flex-wrap: wrap;
        & + h2{margin-top: 50px;}
        > li{
          margin: 0 60px 20px 0;padding: 0;
          > a{font-size: 18px;font-weight: 500;color: $gray-1;
            &:hover,
            &:focus{color: $primary;font-weight: 600;}
          }
        }
      }
    }
  }
}


@include media-breakpoint-down(lg){
  [data-menu="company"]{
    .allmenu-sub:not(#dep2-company){display: none;}
    #dep2-company{display: flex;}
  }
  [data-menu="business"]{
    .allmenu-sub:not(#dep2-business){display: none;}
    #dep2-business{display: flex;}
  }
  [data-menu="brand"]{
    .allmenu-sub:not(#dep2-brand){display: none;}
    #dep2-brand{display: flex;}
  }
}