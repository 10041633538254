.global-network {
  @include media-breakpoint-down(lg) {
    margin-top: 80px;
  }
  @include media-breakpoint-up(lg) {
    overflow: hidden;
    height: 100vh;
  }
}
.global-network-section {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  .left,
  .right {
    top: $header-height;
  }
  @include media-breakpoint-up(lg) {
    height: calc(100vh - $header-height-lg-expand);
    .left {
      position: absolute;
      top: 0;
      left: 0;
      width: 50vw;
      bottom: 0;
    }
    .right {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50vw;
      bottom: 0;
    }
    /*     .left,
    .right{top: $header-height-lg-expand;} */
  }
}

.animation-image {
  transition: 0.5s ease-in-out;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    &.active {
      z-index: 1;
    }
  }
  @include media-breakpoint-down(lg) {
    margin-left: 0 !important;
    width: 100% !important;
    img {
      left: 0;
      top: 0;
      height: 460px;
      transform: none !important;
      display: none;
    }
    .start-image {
      display: none;
    }
  }
  @include media-breakpoint-up(lg) {
    width: 319px;
    height: 383px;
    margin-left: calc(50vw - 319px);
    margin-right: 0;
    position: relative;
    img {
      position: absolute;
      transition: 0.25s ease-in-out;
    }
    .gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      transition: background 0.3s;
    }
    .animation-image-1,
    .animation-image-2,
    .animation-image-3,
    .animation-image-4 {
      opacity: 0;
    }
  }
}

.network-list {
  position: relative;
  left: 0;
  top: 0;
  height: 460px;
  width: 100vw;
  z-index: 1;
  transition: 0.5s ease-in-out;
  > .container {
    height: 100%;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    li {
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      span {
        color: rgba(255, 255, 255, 0.8);
        font-size: 12px;
      }
      strong {
        color: rgba(255, 255, 255, 0.8);
        font-size: 24px;
        line-height: 1.2;
      }
      &.active {
        span {
          color: $white;
          font-size: 16px;
        }
        strong {
          color: $white;
          font-size: 36px;
          font-family: 'Brand New Hyosung OTF';
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    @for $i from 1 to 5 {
      &.network-list-#{$i} {
        background: url("../images/globalnetwork-section-#{$i}.jpg")
          no-repeat
          0
          0;
        background-size: cover;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    height: 100%;
    opacity: 0;
    transform: translateX(100%);
    trnasition: 0.5s ease-in-out;
    ul {
      gap: 80px;
      align-items: flex-end;
      li {
        width: 100%;
        text-align: right;
        align-items: flex-end;
        gap: 10px;
        transition: 0.3s;
        span {
          color: $gray-1;
          font-weight: 500;
          font-size: 18px;
          line-height: 1.2;
          transition: 0.3s;
        }
        strong {
          color: $gray-1;
          // font-size: 68px;
          font-size: 42px;
          line-height: 1.2;
          transition: 0.3s;
          font-family: "Brand New Hyosung OTF";
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .action1 {
    .animation-image {
      margin-left: 0;
      width: 100vw;
      height: 100%;
      transform: translateY(0);
    }
    .gradient {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .action2 {
    .animation-image {
      width: 50vw;
    }
    .right,
    .network-list {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @for $i from 1 to 5 {
    [data-priority="#{$i}"] {
      //.animation-image-#{$i}{z-index: 1;}
      .network-list {
        ul {
          li:nth-child(#{$i}) {
            //transform: translateX(-8.3333%);
            padding-right: 4.3333%;
            span {
              color: $dark-gray-1;
              font-size: 22px;
            }
            strong {
              color: $dark-gray-1;
              font-size: 66px;
            }
          }
        }
      }
      .animation-image-#{$i} {
        opacity: 1;
        z-index: 1;
      }
      .factory-list-#{$i} {
        opacity: 1;
        z-index: 1;
      }
    }
  }
}

.factory-list {
  margin-top: 80px;
  margin-bottom: 100px;
  .inner {
    padding-left: 18px;
    padding-right: 18px;
  }
  h2 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 12px;
    margin-top: 20px;
  }
  ul {
    li {
      .box {
        background: $light-gray-3;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 14px;
      }
      .factory {
        font-size: 20px;
        line-height: 24px;
        color: $dark-gray-1;
        font-weight: 500;
      }
      .address {
        font-size: 12px;
        color: $gray-3;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    transform: none !important;
    opacity: 1 !important;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-bottom: 0;
    width: 50vw;
    height: 100vh;
    position: absolute;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    .inner {
      padding: 0;
      max-width: 750px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 60px;
    }
    h2 {
      color: $white;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
    }
    h3 {
      color: $white;
      font-size: 14px;
      line-height: 22px;
      font-weight: 700;
      // margin-bottom: 14px;
      // margin-top: 20px;
      margin-bottom: 10px;
      margin-top: 17px;
    }
    ul {
      li {
        .box {
          gap: 4px;
          min-height: 120px;
          padding: 26px 20px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(10.5px);
        }
        .factory {
          color: $white;
          font-size: 18px;
          line-height: 24px;
        }
        .address {
          font-size: 13px;
          line-height: 1.3;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    ul {
      li {
        .box {
          padding-left: 30px;
          padding-right: 30px;
        }
        .factory {
          font-size: 20px;
        }
        .address {
          font-size: 14px;
          line-height: 1.4;
          font-weight: 300;
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    padding-left: 100px;
    padding-right: 100px;
    ul {
      li {
        .box {
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
  }
}

/* .gnetwork-container {
  .section01 { position: relative;width:100%;height: 100vh;overflow:hidden;
    &.action01 {
      .h1-par { opacity: 1; margin-top:100px; }
      .bg.global-bg-1.first {  opacity: 1;margin-top: 70vh;left: auto;top:auto; }
    }
    &.action02 {
      .content-01 { margin-top:-30vh; transition: all .6s ease; }
      .bg.global-bg-1.first {  opacity: 1; margin-top: 40vh;  }
    }
    &.action03 {
      .content-01 { margin-top:-60vh;opacity: 0; }
      .bg.global-bg-1.first { width: 100%;height: 100%;left:0;margin-left:0;margin-top:0;background-image:url("../images/globalnetwork-section-1.jpg"); }
    }
    &.action04 {
      .bg.global-bg-1.first { width: 50%;background-image:url("../images/globalnetwork-section-1-md.png"); }
      .global-bg-wrap .bg-content { left:50%;  }

      .item-1 { color:$dark-gray-1;padding-right:113px;transform: scale(1.5); }
      .item-2 { margin-right:0px }
      .item-3 { margin-right:0px }
      .item-4 { margin-right:0px }
    }
    &.action05 {
      .bg.global-bg-1.first {  background-image:url("../images/globalnetwork-section-2.png");  }
      .item-1 { color:$gray-1;padding-right:0;transform: scale(1); }
      .item-2 { color:$dark-gray-1;padding-right:113px;transform: scale(1.5); }
    }
    &.action06 {
      .bg.global-bg-1.first {  background-image:url("../images/globalnetwork-section-3.png");  }
      .item-2 { color:$gray-1;padding-right:0;transform: scale(1); }
      .item-3 { color:$dark-gray-1;padding-right:113px;transform: scale(1.5); }
    }
    &.action07 {
      .bg.global-bg-1.first {  background-image:url("../images/globalnetwork-section-4.png");  }
      .item-3 { color:$gray-1;padding-right:0;transform: scale(1); }
      .item-4 { color:$dark-gray-1;padding-right:113px;transform: scale(1.5); }
    }

    .content-01 { position: relative;transition: all .8s ease; z-index: 1;  }
    .h1-par {
      width: 660px;font-weight: 200;font-size: 66px;line-height: 79px;margin-top:500px;transition: all 1s ease;opacity: 0;
      strong { font-weight: 600; }
    }
    .text-01 {
      position: relative;padding-top:42px;margin-top: 80px;margin-left: 110px;font-weight: 400;font-size: 18px;line-height: 26px;
      &::before { content: '';position: absolute;left:0;top:0;width: 48px;height: 2px;background-color: $red; }
    }
  }

}

//.global-bg-wrap{
.global-bg-wrap{
  position:absolute; top:70px;left:0;width:100%;height:calc(100vh - 70px); z-index: 0;transition: all .8s ease;
  .container { display: flex;height: 100%; }
  .bg-section{
    width:50%; height: 100%;overflow: hidden;z-index: 1;
    .bg{
      position: absolute;width: 50%;height: 100%;left:0;top:0;background-size:cover; background-position:50% 50%;background-repeat: no-repeat; opacity: 0;transition: all .9s ease;
      &.global-bg-1{
        width:319px; height:383px; z-index: 5; background-image:url("../images/globalnetwork-section-1-sm.png"); background-size: cover;
        &.first {  opacity: 0; margin-left:270px;margin-top:90vh; }
        &.active{ background-image:url("../images/globalnetwork-section-1.png");}
      }
      &.global-bg-2{
        background-image:url("../images/globalnetwork-section-2.png"); z-index: 4;
      }
      &.global-bg-3{
        background-image:url("../images/globalnetwork-section-3.png"); z-index: 3;
      }
      &.global-bg-4{
        background-image:url("../images/globalnetwork-section-4.png"); z-index: 2;
      }
      &.active{
        opacity: 1;
      }
    }
  }

  .bg-content{
    position: absolute;left:130%;width: 50%;height: 100%;z-index:3;padding-right:15%;padding-top:15vh;transition: all .9s ease;
    li{
      color:$gray-1;margin-bottom:80px;text-align: right;transition: all .6s ease;
      span{display:block;margin-bottom:13px;font-size: 18px;font-weight: 500;line-height: 21px;}
      strong{font-size: 68px;font-weight: 700;line-height: 81px;}
      &:last-child{
        margin-bottom:0;
      }
      &.item-2 { margin-right:-150px }
      &.item-3 { margin-right:-300px }
      &.item-4 { margin-right:-550px }
    }
  }

}
*/

.global-num {
  padding: 315px 0 270px;
  color: $white;
  background: url("../images/globalnetwork-global-num.png") no-repeat 50% 50%;
  background-size: cover;
  .tit {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 100px;
    text-align: center;
    strong {
      display: inline-block;
      margin-bottom: 20px;
      font-size: 48px;
      font-weight: 700;
      line-height: 58px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
  }
  .col {
    width: calc((100% - 60px) / 4);
    border-top: 2px solid rgba(255, 255, 255, 0.6);
    margin-right: 20px;
    color: $white;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    &:hover {
      background: $white;
      color: #202022;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 48px 0 44px 19px;
      .tit {
        margin-bottom: 59px;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        strong {
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          .count {
            display: inline-block;
            margin: 0 10px 0 10px;
            font-size: 44px;
            line-height: 65px;
          }
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 110px 0 0;
    background-color: $black;
    background-image: url("../images/globalnetwork-global-num-mo.jpg");
    background-size: 100% auto;
    background-position: 0 0;
    .tit {
      margin-bottom: 81px;
      strong {
        font-size: 24px;
        line-height: 120%;
      }
      span {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .col {
      margin: 0 10px;
      width: calc(100vw - 36px);
      border-top: 1px solid $light-gray-2;
      &:first-child {
        border-top: 0;
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 46px 0 34px 10px;
        .tit {
          margin-bottom: 55px;
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;
        }
        p {
          font-size: 16px;
          line-height: normal;
          width: 100%;
          text-align: right;
          strong {
            .count {
              margin: 0 6px 0 10px;
              font-size: 44px;
              line-height: 52px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: -$header-height-lg-expand;
  }
}
.global-tab-wrap {
  padding: 173px 0 180px;
  background-color: $dark-gray-1;
  overflow-x: hidden;
  .tab-group {
    margin-bottom: 65px;
    .nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14px 12px;
      // max-width: 1336px;
      max-width: 980px;
      margin: 0 auto;
      li {
        a {
          display: inline-block;
          padding: 8px 24px;
          border-radius: 20px;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: $light-gray-2;
          transition: color 0.15s ease-in-out, background 0.15s ease-in-out;
        }
        &.active,
        &:focus,
        &:hover {
          a {
            color: $primary;
            background-color: $black;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 80px 0 212px;
    .tab-group {
      height: 34px;
      padding: 0;
      margin: 0 0 65px 0;
      overflow-x: scroll;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .tab {
        display: block;
        width: auto;
        height: 100%;
        padding: 0 18px;
        white-space: nowrap;
        overflow-x: auto;
        li {
          display: inline-block;
          padding: 0;
          margin: 0 14px 0 0;
          text-align: center;
          span {
            top: 0;
            line-height: 24px;
            font-size: 9px;
          }
          a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 7px 16px 8px 16px;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
  .global-accordion {
    .accordion-item {
      .accordion-button {
        display: flex;
        width: 100%;
        padding: 16px 22px;
        position: relative;
        border: 1px solid $gray-2;
        border-bottom: 0;
        &.align-column {
          strong {
            flex-direction: row;
          }
        }
        img {
          margin-right: 35px;
        }
        strong {
          display: flex;
          align-items: baseline;
          color: $light-gray-2;
          font-size: 32px;
          font-weight: 700;
          line-height: 38px;
          font-family: "Brand New Hyosung OTF";
          text-align: left;

          span {
            font-size: 18px;
            font-weight: 400;
            line-height: 21px;
          }
        }

        &::after {
          position: absolute;
          right: 22px;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          display: inline-block;
          width: 48px;
          height: 48px;
          content: "";
          border: 1px solid #202022;
          border-radius: 100%;
          background: url("../images/icon-plus.svg") no-repeat 50% 50%;
          transition: 0s;
        }
        &.active {
          border-bottom: 1px solid $gray-2;
          &::after {
            background-image: url("../images/icon-minus.svg");
          }
        }
      }
      &:last-child {
        .accordion-button {
          border-bottom: 1px solid $gray-2;
        }
      }
      .accordion-body {
        display: none;
        .list {
          border: 1px solid $dark-gray-4;
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 39px 113px 40px;
            border-bottom: 1px solid $dark-gray-4;
            .tit {
              margin-bottom: 8px;
              font-size: 28px;
              font-weight: 500;
              line-height: 33px;
              color: $light-gray-3;
            }
            address {
              margin-bottom: 16px;
              font-family: Roboto;
              font-size: 16px;
              color: $gray-1;
              font-weight: 400;
              line-height: 19px;
            }
            .num-line {
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
              color: $gray-1;
              span {
                display: inline-block;
                &:first-child {
                  margin-right: 20px;
                }
              }
            }
            &:last-child {
              border-bottom: 0;
            }
            button {
              color: $light-gray-2;
              font-size: 19px;
              font-weight: 600;
              line-height: 23px;
              border: 1px solid #202022;
              &:hover {
                color: $primary;
                transition: color 0.15s ease-in-out;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .gnetwork-container {
      .section01 {
        &.action01 {
          .h1-par {
            opacity: 1;
            margin-top: 100px;
          }
          .bg.global-bg-1.first {
            opacity: 1;
            margin-top: 70vh;
            left: auto;
            top: auto;
          }
        }
        &.action02 {
          .content-01 {
            margin-top: -30vh;
            transition: all 0.6s ease;
          }
          .bg.global-bg-1.first {
            opacity: 1;
            margin-top: 40vh;
          }
        }
        &.action03 {
          .content-01 {
            margin-top: -60vh;
            opacity: 0;
          }
          .bg.global-bg-1.first {
            width: 100%;
            height: 100%;
            left: 0;
            margin-left: 0;
            margin-top: 0;
            background-image: url("../images/globalnetwork-section-1.jpg");
          }
        }
        &.action04 {
          .bg.global-bg-1.first {
            width: 50%;
            background-image: url("../images/globalnetwork-section-1-md.png");
          }
          .global-bg-wrap .bg-content {
            left: 50%;
          }

          .item-1 {
            color: $dark-gray-1;
            padding-right: 113px;
            transform: scale(1.5);
          }
          .item-2 {
            margin-right: 0px;
          }
          .item-3 {
            margin-right: 0px;
          }
          .item-4 {
            margin-right: 0px;
          }
        }
        &.action05 {
          .bg.global-bg-1.first {
            background-image: url("../images/globalnetwork-section-2.png");
          }
          .item-1 {
            color: $gray-1;
            padding-right: 0;
            transform: scale(1);
          }
          .item-2 {
            color: $dark-gray-1;
            padding-right: 113px;
            transform: scale(1.5);
          }
        }
        &.action06 {
          .bg.global-bg-1.first {
            background-image: url("../images/globalnetwork-section-3.png");
          }
          .item-2 {
            color: $gray-1;
            padding-right: 0;
            transform: scale(1);
          }
          .item-3 {
            color: $dark-gray-1;
            padding-right: 113px;
            transform: scale(1.5);
          }
        }
        &.action07 {
          .bg.global-bg-1.first {
            background-image: url("../images/globalnetwork-section-4.png");
          }
          .item-3 {
            color: $gray-1;
            padding-right: 0;
            transform: scale(1);
          }
          .item-4 {
            color: $dark-gray-1;
            padding-right: 113px;
            transform: scale(1.5);
          }
        }

        .content-01 {
          position: relative;
          transition: all 0.8s ease;
          z-index: 1;
        }
        .h1-par {
          width: 100%;
          font-size: 26px;
          line-height: 120%;
          margin-top: 500px;
          strong {
          }
        }
        .text-01 {
          margin-top: 50px;
          margin-left: 0px;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    .global-accordion {
      .accordion-item {
        &:last-child {
          .accordion-button {
            border-bottom: 1px solid #333;
          }
        }
        .accordion-button {
          padding: 20px;
          border: 1px solid #333;

          &.active {
            border-bottom: 1px solid #333;
          }
          img {
            width: 30px;
            margin-right: 10px;
          }
          strong {
            font-size: 18px;
            line-height: 21.48px;
            span {
              font-size: 8px;
              font-weight: 400;
              line-height: 9.55px;
            }
          }

          &::after {
            right: 20px;
            width: 30px;
            height: 30px;
          }
        }
        .accordion-body {
          .list {
            border: 1px solid #202022;
            li {
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              padding: 30px 50px 30px 30px;
              border-bottom: 1px solid #202022;
              .tit {
                margin-bottom: 30px;
                font-size: 20px;
                line-height: 23.87px;
              }
              address {
                margin-bottom: 8px;
                font-size: 12px;
                line-height: 14.06px;
              }
              .num-line {
                font-size: 12px;
                font-weight: 500;
                line-height: 14.06px;
                span {
                  display: block;
                  width: 100%;
                  &:first-child {
                    margin: 0 0 4px 0;
                  }
                  &:last-child {
                    margin-bottom: 40px;
                  }
                }
              }
              button {
                font-size: 14px;
                line-height: 16.8px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .gnetwork-container {
    .section01 {
      &.action01 {
        .h1-par {
          margin-top: 40px;
        }
        .bg.global-bg-1.first {
          margin-top: 440px;
          left: auto;
          top: auto;
          right: 18px;
        }
      }
      &.action02 {
        .content-01 {
          margin-top: -30vh;
        }
        .bg.global-bg-1.first {
          margin-top: 240px;
        }
      }
      &.action03 {
        .content-01 {
          margin-top: -60vh;
          opacity: 0;
        }
        .bg.global-bg-1.first {
          width: 100%;
          height: 100%;
          right: 0;
          margin-left: 0;
          margin-top: 0;
          background-image: url("../images/globalnetwork-section-1-md.png");
          &:after {
            opacity: 0.3;
          }
        }
      }
      &.action04 {
        .bg.global-bg-1.first {
          width: 100%;
          background-image: url("../images/globalnetwork-section-1-md.png");
        }
        .global-bg-wrap .bg-content {
          left: 0%;
          z-index: 5;
          opacity: 1;
        }

        .item-1 {
          color: $white;
          padding-right: 0px;
          margin-bottom: 40px;
        }
        .item-2 {
          margin-right: 0px;
        }
        .item-3 {
          margin-right: 0px;
        }
        .item-4 {
          margin-right: 0px;
        }
      }
      &.action05 {
        .bg.global-bg-1.first {
          background-image: url("../images/globalnetwork-section-2.png");
        }
        .item-1 {
          color: $white;
          padding-right: 0;
          margin-bottom: 24px;
        }
        .item-2 {
          color: $white;
          padding-right: 0px;
          margin-bottom: 40px;
          margin-top: 16px;
        }
      }
      &.action06 {
        .bg.global-bg-1.first {
          background-image: url("../images/globalnetwork-section-3.png");
        }
        .item-2 {
          color: $white;
          padding-right: 0;
          margin-bottom: 24px;
        }
        .item-3 {
          color: $white;
          padding-right: 0px;
          margin-bottom: 40px;
          margin-top: 16px;
        }
      }
      &.action07 {
        .bg.global-bg-1.first {
          background-image: url("../images/globalnetwork-section-4.png");
        }
        .item-3 {
          color: $white;
          padding-right: 0;
          margin-bottom: 24px;
        }
        .item-4 {
          color: $white;
          padding-right: 0px;
          margin-top: 16px;
        }
      }

      .content-01 {
        position: relative;
        transition: all 0.8s ease;
        z-index: 1;
      }
      .h1-par {
        width: 100%;
        font-size: 26px;
        line-height: 120%;
        strong {
          display: block;
        }
      }
      .text-01 {
        margin-top: 50px;
        margin-left: 0px;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .global-bg-wrap {
    top: 70px;
    .container {
    }
    .bg-section {
      width: 100%;
      .bg {
        position: absolute;
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        &.global-bg-1 {
          width: 161px;
          height: 193px;
          background-image: url("../images/globalnetwork-section-1-sm.png");
          background-size: cover;
          &.first {
            margin-left: 0px;
            margin-top: 90vh;
            &:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 6;
              background: $black;
              opacity: 0;
            }
          }
          &.active {
            background-image: url("../images/globalnetwork-section-1.png");
          }
        }
        &.global-bg-2 {
          background-image: url("../images/globalnetwork-section-2.png");
          z-index: 4;
        }
        &.global-bg-3 {
          background-image: url("../images/globalnetwork-section-3.png");
          z-index: 3;
        }
        &.global-bg-4 {
          background-image: url("../images/globalnetwork-section-4.png");
          z-index: 2;
        }
        &.active {
          opacity: 1;
        }
      }
    }

    .bg-content {
      left: 0%;
      height: 100%;
      z-index: 3;
      padding-top: 15vh;
      opacity: 0;
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 0;
      transform: translate(0, -50%);
      top: 50%;
      ul {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      li {
        color: $gray-1;
        margin-bottom: 80px;
        text-align: center;
        transition-duration: 1s;
        span {
          display: block;
          margin-bottom: 13px;
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
        }
        strong {
          font-size: 68px;
          font-weight: 700;
          line-height: 81px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.item-2 {
          margin-right: -150px;
        }
        &.item-3 {
          margin-right: -300px;
        }
        &.item-4 {
          margin-right: -550px;
        }
      }

      li {
        margin-bottom: 24px;
        color: $white;
        span {
          margin-bottom: 10px;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
        }
        strong {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
  }
}

.exchange-rate {
  margin-left: 10px;
  font-weight: 700;
  @include media-breakpoint-down(lg){
    font-size: 20px;
    line-height: normal;
    margin-left: 20px;
  }
}
