.ceo-wrap {
  .ceo-section {
    height: 100vh;
    padding-top: $header-height-lg-expand;
    @include media-breakpoint-down(lg) {
      padding-top: $header-height;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 1.4s ease;
  }
  .container {
    position: relative;
    height: 100%;
  }

  .section01 {
    padding-bottom: 138px;
    margin-top: -250px;
    height: auto;
    .text {
      font-size: 28px;
      line-height: 1.357;
      font-weight: 500;
      margin-top: 70px;
    }
    @include media-breakpoint-down(lg) {
      padding-bottom: 100px;
      margin-top: 80px;
      padding-top: 0;
      .text {
        font-size: 16px;
        line-height: 26px;
        margin-top: 30px;
        br {
          display: none;
        }
      }
    }
  }
  .section02 {
    background: $black;
    .bg {
      background: url("../images/ceo-section02-bg.png") no-repeat 50% -200%;
      background-size: cover;
      @include media-breakpoint-down(lg) {
        background-image: url("../images/ceo-section02-bg-mo.jpg");
      }
    }
    .text-01 {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      font-weight: 700;
      font-size: 38px;
      line-height: 53px;
      opacity: 1;
      z-index: 1;
      color: $white;
      width: 100%;
      transition: all 0.7s ease;
      font-family: 'Brand New Hyosung OTF';
      @include media-breakpoint-down(lg) {
        font-size: 26px;
        line-height: 36px;
        padding-left: 18px;
        padding-right: 18px;
      }
    }
    .text-02 {
      position: absolute;
      width: 770px;
      top: 0;
      transform: translateY(0%);
      font-weight: 500;
      font-size: 24px;
      line-height: 35px;
      z-index: 1;
      color: $white;
      transition: all 0.7s ease;
      opacity: 0;
      .step-01 {
        opacity: 0;
      }
      .step-02 {
        width: 770px;
        margin-top: 40px;
        opacity: 0;
      }
      @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 26px;
        width: 100%;
        left: 0;
        padding-left: 18px;
        padding-right: 18px;
        .step-02 {
          width: auto;
        }
      }
    }
    &.active-01 {
      .text-01 {
        opacity: 0;
        top: 30%;
        transform: translateY(-30%);
        transition: all 0.7s ease;
      }
    }
    &.active-02 {
      // 2번째 active클래스 넣으면서 1번째 삭제
      .text-01 {
        opacity: 0;
        top: 30%;
        transform: translateY(-30%);
        transition: all 0.7s ease;
      }
      //  //
      .text-02 {
        opacity: 1;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.7s ease;
      }
      .step-01 {
        opacity: 1;
        transition: all 0.7s ease;
      }
      .step-02 {
        opacity: 0.5;
        transition: all 0.7s ease;
      }
    }
    &.active-03 {
      .step-01 {
        opacity: 0.5;
        transition: all 0.7s ease;
      }
      .step-02 {
        opacity: 1;
        transition: all 0.7s ease;
      }
    }
  }
  .section03 {
    .bg {
      background: url("../images/ceo-section03-bg.png") no-repeat 100% 80%;
      background-size: cover;
      @include media-breakpoint-down(lg) {
        background-image: url("../images/ceo-section03-bg-mo.jpg");
      }
    }
    .text-01 {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      font-weight: 700;
      font-size: 38px;
      line-height: 53px;
      opacity: 1;
      z-index: 1;
      color: $white;
      width: 100%;
      transition: all 0.7s ease;
      font-family: 'Brand New Hyosung OTF';
      @include media-breakpoint-down(lg) {
        font-size: 26px;
        line-height: 36px;
        padding-left: 18px;
        padding-right: 18px;
      }
    }
    .text-02 {
      position: absolute;
      width: 771px;
      top: 0;
      transform: translateY(0%);
      font-weight: 500;
      font-size: 24px;
      line-height: 35px;
      z-index: 1;
      color: $white;
      transition: all 0.7s ease;
      opacity: 0;
      .step-01 {
        opacity: 0;
      }
      .step-02 {
        width: 771px;
        margin-top: 40px;
        opacity: 0;
      }
      @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 26px;
        width: 100%;
        left: 0;
        padding-left: 18px;
        padding-right: 18px;
        .step-02 {
          width: auto;
        }
      }
    }
    &.active-01 {
      .text-01 {
        opacity: 0;
        top: 30%;
        transform: translateY(-30%);
        transition: all 0.7s ease;
      }
    }
    &.active-02 {
      // 2번째 active클래스 넣으면서 1번째 삭제
      .text-01 {
        opacity: 0;
        top: 30%;
        transform: translateY(-30%);
        transition: all 0.7s ease;
      }
      //  //
      .text-02 {
        opacity: 1;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.7s ease;
      }
      .step-01 {
        opacity: 1;
        transition: all 0.7s ease;
      }
      .step-02 {
        opacity: 0.5;
        transition: all 0.7s ease;
      }
    }
    &.active-03 {
      .step-01 {
        opacity: 0.5;
        transition: all 0.7s ease;
      }
      .step-02 {
        opacity: 1;
        transition: all 0.7s ease;
      }
    }
  }
}
