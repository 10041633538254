.nav-tabs {
  border-bottom: 0;
  flex-flow: inherit;
  .nav-item {
    width: 100%;
    .nav-link {
      width: 100%;
      background-color: $light-gray-3;
      font-size: 22px;
      line-height: 26px;
      font-weight: 700;
      color: $gray-2;
      border-radius: 0;
      border: 0;
      padding: 22px 0;
      &.active {
        background-color: $dark-gray-1;
        color: $white;
      }
    }
  }
  &.border-type {
    gap: 80px;
    .nav-item {
      width: auto;
      .nav-link {
        width: auto;
        background-color: transparent;
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        border-radius: 0;
        border: 0;
        padding: 6px 0 9px;
        position: relative;
        &.active {
          color: $white;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            background: $primary;
            left: 0;
            bottom: 0;
            border-radius: 5px;
          }
        }
      }
    }
  }
  &.normal-type {
    gap: 80px;
    .nav-item {
      width: auto;
      .nav-link {
        width: auto;
        background-color: transparent;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: $gray-1;
        border-radius: 0;
        border: 0;
        padding: 6px 0 9px;
        position: relative;
        &.active {
          color: $primary;
        }
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .nav-tabs {
    .nav-item {
      .nav-link {
        font-size: 16px;
        line-height: 24px;
        padding: 13px 0;
        &.type-1 {
          min-height: 74px;
        }
      }
    }
    &.border-type {
      gap: 40px;
      width: calc(100% + 120px);
      .nav-item {
        width: auto;
        .nav-link {
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          color: $gray-2;
          padding: 6px 0 16px;
        }
      }
    }
    &.normal-type {
      gap: 20px;
      .nav-item {
        width: auto;
        .nav-link {
          width: auto;
          background-color: transparent;
          font-size: 16px;
          line-height: 19px;
          font-weight: 700;
          color: $gray-1;
          border-radius: 0;
          border: 0;
          padding: 6px 0 9px;
          position: relative;
          &.active {
            color: $primary;
          }
        }
      }
    }
  }
  .scroll-tab {
    margin-left: -18px;
    margin-right: -18px;
    .nav-tabs {
      width: auto;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 18px;
      gap: 40px;
      flex-wrap: nowrap;
      justify-content: flex-start;
      &::-webkit-scrollbar {
        display: none;
      }
      .nav-item {
        flex: 0 0 auto;
      }
    }
  }
}
