html.is-allsearch-open{
  #header{mix-blend-mode: inherit !important;}
}

.allsearch{
  display: flex; background: $dark-gray-1;position: fixed;left: 0;top: 0;z-index: 999;width: 100vw; height: 320px;
  flex-direction: column;overflow: hidden;
  transform: translateY(-320px);  transition: transform .3s, opacity .15s;
  .active ~ &{transform: translateY(0); transition: transform .3s, opacity .15s;}
  .btn-close{
    display: flex;align-items: center;justify-content: center;font-weight: 700;position: absolute;right: 40px;top: 30px;z-index: 1;
    width: 48px;height: 48px;border-radius: 50%;border: 1px solid $white;
  }
}

.allsearch-header{
  flex: 0 0 $header-height-lg;display: flex;justify-content: space-between;align-items: center;padding: 0 40px;
  .logo-in-layer{
    background: url('../images/logo-white.svg') no-repeat 0 0;background-size: contain;width: 159px;height: 15px;
  }
}

.allsearch-body{
  flex-grow: 1;position: relative;display: block;
}

.allsearch-form{
  flex: 0 0 auto;padding: 0 18px;
  @include media-breakpoint-up(lg){
    max-width: 658px;padding: 0;margin: 46px auto 0;
  }
  .allsearch-form-inner{display: flex;align-items: center;border-bottom: 3px solid $dark-gray-4;}
  input{
    flex-grow: 1;border: 0;background: transparent;font-size: 18px;color: $white;line-height: 21px;font-weight: 700;height: 45px;display: block;padding: 12px 10px;
    @include media-breakpoint-up(lg){
      padding: 24px 10px;font-size: 32px;line-height: 38px;height: 86px;
    }
    &:focus{outline: none;}
    &.filled ~ .btn-search-delete{
      display: block;
    }
  }
  button{flex: none;padding: 10px;}
  .btn-search-delete{
    display: none;
  }
  @include media-breakpoint-up(lg){
    .icon-search-in-layer{width: 38px;height: 38px;}
  }
}
