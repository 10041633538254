/* 인재상 시작 */
.people-container {
  .people-section {
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
  .section01 {
    margin-bottom: 160px;
    padding-top: 200px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 80px;
      padding: 0 30px;
    }
    .container {
      position: relative;
      height: 100%;
      .section-title {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .title-top {
          display: flex;
          flex-direction: column;
          gap: 20px;
          span {
            font-weight: 600;
            font-size: 18px;
            line-height: 32px;
            color: #ff233d;
            text-align: center;
          }
          strong {
            font-weight: 700;
            font-size: 48px;
            line-height: 57px;
            text-align: center;
            font-family: "Brand New Hyosung OTF";
          }
          @include media-breakpoint-down(lg) {
            span {
              font-size: 14px;
              line-height: 22px;
            }
            strong {
              font-size: 28px;
              line-height: 36px;
            }
          }
        }
        .title-desc {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
  .section02 {
    margin-bottom: 170px;
    .section02-title {
      margin-bottom: 60px;
      p {
        font-size: 34px;
        font-weight: 700;
        line-height: 41px;
        color: #161618;
        margin-bottom: 14px;
        font-family: "Brand New Hyosung OTF";
      }
      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
        p {
          font-size: 24px;
          line-height: 34px;
          margin: 0;
        }
      }
    }
    .box {
      background-color: #202022;
      padding: 50px 70px;
      height: 100%;
      strong {
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        color: #fff;
        font-family: "Brand New Hyosung OTF";
      }
      span {
        font-weight: 700;
        font-size: 16px;
        color: #ff233d;
        line-height: 23px;
        padding-left: 12px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #fff;
        border-top: 1px solid #444444;
        margin-top: 20px;
        padding-top: 20px;
        .sub {
          font-size: 18px;
          font-weight: 800;
          line-height: 17px;
          color: #fff;
          display: block;
          margin-bottom: 12px;
          padding: 0;
        }
      }
      @include media-breakpoint-down(lg) {
        padding: 20px 15px;
        height: 181px;
        &.type-1 {
          height: auto;
          min-height: 225px;
        }
        strong {
          font-size: 20px;
          line-height: 24px;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
        }
        p {
          font-weight: 500;
          font-size: 12px;
          margin-top: 4px;
          padding-top: 8px;
          color: #aaaaaa;
          word-break: normal;
          .sub {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 16px;
            color: #fbfbfb;
          }
        }
      }
    }
  }
  .section03 {
    display: flex;
    align-items: center;
    color: $white;
    text-align: center;
    height: 800px;
    background: url("../images/jobpeople-section03-img01.jpg") no-repeat 50% 50%;
    background-size: cover;
    strong {
      font-weight: 700;
      font-size: 48px;
      line-height: 57px;
      font-family: "Brand New Hyosung OTF";
    }
    p {
      font-weight: 500;
      font-size: 28px;
      line-height: 38px;
      margin-top: 30px;
      margin-bottom: 70px;
    }
    @include media-breakpoint-down(lg) {
      height: 460px;
      background: url("../images/jobpeople-section03-img01.jpg") no-repeat 40%
        50%;
      background-size: cover;
      strong {
        font-size: 24px;
        line-height: 34px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
        margin-top: 14px;
        margin-bottom: 70px;
      }
    }
  }
}
/* 인재상 끝 */

/* 인사제도 시작 */
.system-container {
  overflow: hidden;
  .system-section {
    position: relative;
    width: 100%;
    .section-title {
      font-family: "Brand New Hyosung OTF";
    }
  }
  .alt-text {
    overflow: hidden;
    width: 0;
    height: 0;
  }
  .section01 {
    padding-top: 70px;
    margin-top: -80px;
    .container {
      position: relative;
      height: 100%;
    }

    .h1-par {
      width: 860px;
      margin-top: 180px;
      font-weight: 200;
      font-size: 66px;
      line-height: 79px;
      strong {
        display: block;
        font-weight: 600;
      }
    }
    .text-01 {
      position: relative;
      padding-top: 42px;
      margin-top: 80px;
      font-weight: 400;
      font-size: 17px;
      line-height: 19px;
      margin-left: 110px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 48px;
        height: 2px;
        background-color: $red;
      }
    }
    .visually-image {
      margin-top: 70px;
      height: 452px;
      background: url("../images/jobpeople-section01-img01.jpg") no-repeat 50%
        50%;
      background-size: cover;
    }
  }
  .section02 {
    background-color: $dark-gray-1;
    color: $white;
    padding-top: 200px;
    .container {
      position: relative;
      height: 100%;
    }
    .section-title {
      display: block;
      font-weight: 700;
      font-size: 48px;
      line-height: 57px;
      text-align: center;
    }
    .section-p {
      font-size: 16px;
      line-height: 24px;
      margin-top: 30px;
      margin-bottom: 124px;
      text-align: center;
    }
    // .image-wrap {  height: 1532px;background: url('../images/jobsystem-section02-img01.png') no-repeat 50% 50%;  }

    .section02-lists {
      margin-top: -195px;
      .row > div + div {
        margin-top: 140px;
      }
      strong {
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;
        font-family: "Brand New Hyosung OTF";
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }
  .section03 {
    background-color: $dark-gray-1;
    color: $white;
    padding-top: 300px;
    .container {
      position: relative;
      height: 100%;
    }
    .detail {
      height: 974px;
      strong {
        display: block;
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        font-family: "Brand New Hyosung OTF";
      }
      .section-title {
        display: block;
        font-weight: 700;
        font-size: 48px;
        line-height: 57px;
        padding-top: 76px;
        margin-bottom: 120px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        margin-top: 30px;
        margin-bottom: 60px;
        & + strong {
          padding-top: 30px;
          border-top: 1px solid $dark-gray-3;
        }
      }
    }
    .image-wrap {
      position: absolute;
      right: -20%;
    }
  }

  .system-btn-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    li {
      flex: 0 0 calc((100% - 40px) / 3);
      position: relative;
      @include media-breakpoint-down(lg) {
        flex: 0 0 100% !important;
      }
    }
    .box {
      position: relative;
      background-color: $dark-gray-2;
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(lg) {
        aspect-ratio: auto;
      }
    }
    a {
      display: block;
      flex-grow: 1;
      padding: 40px;
      color: $white;
      transition: background-color 0.2s;
      @include media-breakpoint-down(lg) {
        padding: 30px;
        height: auto !important;
      }
    }
    .title-area {
      padding-right: 50px;
      position: relative;
      &::after {
        content: "";
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 48px;
        height: 48px;
        background: $dark-gray-1 url("../images/icon-plus.svg") no-repeat 50%
          50%;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
      }
      strong {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: $white;
        transition: 0.25s;
        flex-grow: 1;
        padding-top: 10px;
        display: block;
      }
      @include media-breakpoint-down(lg) {
        align-items: center;
        &::after {
          width: 30px;
          height: 30px;
        }
        strong {
          font-size: 20px;
          padding-top: 0;
          width: 80%;
          &.type-1 {
            width: 100%;
          }
        }
      }
    }
    .sub {
      display: block;
      margin-top: 9px;
      font-size: 14px;
      line-height: 22px;
      color: $white;
      opacity: 0.8;
    }
    .content {
      position: relative;
      padding-top: 60px;
      &.text-2 {
        padding-top: 34px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
        }
      }
      p + p {
        margin-top: 14px;
      }
      @include media-breakpoint-down(lg) {
        padding-top: 37px;
        transition: none !important;
      }
    }
    a[aria-expanded="true"] {
      background-color: $dark-gray-2 !important;
      .title-area {
        strong {
          color: $gray-3;
        }
        &::after {
          background-image: url("../images/icon-minus.svg");
        }
      }
    }
    &.btn-white-style {
      .title-area {
        &::after {
          background-color: #fff;
        }
      }
    }
    &.column-2,
    &.column-3 {
      gap: 0;
      .box {
        background: transparent;
      }
      li {
        & + li {
          &::after {
            content: "";
            position: absolute;
            border-left: 1px solid rgba(255, 255, 255, 0.4);
            top: 0;
            left: 0;
            bottom: 0;
          }
        }
      }
      @include media-breakpoint-down(lg) {
        margin-left: -18px;
        margin-right: -18px;
        /* .box{aspect-ratio: 375/323 !important;} */
        li + li {
          &::before {
            content: "";
            position: absolute;
            border-top: 1px solid rgba(255, 255, 255, 0.4);
            top: 0;
            left: 0;
            right: 0;
          }
          &::after {
            display: none;
          }
        }
      }
    }
    &.column-2 {
      li {
        flex: 0 0 50%;
        &:nth-child(n + 3) {
          &::before {
            content: "";
            position: absolute;
            border-top: 1px solid rgba(255, 255, 255, 0.4);
            top: 0;
            left: 0;
            right: 0;
          }
        }
        &:nth-child(odd) {
          &::after {
            display: none;
          }
        }
      }
      .box {
        aspect-ratio: 668/377;
      }
    }
    &.column-3 {
      li {
        flex: 0 0 33.333333%;
        &:nth-child(3n + 1) {
          &::after {
            display: none;
          }
        }
      }
      .box {
        aspect-ratio: 445/293;
        width: 100%;
        height: 100%;
      }
    }
  }

  .section04 {
    background-color: $dark-gray-1;
    color: $white;
    padding-top: 166px;
    .section-sub {
      & + .section-sub {
        margin-top: 140px;
        @include media-breakpoint-down(lg) {
          margin-top: 100px;
        }
      }
    }
    .section-title {
      display: block;
      font-weight: 700;
      font-size: 48px;
      line-height: 57px;
      margin-bottom: 50px;
    }
    .image-wrap {
      margin-bottom: 40px;
      height: 315px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        height: 100%;
        object-fit: cover;
        max-width: none;
      }
      &.left {
        img {
          right: 0;
        }
      }
      &.right {
        img {
          left: 0;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      padding-top: 0px;
      .section-title {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 30px;
      }
      .image-wrap {
        margin-bottom: 30px;
        margin-left: -18px;
        height: auto;
        img {
          position: static;
          max-width: 100%;
        }
      }
    }
  }

  .section05 {
    background-color: $dark-gray-1;
    color: $white;
    padding-bottom: 240px;
    .section-title {
      display: block;
      font-weight: 700;
      font-size: 48px;
      line-height: 57px;
      padding-top: 240px;
    }
    .section-p {
      font-size: 16px;
      line-height: 24px;
      margin-top: 30px;
      margin-bottom: 50px;
    }
    ul {
      li {
        a[aria-expanded="true"] {
          height: auto;
        }
      }
    }
    .column-3 {
      background: url("../images/jobsystem-section05-bg01.png") no-repeat 50%
        50%;
      background-size: cover;
      li {
        &:nth-child(2) {
          .content {
            padding-top: 5px;
          }
        }
      }
    }
    .column-2 {
      background: url("../images/jobsystem-section05-bg02.png") no-repeat 50%
        50%;
      background-size: cover;
    }

    .image-wrap {
      height: 294px;
      background: url("../images/jobsystem-section05-img01.svg") no-repeat 50%
        50%;
      margin-top: 140px;
      margin-bottom: 240px;
    }
  }
  .section05-1 {
    padding-top: 110px;
    background-color: $black;
    color: $white;
    .section-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 57px;
    }
    .section-p {
      font-size: 16px;
      line-height: 24px;
    }
    .row {
      padding-bottom: 240px;
    }
  }

  .section06 {
    .part-01 {
      display: flex;
      align-items: center;
      text-align: center;
      height: 650px;
      color: $white;
      background: url("../images/jobsystem-section06-bg.jpg") no-repeat 50% 50%;
      background-size: cover;
      strong {
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        font-family: "Brand New Hyosung OTF";
      }
      p {
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;
        margin-top: 40px;
        font-family: "Brand New Hyosung OTF";
      }
    }
    .part-02 {
      padding: 110px 0 240px;
      strong {
        display: block;
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;
        margin-bottom: 60px;
        font-family: "Brand New Hyosung OTF";
      }
      .content-box {
        display: flex;
      }
      .content {
        position: relative;
        em {
          display: block;
          margin-bottom: 14px;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          font-style: normal;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          word-break: break-all;
        }
        & + .content {
          padding-left: 66px;
          margin-left: 66px;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: $light-gray-2;
          }
        }
      }
    }
  }
  .section07 {
    display: flex;
    align-items: center;
    color: $white;
    text-align: center;
    height: 800px;
    background: url("../images/jobpeople-section03-img01.jpg") no-repeat 50% 50%;
    background-size: cover;
    strong {
      font-weight: 700;
      font-size: 48px;
      line-height: 57px;
      font-family: "Brand New Hyosung OTF";
    }
    p {
      font-weight: 500;
      font-size: 28px;
      line-height: 38px;
      margin-top: 30px;
      margin-bottom: 70px;
    }
  }
}
@include media-breakpoint-down(lg) {
  .system-container {
    .no-mobile {
      display: none;
    }
    ul.btn-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        width: 100%;
        padding: 10px 0;
        .box {
          background-color: $dark-gray-2;
          min-height: 90px;
          padding: 33px 30px;
        }
        .title-area {
          height: auto;
          &:hover,
          &:focus {
            strong {
              color: $gray-3;
            }
            .btn .icon-plus {
              background-image: url(../images/icon-minus.svg);
            }
            & + .content {
              height: auto;
              padding-top: 40px;
            }
          }
        }
        strong {
          font-size: 20px;
          line-height: 24px;
        }
        .sub {
          margin-top: 6px;
          font-size: 12px;
          line-height: 22px;
        }
        .content {
          margin-top: 0px;
        }
        .btn {
          right: 30px;
          top: 30px;
        }
      }
    }
    .section01 {
      padding-top: 70px;
      margin-top: -80px;

      .h1-par {
        width: 100%;
        margin-top: 40px;
        font-size: 26px;
        line-height: 31px;
      }
      .text-01 {
        margin-top: 50px;
        font-size: 14px;
        line-height: 22px;
        margin-left: 0px;
      }
      .visually-image {
        height: 200px;
        margin-top: 50px;
        margin-bottom: 0px;
        background-image: url("../images/jobpeople-section01-img01-mo.jpg");
        background-size: cover;
      }
    }
    .section02 {
      padding-top: 50px;
      .container {
        padding: 0 18px;
      }
      .section-title {
        font-size: 28px;
        line-height: 36px;
      }
      .section-p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: -30vw;
      }
      .image-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 300px 0 150px 0;
        @include media-breakpoint-down(md) {
          margin: 200px 0 150px 0;
          width: calc(100% + 36px);
          margin-left: -18px;
        }
        @include media-breakpoint-down(sm) {
          // img {
          //   scale: 1.7;
          // }
        }
      }

      .section02-lists {
        margin-top: -30vw;
        .row > div + div {
          margin-top: 60px;
        }
        strong {
          font-size: 24px;
          line-height: 34px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }
    }
    .section03 {
      padding-top: 120px;
      .container {
        position: relative;
        height: 100%;
      }
      .detail {
        height: auto;
        .section-title {
          font-size: 28px;
          line-height: 36px;
          padding-top: 0px;
          margin-bottom: 48px;
        }
        strong {
          font-size: 22px;
          line-height: 32px;
        }
        p {
          font-size: 16px;
          line-height: 26px;
          margin-top: 10px;
          margin-bottom: 40px;
          & + strong {
            padding-top: 0px;
            border-top: none;
          }
        }
      }
      .image-wrap {
        position: relative;
        right: 0%;
        background-size: 160% auto;
        background-position: 50% 0;
        margin-top: -80px;
        margin-bottom: -10vw;
        @include media-breakpoint-down(lg){
          margin-top: 50px;
          margin-bottom: 20vw;
          img {
            scale: 1.1;
            width: 100%;
          }
          @include media-breakpoint-down(sm) {
            img {
              scale: 1.7;
            }
          }
        }
      }
    }

    .section05 {
      padding-top: 90px;
      padding-bottom: 1px;
      .section-title {
        padding-top: 10px;
        font-size: 28px;
        line-height: 36px;
        &.p-mo {
          padding-top: 100px;
        }
      }
      .section-p {
        font-size: 16px;
        line-height: 26px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .type-01 {
        width: calc(100% + 36px);
        background: url("../images/jobsystem-section05-bg01-mo.jpg") no-repeat
          0% 0%;
        background-size: 100% 100%;
        li {
          padding: 0;
          position: relative;
          & + li {
            &::before {
              left: 0;
              top: 0;
              height: 1px;
              width: 100%;
            }
          }
          .box {
            min-height: 323px;
          }
        }
      }

      .type-02 {
        width: calc(100% + 36px);
        background: url("../images/jobsystem-section05-bg02-mo.jpg") no-repeat
          0% 0%;
        background-size: 100% 100%;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        margin-bottom: 280px;
        li {
          width: 100%;
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          &:nth-child(2n) {
            border-left: none;
          }
          .box {
            min-height: 488px;
          }
        }
      }

      .image-wrap {
        height: 797px;
        background: url("../images/jobsystem-section05-img01-mo.svg") no-repeat
          50% 0%;
        margin-top: 59px;
        margin-bottom: 100px;
      }
    }
    .section05-1 {
      padding-top: 100px;
      .section-title {
        display: block;
        margin-bottom: 30px;
        font-size: 28px;
        line-height: 36px;
      }
      .section-p {
        line-height: 26px;
      }
      .row {
        padding-bottom: 100px;
      }
    }

    .section06 {
      .part-01 {
        display: flex;
        align-items: center;
        text-align: center;
        height: 380px;
        padding: 60px 5%;
        color: $white;
        background: url("../images/jobsystem-section06-bg.jpg") no-repeat 50%
          50%;
        background-size: cover;
        strong {
          font-size: 18px;
          line-height: 22px;
        }
        p {
          font-size: 24px;
          line-height: 34px;
          margin-top: 20px;
        }
      }
      .part-02 {
        padding: 20px 0 100px;
        strong {
          font-size: 24px;
          line-height: 34px;
          margin-bottom: 40px;
          margin-top: 80px;
        }
        .content-box {
          flex-direction: column;
        }
        .content {
          em {
            margin-bottom: 10px;
          }
          p {
            width: 100%;
            font-size: 16px;
            line-height: 26px;
          }
          & + .content {
            padding-left: 0px;
            margin-left: 0px;
            margin-top: 30px;
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .section07 {
      height: 460px;
      background: url("../images/jobpeople-section03-img01.jpg") no-repeat 40%
        50%;
      background-size: cover;
      strong {
        font-size: 24px;
        line-height: 34px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
        margin-top: 14px;
        margin-bottom: 70px;
      }
      .btn-lg {
        width: 100%;
      }
    }
  }
}
/* 인사제도 끝 */

/* 직무 소개 시작 */
.job-wrap {
  @include media-breakpoint-up(lg) {
    padding-top: 200px;
    .job-wrap-head {
      padding-top: 180px;
      height: 1200px;
      position: relative;
      .title {
        font-weight: 200;
        font-size: 66px;
        line-height: 79px;
        color: $dark-gray-2;
        strong {
          font-weight: 500;
        }
      }
      .desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: $dark-gray-3;
        position: relative;
        padding: 40px 0 60px;
        margin-top: 80px;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 48px;
          height: 2px;
          background-color: #ff233d;
        }
      }
      .job-title-content {
      }
    }
    .section {
      padding-top: 240px;
      .title {
        font-weight: 700;
        font-size: 48px;
        line-height: 57px;
        text-align: center;
        color: $dark-gray-1;
        margin-bottom: 30px;
        font-family: "Brand New Hyosung OTF";
      }
      .section-image {
        width: 100%;
        margin-bottom: 80px;
        height: 452px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .content-list {
        margin-bottom: 80px;
        .title {
          font-weight: 700;
          font-size: 34px;
          line-height: 41px;
          color: $dark-gray-1;
          text-align: left;
          font-family: "Brand New Hyosung OTF";
        }
        .desc {
          font-size: 16px;
          line-height: 30px;
          color: $dark-gray-3;
          margin-top: 30px;
          word-break: normal;
        }
      }
      .img {
        position: sticky;
        top: 200px;
      }
    }
    .section-employment {
      width: 100%;
      height: 800px;
      background: url("../images/section-img-09.png") 50% 50% no-repeat;
      background-size: cover;
      margin-top: 200px;
      position: relative;
      .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
          font-weight: 700;
          font-size: 48px;
          line-height: 57px;
          text-align: center;
          color: $white;
          font-family: "Brand New Hyosung OTF";
        }
        .desc {
          font-weight: 500;
          font-size: 28px;
          line-height: 38px;
          text-align: center;
          color: $white;
          margin-top: 30px;
        }
        .btn {
          margin-top: 70px;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .section {
      margin-bottom: 140px;
      .title {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: $dark-gray-1;
        margin-bottom: 50px;
        font-family: "Brand New Hyosung OTF";
      }
      .section-image {
        width: 100%;
        height: 240px;
        margin-bottom: 50px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .content-list {
        margin-bottom: 60px;
        .title {
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
          color: $dark-gray-1;
          text-align: left;
          margin-bottom: 0;
          font-family: "Brand New Hyosung OTF";
        }
        .desc {
          font-size: 16px;
          line-height: 26px;
          color: $dark-gray-3;
          margin-top: 20px;
          word-break: normal;
        }
      }
      .img {
        position: sticky;
        top: 200px;
      }
    }
    .section-employment {
      width: 100%;
      height: 500px;
      background: url("../images/section-img-09.png") 50% 50% no-repeat;
      background-size: cover;
      margin-top: 120px;
      position: relative;
      .content {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
          text-align: center;
          color: $white;
          font-family: "Brand New Hyosung OTF";
        }
        .desc {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          color: $white;
          margin-top: 14px;
        }
        .btn {
          width: 100%;
          margin-top: 70px;
        }
      }
    }
  }
}
/* 직무 소개 끝 */

/* 인재육성 시작 */
.human-wrap {
  padding: 200px 0 0 0;
  background-color: $dark-gray-1;
  .strategy-section {
    padding-bottom: 240px;
    &.section08 {
      display: flex;
      align-items: center;
      color: $white;
      text-align: center;
      height: 800px;
      background: url("../images/jobpeople-section03-img01.jpg") no-repeat 50% 50%;
      background-size: cover;
      padding-bottom: 0;
      strong {
        font-weight: 700;
        font-size: 48px;
        line-height: 57px;
        font-family: "Brand New Hyosung OTF";
      }
      p {
        font-weight: 500;
        font-size: 28px;
        line-height: 38px;
        margin-top: 30px;
        margin-bottom: 70px;
      }
    }
    .bg-image {
      // position: relative;
      .image-wrap {
        width: 100%;
        height: 100%;
        text-align: center;
        .alt-text {
          overflow: hidden;
          width: 0;
          height: 0;
        }
      }
    }
    .section-content {
      .section-content-title {
        font-weight: 700;
        font-size: 48px;
        line-height: 57px;
        text-align: center;
        color: $white;
        font-family: "Brand New Hyosung OTF";
      }
      .section-content-desc {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $white;

        max-width: 700px;
        margin: 30px auto 0;
        font-weight: 300;
      }
    }
    .section-content-list {
      margin-top: 140px;
      .section-image {
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .section-content-subtitle {
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      color: $white;
      margin-bottom: 30px;
      font-family: "Brand New Hyosung OTF";
    }
    .section-content-txt {
      font-size: 18px;
      line-height: 26px;
      color: $white;
    }
    .list-box {
      display: flex;
      gap: 15px;
      li {
        color: $white;
        position: relative;
        padding-left: 15px;
        line-height: 26px;
        font-size: 18px;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 10px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $white;
        }
      }
      &.case-1 {
        > ul {
          width: calc(100% / 2);
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }
      }
    }
    .section-btn-group {
      display: flex;
      justify-content: center;
      margin-top: 80px;
    }
    .education-txt {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: $gray-1;
      margin-bottom: 48px;
    }
    .education-list {
      .education-list-title {
        display: flex;
        border-bottom: 1px solid $dark-gray-4;
        padding: 18px 0;
        .key {
          width: 40%;
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          color: $gray-1;
        }
        .value {
          width: 60%;
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          color: $gray-1;
        }
      }
      .education-list-content {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $dark-gray-4;
        padding: 40px 0;
        .key {
          width: 40%;
          font-weight: 700;
          font-size: 34px;
          line-height: 41px;
          color: $white;
          position: relative;
          font-family: "Brand New Hyosung OTF";
          span {
            display: block;
            font-weight: 700;
            font-size: 12px;
            line-height: 8px;
            color: $white;
            margin-top: 6px;
          }
          &::after {
            content: "";
            position: absolute;
            width: 2px;
            height: 22px;
            background-color: #aaaaaa;
            right: 47px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .value {
          width: 60%;
          font-size: 16px;
          line-height: 30px;
          color: $white;
        }
      }
    }
    .professionalism-wrap {
      margin-bottom: 140px;
      .professionalism-list {
        display: flex;
        margin-bottom: 30px;
        .professionalism-img {
          width: 50%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .professionalism-desc {
          width: 50%;
          font-size: 16px;
          line-height: 30px;
          color: $white;
          padding-left: 113px;
        }
      }
    }
    .program-wrap {
      display: flex;
      flex-flow: wrap;
      gap: 20px;
      @media (max-width: 1324px) {
        gap: 18px;
      }
      .program-list {
        width: calc(33% - 10px);
        padding: 50px 40px;
        background-color: $dark-gray-2;
        padding-bottom: 15%;
        .program-list-title {
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          color: $white;
        }
        .program-list-desc {
          margin-top: 30px;
          li {
            font-size: 16px;
            line-height: 24px;
            color: $white;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 3px;
              height: 3px;
              background-color: $white;
              border-radius: 50%;
              top: 10px;
              left: -16px;
            }
          }
        }
      }
    }
  }
  .more-btn {
    color: $red;
  }
  @include media-breakpoint-down(lg) {
    padding: 0;
    .strategy-section {
      padding-bottom: 160px;
      &.section08 {
        background-size: cover;
        height: 500px;
        strong {
          font-size: 24px;
          line-height: 34px;
        }
        p {
          font-size: 18px;
          line-height: 22px;
          margin-top: 14px;
          margin-bottom: 70px;
        }
        .btn {
          width: 100%;
        }
      }
      .bg-image {
        .image-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          transform: scale(1.1);
        }
      }
      .section-content {
        padding: 0 14px;
        .section-content-title {
          font-size: 28px;
          line-height: 36px;
        }
        .section-content-desc {
          font-size: 16px;
          line-height: 26px;
        }
      }
      .section-content-list {
        margin-top: 60px;
      }
      .section-content-subtitle {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 20px;
        margin-top: 30px;
      }
      .section-content-txt {
        font-size: 16px;
      }
      .list-box {
        display: block;
        li {
          padding-left: 25px;
          font-size: 16px;
          &::before {
            left: 10px;
          }
        }
      }
      .section-btn-group {
        margin-top: 50px;
        .btn {
          width: 100%;
        }
      }
      .education-txt {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
      }
      .education-list {
        .education-list-title {
          padding: 10px 0;
          .key {
            width: 30%;
            font-size: 14px;
            line-height: 22px;
          }
          .value {
            width: 70%;
            font-size: 14px;
            line-height: 22px;
            padding-left: 16px;
          }
        }
        .education-list-content {
          align-items: start;
          padding: 30px 0;
          justify-content: space-between;
          .key {
            width: 30%;
            font-size: 13px;
            line-height: 19px;
            span {
              font-size: 10px;
              line-height: 18px;
              margin-top: 10px;
              font-weight: 300;
            }
            &::after {
              content: none;
            }
          }
          .value {
            width: 70%;
            padding-left: 16px;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      .professionalism-wrap {
        margin-bottom: 140px;
        .professionalism-list {
          display: block;
          margin-bottom: 60px;
          .professionalism-img {
            width: 100%;
          }
          .professionalism-desc {
            width: 100%;
            line-height: 26px;
            padding-left: 0;
            margin-top: 30px;
          }
        }
      }
      .program-wrap {
        display: block;
        .program-list {
          width: 100%;
          padding: 40px 30px;
          margin-bottom: 20px;
          .program-list-title {
            font-size: 20px;
            line-height: 24px;
          }
          .program-list-desc {
            margin-top: 40px;
            margin-left: 5px;
            li {
              font-size: 14px;
              line-height: 22px;
              padding-left: 16px;
              &::before {
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}
/* 인재육성 끝 */
