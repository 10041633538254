.table-container {
  overflow: auto;
  position: relative;
  .pagination {
    margin-top: 80px;
  }
}
.table-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.table {
  width: 100%;
  &.custom-table {
    &.type-1 {
      tbody td,
      thead th {
        font-size: 16px;
        line-height: 24px;
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 22px;
          position: relative;
        }
      }
    }
    thead {
      th {
        background-color: $light-gray-3;
        font-weight: 600;
        padding: 17px 30px;
        border: 1px solid $light-gray-1;
        color: $dark-gray-4;
      }
    }
    tbody {
      tr {
        &.active {
          td {
            background-color: rgba(255, 35, 61, 0.03);
            font-weight: 600;
          }
        }
      }
      td {
        padding: 17px 22px;
        border: 1px solid $light-gray-2;
        color: $dark-gray-4;
        p,
        span,
        a {
          color: $dark-gray-4;
        }
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .table {
    &.custom-table {
      thead {
        th {
          font-size: 14px;
          line-height: 22px;
          padding: 14px 20px;
        }
      }
      tbody {
        td {
          font-size: 14px;
          line-height: 22px;
          padding: 14px 20px;
          &.type-1 {
            padding: 14px 61px 14px 20px;
          }
        }
      }
    }
  }
}
