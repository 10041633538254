.fixed-indicator {
  position: sticky;
  top: 160px;
  left: 70px;
  width: fit-content;
  z-index: 10;
  margin-bottom: -180px;
  ol {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  li {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 24px;
    padding-left: 48px;
    &.active a {
      font-size: 18px;
      line-height: 1.3;
      opacity: 1;
      color: $danger;
    }
    > a {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 1;
      transition: 0.2s;
      color: $dark-gray-1;
      opacity: 0.2;
      > span {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }
  .progress-circle {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
    display: block;
    .bg {
      fill: rgba(0, 0, 0, 0.2);
    }
    .fg {
      fill: $red;
    }
  }
  .year {
    display: block;
    font-weight: 600;
  }
  .title {
    display: block;
    font-weight: 600;
  }
  .year ~ .title {
    font-weight: 400;
  }
  progress {
    display: none;
  }
  .fixed-indicator-arrow {
    display: none;
  }

  &[data-theme="dark"] {
    li {
      > a {
        color: #454546;
        opacity: 1;
      }
      &.active {
        > a {
          color: $red;
        }
      }
    }
    .progress-circle {
      .bg {
        fill: #454546;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .fixed-indicator {
    position: sticky;
    top: auto;
    bottom: 0;
    left: 0px;
    width: 100%;
    margin-bottom: 0px;
    background-color: $white;
    height: 100px;
    transition: transform 0.3s;
    &.before {
      transform: translateY(110px);
    }
    ol {
      height: 100%;
    }
    li {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      padding: 18px 18px;
      text-align: center;
      background: $white;
      &.active {
        font-size: 20px;
        z-index: 1;
      }
      &:first-child {
        [aria-label="prev"] {
          visibility: hidden;
        }
      }
      &:last-child {
        [aria-label="next"] {
          visibility: hidden;
        }
      }
      > a {
        display: block;
        color: $dark-gray-1;
        font-size: 14px;
        opacity: 1;
        position: absolute;
        left: 70px;
        right: 70px;
        top: 18px;
        height: 48px;
        &:hover,
        &:focus {
          font-size: 14px;
        }
        > span {
          justify-content: center;
          flex-direction: column-reverse;
          gap: 0;
          height: 100%;
        }
      }
    }
    .progress-circle {
      display: none;
    }
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $dark-gray-1;
    }
    .year {
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
    }
    .year ~ .title {
      font-weight: 600;
    }
    .progress-bar-box {
      display: block;
      height: 2px;
      background: #dddddd;
      border-radius: 1px;
      overflow: hidden;
      margin-top: 15px;
      .progress-bar-mo {
        display: block;
        height: 100%;
        background: #ff233d;
        border-radius: 4px;
      }
    }
    .fixed-indicator-arrow {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
