.popup-swipe{
  display: none;
  @include media-breakpoint-down(lg){
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img{
      width: 160px;
    }
  }
}