// 메인 비쥬얼 써클 크기 가 *세 동일함
$mainVisualCircle: 764px;
$mainVisualCircleHalf: calc($mainVisualCircle / 2);
$startPoint: 0;

.main-container {
  overflow-x: hidden;
}

// 섹션 1 : 메인 비주얼
#main-visual {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include media-breakpoint-down(lg) {
    height: calc(100vh - $header-height);
    height: calc(
      100vh - constant(safe-area-inset-bottom) - constant(safe-area-inset-top) -
        $header-height
    );
    height: calc(
      100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) -
        $header-height
    );
  }
  .visual-container {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    @include media-breakpoint-down(lg) {
      height: calc(
        100vh - constant(safe-area-inset-bottom) - constant(safe-area-inset-top) -
          $header-height
      );
      height: calc(
        100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) -
          $header-height
      );
    }

    .video-wrapper {
      width: 100vw;
      height: 100%;
      position: relative;
      &.left {
        &::before {
          content: "";
          display: block;
          width: $mainVisualCircle;
          height: $mainVisualCircle;
          background: $white;
          border-radius: 50%;
          position: absolute;
          left: calc((100vw - $mainVisualCircle) / 2 - 120px);
          bottom: calc((100vh - $mainVisualCircle) / 2 + 15px);
          animation: float01 2s linear infinite alternate;
        }
      }
      &.right {
        &::before {
          content: "";
          display: block;
          width: $mainVisualCircle;
          height: $mainVisualCircle;
          background: url("../images/main-visual-comfort-circle.png");
          background-size: contain;
          border-radius: 50%;
          position: absolute;
          left: calc((100vw - $mainVisualCircle) / 2 + 120px);
          bottom: calc((100vh - $mainVisualCircle) / 2 - 25px);
          animation: float03 1.5s linear infinite alternate;
        }
      }
      @include media-breakpoint-down(lg) {
        &::before {
          display: none !important;
        }
      }
    }

    .video-container {
      --clip: 382px at 50% 50%;
      clip-path: circle(var(--clip));
      transition: --clip 1s;
      // 위 반원일때
      position: relative;
      width: 100%;
      height: 100vh;

      @include media-breakpoint-down(lg) {
        clip-path: none;
        height: calc(100vh - $header-height);
        height: calc(
          100vh - constant(safe-area-inset-bottom) -
            constant(safe-area-inset-top) - $header-height
        );
        height: calc(
          100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) -
            $header-height
        );
      }
      @include media-breakpoint-up(lg) {
      }
    }

    .video-box {
      width: 100%;
      height: 100%;
      position: relative;
      //위 반원일때
      figure {
        width: 100%;
        height: 100%;
        video {
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100%;
        }
      }
    }
    .video-title-box {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .video-title-wrapper {
        width: 100%;
        height: 100vh;
        @include media-breakpoint-down(lg) {
          height: calc(100vh - $header-height);
          height: calc(
            100vh - constant(safe-area-inset-bottom) -
              constant(safe-area-inset-top) - $header-height
          );
          height: calc(
            100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) -
              $header-height
          );
        }
        display: flex;
        justify-content: flex-end;
        transition: all 0.4s;
        .video-title-wrap {
          width: 100%;
          display: flex;
          align-items: center;
          height: 100%;
          position: relative;
        }
      }
    }
  }

  .main-svg-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .main-visual-circle {
    width: 100%;
    height: 100%;
    overflow: visible;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.visual-wrapper {
  overflow: hidden;
  width: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  &.left-side {
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background: $black;
    width: $startPoint;
  }
  &.right-side {
    z-index: 100;
    background: $white;
  }
}

.video-title-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all 0.8s;
  color: $white;
  position: absolute;
  small {
    font-weight: 700;
    font-family: "Brand New Hyosung OTF";
    transition: all 0.6s;

    i {
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s;
    }
    span {
      transition: opacity 0.3s;
    }
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 40px;
    transition: opacity 0.6s;
    line-height: 1.142;
    .d-lg-block {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      font-size: 80px;
      margin-top: 60px;
      margin-bottom: 0;
    }
  }
  &.left {
    text-align: left;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    small {
      font-size: 12px;
    }
    h2 {
      opacity: 1;
    }
    @include media-breakpoint-up(lg) {
      bottom: auto;
      top: 50%;
      transform: translate(0, -50%);
      small {
        font-size: 28px;
      }
    }
  }
  &.right {
    text-align: right;
    align-items: flex-end;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    small {
      font-size: 12px;
    }
    h2 {
      opacity: 1;
    }
    @include media-breakpoint-up(lg) {
      bottom: auto;
      top: 50%;
      transform: translate(0, -50%);
      small {
        font-size: 28px;
      }
    }
  }
  [data-state="center"] & {
    small {
      font-size: 20px;
      i {
        display: inline;
        visibility: visible;
        opacity: 1;
      }
      span {
        visibility: hidden;
        opacity: 0;
        display: none;
      }
      @include media-breakpoint-up(lg) {
        font-size: 28px;
      }
    }
    h2 {
      opacity: 0;
    }
    .transparent-btn {
      opacity: 0;
    }
    @include media-breakpoint-down(lg) {
      small {
        width: 50vw;
        text-align: center;
        padding: 0 18px;
      }
      &.left {
        width: 100vw;
        top: 75%;
        transform: translate(0, 0);
        left: -18px;
      }
      &.right {
        width: 100vw;
        top: 75%;
        transform: translate(0, 0);
        right: -18px;
      }
    }
    @include media-breakpoint-up(lg) {
      small {
        text-align: center;
        width: $mainVisualCircleHalf;
      }
      &.left {
        width: 100%;
        bottom: auto;
        top: 50%;
        transform: translate(calc((100% / 2) - $mainVisualCircleHalf), -63px);
      }
      &.right {
        width: 100%;
        bottom: auto;
        top: 50%;
        transform: translate(
          calc(((100% / 2) - $mainVisualCircleHalf) * -1),
          -42px
        );
      }
    }
  }
  [data-state="left"] &,
  [data-state="right"] & {
    @include media-breakpoint-up(lg) {
      small {
        .fullscreen-none {
          display: none !important;
        }
      }
    }
  }
}

.transparent-btn {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  display: inline-flex;
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid $white;
  border-radius: 40px;
  color: $white;
  width: fit-content;
  white-space: break-spaces;
  width: max-content;
  margin-top: 48px;
  transition: opacity 0.6s;
  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 21px;
    padding: 14px 20px;
    margin-top: 96px;
  }
}

#main-visual {
  &[data-state="left"],
  &[data-state="right"] {
    @include media-breakpoint-down(lg) {
      //.visual-container .video-title-box .video-title-wrapper{width: 100% !important;}
    }
  }
}

@keyframes floatfloat0101 {
  0% {
    left: calc((100vw - $mainVisualCircle) / 2 - 100px);
    bottom: calc((100vh - $mainVisualCircle) / 2 + 15px);
  }

  100% {
    left: calc((100vw - $mainVisualCircle) / 2 - 110px);
    bottom: calc((100vh - $mainVisualCircle) / 2 + 25px);
  }
}

@keyframes float03 {
  0% {
    left: calc((100vw - $mainVisualCircle) / 2 + 110px);
    bottom: calc((100vh - $mainVisualCircle) / 2 - 25px);
  }

  100% {
    left: calc((100vw - $mainVisualCircle) / 2 + 120px);
    bottom: calc((100vh - $mainVisualCircle) / 2 - 35px);
  }
}

// 드래그
#dragme {
  position: absolute;
  height: 100%;
  left: 0;
  cursor: col-resize !important;
  z-index: 300;
  .drag-wrapper {
    width: 140px;
    height: 140px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 20px;
    a {
      cursor: grab;
      span {
        font-weight: 600;
        font-size: 16px;
      }
    }
    @include media-breakpoint-down(lg) {
      width: 48px;
      height: 48px;
      gap: 0;
      a {
        margin: -1px;
      }
    }
  }
}

// 섹션 1 : 메인 비주얼 끝

// -----------------------------
// -----------------------------

// 공통

.main-list-area {
  &.row {
    --bs-gutter-x: 1.125rem;
    --bs-gutter-y: 1.125rem;
    @include media-breakpoint-up(lg) {
      --bs-gutter-x: 1.25rem;
      --bs-gutter-y: 3.75rem;
    }
  }

  .main-list-item {
    // transition: all 0.3s ease;
    display: block;
    position: relative;
    height: -moz-fit-content;
    height: fit-content;
    .visual-contents-wrap {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 55.5%;
      overflow: hidden;
      box-sizing: inherit;
      // &::before {
      //   content: "";
      //   display: block;
      //   padding-top: 55.5%;
      //   width: 100%;
      //   float: left;
      //   box-sizing: inherit;
      // }
      img {
        display:block;
        width:100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
      .overlay {
        width: 100%;
        height: 80%;
        position: absolute;
        bottom: 0;
        display: none;
        background: linear-gradient(
          360deg,
          rgba(0, 0, 0, 0.9) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    .title-wrap {
      padding: 20px;
      background-color: $white;
      width: 100%;
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .list-sort-wrap {
      width: 100%;
    }
    &:not(.not-hover) {
      &::before {
        content: "";
        position: absolute;
        bottom: 10px;
        right: 15px;
        transform: translate(-10px, 0);
        background: url("../images/icon-top-arrow.svg") no-repeat center;
        background-size: contain;
        width: 20px;
        height: 20px;
        opacity: 0;
        overflow: hidden;
        z-index: 1;
        transition: opacity 0.3s 0.1s ease, transform 0.4s;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $primary;
        transition: width 0.3s ease;
      }
    }
  }

  .list-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: $dark-gray-1;
    transition: color 0.3s ease;
  }
  .list-sort {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #aaaaaa;
    transition: color 0.3s ease;
  }

  @include media-breakpoint-up(lg) {
    .main-list-item.type-2:not(.sub-list-item) {
      .visual-contents-wrap {
        .overlay {
          display: block;
        }
      }

      .title-wrap {
        position: absolute;
        bottom: 0;
        background: inherit;

        &::before {
          content: "";
          display: block;
          width: 65%;
          height: 1px;
          background-color: #d9d9d9;
          position: absolute;
          top: 0;
        }
      }
      .list-title {
        color: $white;
      }
      .list-sort {
        color: $white;
      }
    }

    .main-list-item {
      &:not(.not-hover):hover,
      &:not(.not-hover):focus {
        &::before {
          opacity: 1;
          transform: translate(-4px, -10px);
        }
        &::after {
          width: 100%;
        }
        .list-sort {
          color: $primary;
        }
      }
    }

    .main-sticky-section-01-wrap & {
      .main-list-item {
        .title-wrap {
          background-color: $dark-gray-1;
        }
      }
      .list-title {
        color: $white;
      }
      .list-sort {
        color: $gray-2;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .main-list-item {
      .visual-contents-wrap {
        .overlay {
          width: 100%;
          height: 50%;
          top: 0;
          position: absolute;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          display: block;
        }
      }
      .title-wrap {
        position: absolute;
        top: 0;
        background: inherit;
        height: 100%;
      }
    }
    .list-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: $white;
    }
    .list-sort {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: $white;
    }
  }
}

.sub-list-item-wrap {
  &.row {
    --bs-gutter-x: 1.125rem;
    --bs-gutter-y: 1.125rem;
    @include media-breakpoint-up(lg) {
      --bs-gutter-x: 1.25rem;
      --bs-gutter-y: 3.75rem;
    }
  }
  @include media-breakpoint-up(lg) {
    .sub-list-item {
      .visual-contents-wrap {
        .overlay {
          display: block;
        }
        .title-wrap {
          color: $white;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding-bottom: 12px;
        }
      }
      .title-wrap {
        padding: 12px 20px 20px 20px;
        border-bottom: 1px solid $dark-gray-1;
        position: relative;
        background: inherit;
        height: auto;
        .list-title {
          color: $dark-gray-1;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
  }
}

.list-items-wrap {
  .list-item {
    position: relative;
    overflow: hidden;
    .image-wrap {
      width: 100%;
      background-size: cover;
      background-position: center;
      position: absolute;
    }
    .text-wrap {
      position: relative;
      height: 100%;
      width: 100%;
      .title-container {
        width: 100%;
        height: 100%;
        .title-box {
          position: absolute;
          width: 100%;
        }
      }
      strong {
        color: $white;
        font-weight: 700;
        display: block;
        text-align: center;
        width: 100%;
      }
      p {
        color: $white;
        width: 100%;
        text-align: center;
        word-break: keep-all;
        white-space: break-spaces;
        line-height: 24px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2vw;
    .list-item {
      width: 100%;
      height: 240px;
      .image-wrap {
        transform: scale(1.4);
        height: 240px;
        transition: transform 0.3s ease;
      }
      .text-wrap {
        transition: transform 0.4s;
        top: 0;
        padding: 1.1vw 1.5vw;
        .title-container {
          &::after {
            content: "";
            transition: all 0.5s ease;
            border: 1px solid $white;
            display: block;
            opacity: 0;
            width: 100%;
            height: 100%;
          }
          .title-box {
            top: 42%;
            left: 0;
            transition: transform 0.4s ease;
          }
        }
      }
      strong {
        font-size: 34px;
        line-height: 41px;
      }
      p {
        margin-top: 16px;
        font-weight: 500;
        line-height: 19px;
        padding: 0 3vw;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
      }
      &:hover,
      &:focus {
        .image-wrap {
          transform: scale(1);
        }
        .text-wrap {
          .title-container {
            &::after {
              opacity: 1;
            }
            .title-box {
              transform: translateY(-25%);
              p {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .mo-swipe-indicator {
      padding: 0 30px;
      display: flex;
      flex-direction: column;
      gap: 10rem;
      align-items: center;
    }
    .list-item {
      width: 340px;
      height: 340px;
      position: relative;
      .image-wrap {
        height: 340px;
        width: 340px;
        border-radius: 50%;
      }
      .text-wrap {
        .title-container {
          .title-box {
            top: 36%;
          }
        }
        strong {
          font-size: 38px;
          line-height: 46px;
        }
        p {
          font-size: 16px;
          line-height: 26px;
          max-width: 220px;
          margin: 14px auto auto auto;
        }
      }
    }
  }
}

.list-items-swiper {
  margin: 40px 0;
  @include media-breakpoint-up(lg) {
    margin: 120px 0 0;
  }
  @include media-breakpoint-down(lg) {
    margin-left: -18px !important;
    margin-right: -18px !important;
    .list-items-wrap {
      position: relative;
      .swiper-navigator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto !important;
        margin-left: 24px;
      }
      padding: 0 0 46px 0 !important;
    }
  }
  .swiper-pagination {
    bottom: -6px !important;
  }
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid $black;
    background-color: $white;
    margin: 0 6px !important;
    opacity: 1;
    &-active {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.sticky-visual-wrapper {
  width: 100%;
  overflow: hidden;
}
.sticky-visual {
  display: block;
  position: absolute;
  top: 140px;
  left: 50%;
  width: 658px;
  height: 320px;
  margin-left: -669px;
  overflow: hidden !important;
  z-index: 1;
  .bg-img {
    height: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text {
    color: $white;
    position: absolute;
    left: 50%;
    margin-left: -668px;
    top: 100px;
    display: flex;
    flex-direction: column;
    gap: 42px;
    small {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
    }
    strong {
      font-size: 25px;
      line-height: 30px;
      font-weight: 800;
    }
  }
}

// 공통 끝

// -----------------------------
// -----------------------------

.main-materials-wrap,
.main-sticky-section-01-wrap,
.main-sticky-section-02-wrap {
  @include media-breakpoint-down(lg) {
    background: $white !important;
  }
}

// 섹션 2 : 메인 머트리얼
.main-materials-wrap {
  background-color: #f5f5f5;
  padding-top: 40px;
  position: relative;
  z-index: 3;
  @include media-breakpoint-up(lg) {
    padding-top: 140px;
    padding-bottom: 200px;
  }
}
.materials-text {
  font-weight: 300;
  font-size: 42px;
  line-height: 50px;
  color: #000;
  strong {
    font-weight: 600;
  }
}
// 섹션 2 : 메인 머트리얼 끝

// 섹션 3 :  메인 스티키 1
.main-sticky-section-01-wrap {
  position: relative;
  z-index: 2;
  @include media-breakpoint-up(lg) {
    padding: 140px 0 200px 0;
    background-color: $black;
    margin-top: -570px;
    .main-list-area {
      .title-area {
        position: relative;
        &::before {
          content: "";
          background-image: url("../images/main-section-bg-02.png");
          background-position: left center;
          background-size: contain;
          width: 300px;
          height: 300px;
          display: block;
        }
        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: $white;
          position: absolute;
          left: 0;
          bottom: 86px;
        }
      }
    }
  }
}
// 섹션 3 :  메인 스티키 1 끝

// 섹션 3 :  메인 스티키 2
.main-sticky-section-02-wrap {
  position: relative;
  z-index: 1;
  background-color: #f5f5f5;
  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column;
    padding-bottom: 275px;
    .container {
      order: 1;
    }
    .sticky-section {
      order: 2;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 140px;
    padding-bottom: 200px;
    margin-top: -570px;
  }
}
// 섹션 3 :  메인 스티키 2 끝

.section-wrapper {
  width: 100%;
  overflow: hidden;
}

[data-transition="N"] {
  .left-side,
  .drag-wrapper {
    transition: none !important;
  }
}
