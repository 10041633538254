.ir-wrap {
  position: relative;
}

//공통 ir-head
.ir-head {
  width: 100%;
  height: 160px;
  padding: 66px 0 53px;
  background: url("../images/ir-visual.png") no-repeat 50% 100%;
  background-size: auto 100vh;
  margin-top: -160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  top: 120px;
  z-index: 10;
  transition: top 0.5s 0.1s ease;
  .container {
    max-width: 100%;
  }
  @include media-breakpoint-between(lg, xl) {
    top: 108px;
  }
  @include media-breakpoint-down(lg) {
    height: 90px;
    padding: 19px 0;
    top: 108px;
    margin-top: -90px;
    background-image: url("../images/ir-visual-mo.jpg");
    background-size: 100% auto;
    html.scroll-down & {
      top: 48px;
    }
    .container {
      padding: 0;
    }
  }
  &.is-sticky {
    top: 0 !important;
    z-index: 999;
    .progress {
      opacity: 1;
    }
  }
  @include media-breakpoint-up(lg) {
    &.is-sticky {
      .progress {
        bottom: auto;
        top: 0;
      }
    }
  }
  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    opacity: 0;
    transition: opacity 0.1s;
    overflow: hidden;
    .bar {
      display: block;
      width: 0%;
      height: 100%;
      background-color: $primary;
    }
    @include media-breakpoint-up(lg) {
      bottom: auto;
      top: 0;
    }
  }
  .tab {
    @include media-breakpoint-down(lg) {
      &::-webkit-scrollbar {
        display: none;
      }
    }
    justify-content: center;
    li {
      position: relative;
      text-align: center;
      padding: 9px 0 0;
      a {
        padding: 9px 50px 0;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $gray-2;
        position: relative;
        &::before {
          content: "NOW";
          display: none;
          position: absolute;
          top: -9px;
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          font-size: 10px;
          font-weight: 800;
          line-height: 12px;
          letter-spacing: 0em;
          color: $primary;
        }
        &.active {
          color: $white;
          &::before {
            display: block;
          }
        }
        @include media-breakpoint-down(xl) {
          padding-left: 35px;
          padding-right: 35px;
        }
        @include media-breakpoint-down(lg) {
          &::before {
            top: -18px;
          }
        }
      }
      &::after {
        display: inline-block;
        height: 14px;
        width: 1px;
        background-color: $gray-1;
        content: "";
        position: absolute;
        right: 0;
        top: 18px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .tab-group {
      height: 50px;
      overflow-x: scroll;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .tab {
        width: auto;
        height: 100%;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 18px;
        gap: 50px;
        flex-wrap: nowrap;
        justify-content: flex-start;
        li {
          display: inline-block;
          padding: 24px 0 0 0;
          text-align: center;
          flex: 0 0 auto;
          span {
            top: 0;
            line-height: 24px;
            font-size: 9px;
          }
          a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0;
            font-size: 16px;
            line-height: 26px;
            font-weight: 700;
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

/* .ir-content {
  .ir-section {
    position: relative; z-index: 11; background-color: #fff;
  }
} */

.ir-section {
  padding: 0 0 160px;

  .head-title {
    padding: 160px 0 0;
    color: $dark-gray-1;
    font-size: 48px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0em;
    text-align: center;
    font-family: "Brand New Hyosung OTF";
  }
  .head-title2 {
    padding-top: 160px;
  }
  .head-txt {
    margin: 40px 0 100px;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
  .sheet-tab {
    margin: 100px 0 0;
    @include media-breakpoint-down(lg) {
      margin: 30px 0 0;
    }
  }
  .table-container {
    overflow-y: hidden;
  }
  .table-td-p12 {
    td {
      padding: 17px 12px !important;
    }
  }
  .table-td-p20 {
    td {
      padding: 17px 20px !important;
    }
  }
  .table-th-p20 {
    th {
      padding: 17px 20px !important;
    }
  }
  article {
    padding-top: 100px;
    .page-title {
      margin-bottom: 30px;
      color: $dark-gray-1;
      font-size: 34px;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
      font-family: 'Brand New Hyosung OTF';
    }
    .table {
      &.custom-table {
        thead {
          th {
            border-bottom-width: 2px;
          }
        }
        tbody {
          th {
            background-color: $light-gray-3;
            font-weight: 600;
            padding: 17px 30px;
            border: 1px solid $light-gray-1;
          }
          td {
            .num-list {
              li {
                &::before {
                  font-variant-numeric: tabular-nums;
                }
                span {
                  display: block;
                  text-indent: 4px;
                }
              }
            }
          }
        }
      }
      .num-list {
        li {
          // text-indent: -20px;
          // margin-left: 20px;
          text-indent: -17px;
          margin-left: 17px;
        }
      }
      .down {
        color: #1b65f6;
      }
      //20230707 스타일 추가 [S]
      .up {
        color: #FF233D;
      }
      //20230707 스타일 추가 [E]
    }
    .table-title {
      display: flex;
      justify-content: space-between;
      margin: 30px 0 16px;
      color: $dark-gray-3;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      span {
        font-weight: 400;
        color: $gray-2;
      }
    }
    .table-title2 {
      font-size: 16px;
    }
    .page-txt {
      color: $dark-gray-3;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.04px;
      text-align: left;
      .executive-directors-top {
        margin-bottom: 16px;
        color: $gray-2;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
      .executive-directors {
        li {
          .executive-img {
            height: 311px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .executive-desc {
            min-height: 645px;
            padding: 50px 40px;
            background: $light-gray-3;
            em {
              display: inline-block;
              margin-bottom: 28px;
              color: $primary;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }
            .name {
              margin-bottom: 50px;
              font-size: 34px;
              font-weight: 700;
              line-height: 41px;
              font-family: 'Brand New Hyosung OTF';
              span {
                display: inline-block;
                margin-left: 10px;
                font-size: 16px;
                font-weight: 500;
                color: $gray-1;
              }
              &.type-1 {
                font-family: 'Pretendard';
                span {
                  font-size: 14px;
                  line-height: 22px;
                  font-weight: 300;
                  margin-left: 0;
                }
              }
            }
            dl {
              display: flex;
              // flex-wrap: wrap;
              flex-wrap: nowrap;
              margin-bottom: 14px;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;

              dt {
                margin-right: 20px;
                color: $dark-gray-2;
                font-weight: 500;
              }
              dd {
                color: $gray-3;
                ul {
                  li {
                    margin-bottom: 14px;
                    // margin-left: 60px;
                    // text-indent: -60px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .clause {
        margin-bottom: 40px;
        color: $dark-gray-3;
        > strong {
          display: inline-block;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }
        li {
          margin-left: 20px;
          text-indent: -10px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          span {
            display: inline-block;
            font-size: 11px;
            vertical-align: bottom;
            margin-right: 4px;
          }
          .txt {
            margin: 10px 0 10px -30px;
          }
        }
      }
      @include media-breakpoint-down(lg) {
        .clause {
          margin-bottom: 30px;
          strong {
            margin-bottom: 20px;
          }
        }
        .btn-group {
          margin-top: 60px;
        }
      }
    }
    .btn-group {
      margin-top: 80px;
      button {
        i {
          margin-left: 8px;
        }
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  .table-bottom-noti {
    margin: 16px 0 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: right;
    color: $gray-2;
    @include media-breakpoint-down(lg){
      font-size: 12px;
    }
    &.text-left {
      text-align: left;
      margin-bottom: 100px;
      span {
        display: block;
        margin-left: 13px;
        text-indent: -13px;
      }
      p {
        margin: 0 0 10px 10px;
        text-indent: -10px;
        font-weight: 300;
      }
      > div {
        padding-left: 11px;
        padding-top: 10px;
        li {
          font-weight: 300;
        }
      }
    }
  }

  .dot-list {
    > li {
      padding: 0 0 14px 27px;
      position: relative;
      &::before {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: $dark-gray-3;
        content: "";
        position: absolute;
        left: 11px;
        top: 11px;
      }
      em {
        display: block;
        margin: 10px 0 0;
      }
      .num-list {
        padding: 10px 0 16px 0;
        color: $gray-3;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 50px 0 200px;
    .br-n br {
      display: none;
    }
    .head-title {
      padding: 0;
      font-size: 28px;
      line-height: 36px;
    }
    .head-title2 {
      padding-top: 0;
    }
    .head-txt {
      margin: 30px 0 120px;
      font-size: 16px;
      line-height: 26px;
    }
    .sheet-tab {
      margin: 30px 0 0;
    }
    .table-container {
      &::-webkit-scrollbar {
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 52px;
        background-color: $primary;
      }
    }
    article {
      padding: 50px 0;
      .page-title {
        font-size: 24px;
        line-height: 34px;
      }
      .page-txt {
        font-size: 16px;
        line-height: 26px;
        .executive-directors-top {
          margin-bottom: 10px;
          font-size: 12px;
          line-height: 22px;
        }
        .executive-directors {
          flex-wrap: wrap;
          li {
            width: 100%;
            margin-bottom: 30px;
            .executive-img {
              height: auto;
            }
            .executive-desc {
              min-height: auto;
              padding: 30px;
              em {
                margin-bottom: 20px;
                font-size: 12px;
                line-height: 22px;
              }
              .name {
                display: flex;
                flex-direction: column-reverse;
                margin-bottom: 20px;
                font-size: 18px;
                line-height: 26px;
                span {
                  margin: 0 0 20px 0;
                  font-size: 12px;
                  line-height: 22px;
                }
              }
              dl {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 22px;
                dt {
                  margin-right: 10px;
                }
                dd {
                  ul {
                    li {
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .table-wrap {
        .a-table {
          td {
            a {
              display: inline-block;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 20px;
            }
          }
        }
      }
      .table-container {
        .table {
          thead {
            th {
              font-size: 14px;
            }
          }
          tbody {
            font-size: 14px;
          }
        }
      }
      .table-title {
        margin: 20px 0 10px;
        font-size: 12px;
      }
      &:last-child {
        padding-bottom: 0;
      }
      .btn-group {
        button {
          width: 100%;
          justify-content: center;
          padding: 19px 0;
          &:first-child {
            margin: 0 0 20px 0;
          }
        }
      }
    }
    .dot-list {
      > li {
        padding: 0 0 20px 27px;
        .num-list {
          padding: 10px 0;
          font-size: 14px;
          line-height: 22px;
          li {
            text-indent: -15px;
            margin-left: 15px;
          }
        }
        &:last-child {
          padding: 0 0 0 27px;
        }
      }
    }
  }
  .ir-accordion {
    .accordion-body {
      display: none;
      margin-bottom: 30px;
    }
    .accordion-item {
      &:last-child {
        .accordion-body {
          margin-bottom: 0;
          .table-bottom-noti {
            margin-bottom: 0;
          }
        }
        .accordion-button {
          border-bottom: 1px solid $light-gray-1;
        }
      }
    }
    .accordion-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 16px 30px;
      margin-bottom: -1px;
      font-size: 20px;
      text-align: left;
      font-weight: 600;
      line-height: 24px;
      color: $dark-gray-4;
      background-color: $light-gray-3;
      border: 1px solid $light-gray-1;
      &::after {
        display: inline-block;
        width: 48px;
        height: 48px;
        content: "";
        border: 1px solid $light-gray-1;
        border-radius: 100%;
        background-image: url("../images/icon-plus.svg");
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: center;
        transition: 0s;
      }
      &.active {
        background: $dark-gray-4;
        color: $white;
        &::after {
          background-image: url("../images/icon-minus.svg");
          background-color: $light-gray-3;
          border-color: $dark-gray-2;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .ir-accordion {
      .accordion-item {
        //&:last-child{border-bottom:1px solid $light-gray-1;}
        .accordion-button {
          padding: 20px;
          font-size: 18px;
          line-height: 22px;
          padding-right: 50px;
          position: relative;
          &::after {
            width: 30px;
            height: 30px;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  //재무정보
  .graph-group {
    display: flex;
    margin-bottom: 100px;
    .graph-box {
      .tit {
        margin-bottom: 30px;
        font-size: 34px;
        font-weight: 700;
        line-height: 41px;
        text-align: center;
        font-family: 'Brand New Hyosung OTF';
      }
      .graph {
        position: relative;
        padding: 108px 37px 73px 38px;
        font-size: 14px;
        line-height: 22px;
        background-color: $light-gray-3;
        color: $gray-3;
        font-weight: 500;
        .unit {
          position: absolute;
          top: 36px;
        }
        .graph-line {
          position: relative;
          .line {
            position: relative;
            width: 100%;
            height: 52px;
            border-bottom: 1px solid $white;
            &.line6 {
              border-color: $black;
            }
            .graph-y {
              position: absolute;
              top: 40px;
              left: 0;
              padding-right: 16px;
              width: 42px;
              background-color: $light-gray-3;
              font-weight: 400;
            }
          }
        }
        .graph-bar {
          display: flex;
          justify-content: space-evenly;
          position: absolute;
          bottom: -32px;
          width: calc(100% - 66px);
          right: 0;
          .bar-line {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;
            justify-content: flex-end;
            .bar-box {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              .bar {
                display: inline-block;
                width: 28px;
                margin-bottom: 8px;
                border-radius: 24.5px 24.5px 0px 0px;
                &.bar-black {
                  background-color: #202022;
                }
                &.bar-red {
                  background-color: $primary;
                }
              }
              .badge {
                position: absolute;
                top: -52px;
                color: $white;
                height: 27px;
                padding: 0 12px;
                line-height: 25px;
                font-size: 16px;
                font-weight: 600;
                border-radius: 2px;
                background-color: $dark-gray-1;
                &::after {
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  -webkit-transform: translateX(-50%);
                  bottom: -7px;
                  width: 12px;
                  height: 8px;
                  content: "";
                  background: url("../images/ir-polygon.png") no-repeat 0 0;
                  background-size: 100%;
                }
              }
            }
            .graph-x {
              font-weight: 500;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
      .graph-box {
        .tit {
          margin-bottom: 10px;
          text-align: left;
          font-size: 24px;
          line-height: 34px;
        }
        .graph {
          padding: 30px;
          margin-bottom: 60px;
          font-size: 12px;
          height: 480px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .unit {
            top: 30px;
          }
          .graph-line {
            margin-bottom: 30px;
            .line {
              height: 54px;
              .graph-y {
                top: 44px;
              }
            }
            .graph-bar {
              display: flex;
              justify-content: space-evenly;
              position: absolute;
              bottom: -32px;
              right: 0;
              width: calc(100% - 54px);
              bottom: -25px;
              .bar-line {
                .bar-box {
                  .bar {
                    width: 18px;
                    margin-bottom: 8px;
                  }
                  .badge {
                    font-size: 14px;
                    &::after {
                      bottom: -7px;
                      width: 12px;
                      height: 8px;
                    }
                  }
                }
              }
            }
          }
        }
        :last-child {
          .graph {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .inquiry-card {
    padding: 50px 40px 45px;
    background-color: $light-gray-3;
    .name {
      margin-bottom: 50px;
      font-size: 28px;
      font-weight: 700;
      line-height: 41px;
      color: #444444;
    }
    dl {
      display: flex;
      margin-bottom: 14px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      dt {
        margin-right: 20px;
        color: #202022;
      }
      dd {
        color: $gray-3;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .inquiry-card {
      padding: 30px;
      .name {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        &.type-1 {
          margin-bottom: 20px;
        }
      }
      dl {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .table-link {
    &:hover,
    &:active {
      color: $primary;
    }
  }
  .disclosure {
    .accordion-item {
      .desc {
        padding: 56px 30px 80px;
        margin: 0;
      }
      &:last-child {
        .desc {
          padding: 56px 30px 0;
        }
      }
      .top-tit {
        margin: 80px 0 40px;
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        &.m-t-0 {
          margin-top: 0;
        }
      }
      dl {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
        dt {
          margin-bottom: 10px;
          color: $dark-gray-3;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }
        dd {
          margin-bottom: 10px;
          padding-left: 54px;
          position: relative;
          color: $dark-gray-4;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          &.p-0 {
            padding-left: 0;
          }
          > span {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
          }
          ul {
            margin-top: 10px;
            li {
              position: relative;
              margin-bottom: 10px;
              padding-left: 74px;
              > span {
                position: absolute;
                left: 0;
                display: inline-block;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          &.last {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .disclosure {
      .accordion-item {
        .desc {
          padding: 30px 20px 30px;
          dl {
            margin-bottom: 30px;
            dt {
              margin-bottom: 20px;
              font-size: 18px;
              line-height: 22px;
            }
            dd {
              padding-left: 50px;
              font-size: 14px;
            }
          }
          .top-tit {
            margin: 0 0 20px;
            font-size: 20px;
            line-height: 34px;
          }
        }
      }
    }
  }
  .search-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-bottom: 16px;
    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
    }
  }
  .search-bar {
    input {
      outline: none;
      background-color: $light-gray-3;
      border-color: $light-gray-3;
    }
  }
  @include media-breakpoint-down(lg) {
    .search-line {
      align-items: flex-start;
      flex-direction: column-reverse;
      margin-bottom: 10px;
      p {
        font-size: 12px;
      }
    }
    .search-bar {
      width: 100%;
      margin-bottom: 30px;
      .form-control {
        min-width: calc(100% - 94px);
      }
      button {
        max-height: 50px;
        height: 50px;
      }
    }
  }
  @at-root {
    .card-list {
      gap: 20px;
      margin-bottom: 100px;
      li {
        width: calc(25% - 15px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: 50px 87px 63px 40px;
        background-color: $light-gray-3;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        .num {
          color: $gray-3;
          &.noti {
            span {
              &:first-child {
                font-weight: 700;
                &::after {
                  display: inline-block;
                  margin: 0 4px;
                  background: $gray-3;
                  width: 2px;
                  height: 2px;
                  content: "";
                  border-radius: 100%;
                  vertical-align: middle;
                }
              }
            }
          }
        }
        .title {
          margin: 20px 0 94px;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
        }
        .date {
          color: $dark-gray-4;
        }
        button {
          position: absolute;
          bottom: 50px;
          right: 40px;
        }
        &:hover,
        &:focus {
          color: $white !important;
          background-color: $primary;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out;
          button {
            &.btn-download {
              background-color: $white !important;
              border-color: $white !important;
              .icon-download {
                background-image: url("../images/icon-download-hover.svg");
              }
            }
          }
          .date {
            color: $white;
          }
          .num {
            color: $white;
            &.noti {
              span {
                &:first-child {
                  &::after {
                    background-color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .guidelines-wrap {
      .card-list {
        li {
          padding-right: 74px;
          justify-content: start;
          height: 319px;
          .title {
            margin: 20px 0 136px;
          }
        }
      }
      @include media-breakpoint-down(lg) {
        .ir-section {
          padding-top: 100px;
          li {
            padding-right: 165px;
            height: 100%;
            .title {
              margin: 10px 0 58px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    @at-root {
      .card-list {
        margin-bottom: 24px;
        li {
          width: 100%;
          padding: 30px 174px 30px 30px;
          font-size: 12px;
          .title {
            margin: 10px 0 26px;
            font-size: 14px;
            line-height: 22px;
          }
          button {
            bottom: 30px;
            right: 30px;
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
  .report-top {
    flex-wrap: nowrap;
    margin-bottom: 140px;
    justify-content: space-between;
    .dot-list {
      margin-bottom: 50px;
    }
    .img {
      position: relative;
      width: 39.275%;
      height: 0;
      padding-bottom: 46.407%;
      border: 1px solid #E5E5E5;
      overflow: hidden;
      img {
        display:block;
        width:100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
    }

    .txt {
      padding-top: 30px;
      .tit {
        margin-bottom: 30px;
        font-size: 34px;
        font-weight: 700;
        line-height: 41px;
      }
      li {
        padding: 0 0 6px 27px;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        &::before {
          top: 14px;
        }
      }
    }
  }
  .report-list {
    .row {
      > div {
        width: calc(25% - 62px);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        a {
          display: block;
          width: 100%;
        }
      }
    }
    .img {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 124.4%;
      margin-bottom: 30px;
      border: 1px solid #E5E5E5;
      overflow: hidden;
      img {
        display:block;
        width:100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .txt {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      span {
        display: block;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .report-top {
      flex-wrap: wrap;
      margin-bottom: 100px;
      justify-content: center;
      .btn-download {
        width: 100%;
        justify-content: center;
      }
      .img {
        width: calc(100% - 20px);
        margin: 0;
        padding-bottom: 112.266%;
      }

      .txt {
        padding-top: 50px;
        .tit {
          font-size: 24px;
          line-height: 34px;
        }
        li {
          font-size: 16px;
          line-height: 26px;
          padding: 0 0 10px 27px;
          &::before {
            top: 11px;
          }
        }
      }
    }
    .report-list {
      .row {
        > div {
          width: calc((100% - 15px) / 2);
          margin-bottom: 60px;
          .img {
            -o-object-fit: cover;
            object-fit: cover;
          }
          &:nth-child(odd) {
            margin-right: 15px;
          }
          &:nth-child(n + 3):nth-child(-n + 4) {
            margin-bottom: 0;
          }
        }
      }
      .txt {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  //page
  .page-box {
    margin-top: 80px;
  }
  @include media-breakpoint-down(lg) {
    .page-box {
      margin-top: 24px;
    }
  }
}
