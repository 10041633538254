.modal-dialog {
  &.modal-lg {
    width: 100%;
    max-width: 980px;
  }
  &.modal-xl {
    width: 100%;
    max-width: 1120px;
  }
  &.brand-tv {
    @include media-breakpoint-up(lg) {
      .modal-content {
        .modal-header {
          padding: 50px 46px 0;
          border-bottom: 0;
          .btn {
            position: absolute;
            right: -74px;
            top: 0;
            border: 1px solid $white;
            border-color: #dcdcdc !important;
          }
        }
        .modal-title {
          font-weight: 700;
          font-size: 38px;
          color: #111;
          line-height: 60px;
        }
        .modal-body {
          padding: 0 46px 72px;
          margin-top: 20px;
        }
        .swiper-slide {
          height: auto;
          padding-bottom: unset;
        }
        .content-area {
            width: 100%;
            height: 495px;
            aspect-ratio: 878 / 495;
            position: relative;
            .thumbnail-wrap {
              position: relative;
              width: 100%;
              height: 100%;
              z-index: 1;
              img {
                  width: 100%;
                  height: 100%;
              }
              .play-btn {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
              }
            }
            iframe.video {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        .content-desc {
          color: $dark-gray-1;
          font-weight: 500;
          margin-top: 2rem;
        }
        .swiper-button-prev {
          width: 48px;
          height: 48px;
          left: 4%;
          top: 43.5%;
          transform: translateY(-50%);
          &::after {
            content: none;
          }
        }
        .swiper-button-next {
          width: 48px;
          height: 48px;
          right: 4%;
          top: 44.5%;
          transform: translateY(-50%);
          &::after {
            content: none;
          }
        }
        .web-pagination {
          display: block;
        }
        .mo-pagination {
          display: none;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      height: 100%;
      margin: 0;
      .modal-content {
        width: 100%;
        height: 100%;
        margin: 0;
        border: 0;
        border-radius: 0;
        overflow-x: scroll;
        .modal-header {
          padding: 70px 18px 20px;
          border-bottom: 0;
          .btn {
            position: absolute;
            right: 12px;
            top: 10px;
            background-color: transparent !important;
            border-color: transparent !important;
            .icon-close {
              background-image: url("../images/icon-modal-close.svg");
              width: 16px;
              height: 16px;
            }
          }
        }
        .modal-title {
          font-weight: 700;
          font-size: 24px;
          color: $dark-gray-2;
          line-height: 26px;
        }
        .modal-body {
          padding: 0;
        }
        .swiper-slide {
          height: auto;
        }
        .content-desc {
          color: $dark-gray-1;
          font-weight: 500;
          line-height: 25px;
          padding: 40px 18px 0;
        }
        .content-area {
            position: relative;
            width: 100%;
            aspect-ratio: 375 / 210;
            .thumbnail-wrap {
              position: relative;
              width: 100%;
              height: 100%;
              z-index: 1;
              img {
                  width: 100%;
                  height: 100%;
              }
              .play-btn {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
              }
            }
            iframe.video {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        .brand-modal-swiper {
          padding-bottom: 100px;
        }
        .swiper-button-prev {
          width: 48px;
          height: 48px;
          left: 4%;
          &::after {
            content: none;
          }
          .btn {
            color: $dark-gray-2 !important;
            background-color: $white !important;
            border-color: $light-gray-1 !important;
          }
        }
        .brand-modal-next {
          width: 48px;
          height: 48px;
          right: 4%;
          &::after {
            content: none;
          }
          .btn {
            color: $dark-gray-2 !important;
            background-color: $white !important;
            border-color: $light-gray-1 !important;
          }
        }
        .web-pagination {
          display: none;
        }
        .mo-pagination {
          display: block;
          position: absolute;
          width: 120px;
          height: 50px;
          bottom: 10px;
          left: 10px;
        }
      }
    }
  }
  &.brand-images {
    .image-thumbnail {
      position: relative;
      .swiper-slide {
        filter: grayscale(100%);
        .txt {
          display: none;
        }
        &:hover,
        &:focus {
          filter: inherit;
          transition: 0.3s;
        }
        &.swiper-slide-thumb-active {
          filter: inherit;
          .txt {
            width: 100%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $white;
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .modal-content {
        .modal-header {
          padding: 50px 50px 0;
          border-bottom: 0;
          .btn {
            position: absolute;
            right: -74px;
            top: 0;
            border: 1px solid $white;
            border-color: #dcdcdc !important;
            .icon-close {
              background-image: url("../images/icon-close-white.svg");
              width: 16px;
              height: 16px;
            }
          }
        }
        .modal-title {
          font-weight: 700;
          font-size: 38px;
          color: #111;
          line-height: 60px;
        }
        .modal-body {
          padding: 0 50px 50px;
          margin-top: 20px;
          .modal-detail-thumbnail {
            display: flex;
            gap: 42px;
          }
        }
        .image-modal-swiper {
          width: 77%;
          .content-area {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            overflow:hidden;
            img {
              display:block;
              width:100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 50%; left: 50%;
              transform: translate(-50%,-50%);
            }
          }
          .content-desc {
            font-weight: 500;
            color: #111;
            font-size: 16px;
            line-height: 19px;
            margin-top: 2rem;
          }
        }
        .image-thumbnail {
          width: 23%;
          height: 540px;
          .swiper-slide {
            height: 117px;
            overflow: hidden;
            img {
              display:block;
              width:100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 50%; left: 50%;
              transform: translate(-50%,-50%);
            }
          }
          &::-webkit-scrollbar {
            width: 10px; /* 스크롤바의 너비 */
          }

          &::-webkit-scrollbar-thumb {
            background: #dadada; /* 스크롤바의 색상 */
            border-radius: 0;
          }

          &::-webkit-scrollbar-track {
            background: #f5f5f5; /*스크롤바 뒷 배경 색상*/
          }
          .swiper-scrollbar {
            right: 0;
            top: 0;
            height: 100%;
            border-radius: 0;
            background-color: #f5f5f5;
            width: 10px;
            .swiper-scrollbar-drag {
              width: 10px;
              border-radius: 0;
              background: #dadada;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      height: 100%;
      margin: 0;
      .modal-content {
        width: 100%;
        height: 100%;
        margin: 0;
        border: 0;
        border-radius: 0;
        .modal-header {
          padding: 70px 18px 20px;
          border-bottom: 0;
          .btn {
            position: absolute;
            right: 12px;
            top: 10px;
            background-color: transparent !important;
            border-color: transparent !important;
            .icon-close {
              background-image: url("../images/icon-modal-close.svg");
              width: 16px;
              height: 16px;
            }
          }
        }
        .modal-title {
          font-weight: 700;
          font-size: 24px;
          color: $dark-gray-2;
          line-height: 26px;
        }
        .modal-body {
          padding: 0;
        }
        .image-modal-swiper {
          width: 100%;
          .content-area {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            overflow:hidden;
            img {
              display:block;
              width:100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 50%; left: 50%;
              transform: translate(-50%,-50%);
            }
          }
          .content-desc {
            font-weight: 500;
            color: $dark-gray-1;
            font-size: 16px;
            line-height: 26px;
            margin-top: 2.5rem;
            padding: 0 1rem;
          }
        }
        .image-thumbnail {
          margin-top: 3.8rem;
          padding-left: 1rem;
          .swiper-scrollbar {
            display: none;
          }
          .swiper-slide {
            height: 0;
            padding-bottom: 21%;
            overflow: hidden;
            img {
              display:block;
              width:100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 50%; left: 50%;
              transform: translate(-50%,-50%);
            }
          }
        }
      }
    }
  }

  //product modal
  &.product-view {
    color: $white;

    .modal-content {
      background-color: $dark-gray-1;
      padding: 160px 129px;
      width: 600%;
      .modal-body {
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        width: 600%;
        display: flex;
        flex-wrap: nowrap;
      }
      .table-wrap {
        margin-right: 200px;
        .modal-title {
          margin-bottom: 40px;
          font-weight: 700;
          font-size: 48px;
          line-height: 57px;
        }
        .modal-sub-title {
          margin-bottom: 30px;
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
        }
        table {
          font-size: 16px;
          line-height: 24px;
          thead {
            th {
              color: $light-gray-3 !important;
              background-color: $dark-gray-3 !important;
              text-align: center;
              border-color: $dark-gray-4;
            }
          }
          tbody {
            td {
              color: $light-gray-2;
              text-align: center;
              border-color: $dark-gray-4;
            }
          }
        }
      }
      .btn-dark {
        position: absolute;
        right: 290px;
        top: 100px;
        border-color: $light-gray-1 !important;
      }
      .table-container {
        overflow: visible;
      }
      .product-indicator {
        position: fixed;
        bottom: 0;
        ol {
          display: flex;
          li {
            display: flex;
          }
        }
      }
    }
    .table-info {
      margin: 20px 0 0 56px;
      color: $gray-1;
      font-size: 14px;
      line-height: 22px;
      span {
        margin-left: -56px;
        &::after {
          background: $gray-1;
          width: 5px;
          height: 5px;
          border-radius: 100%;
        }
      }
    }
  }
  &.ojt-program {
    max-width: 884px;
    .modal-content {
      border-radius: 20px;
      .modal-header {
        border-bottom: 0;
        padding: 60px 60px 50px 60px;
        .modal-title {
          font-weight: 700;
          font-size: 34px;
          line-height: 41px;
          color: $dark-gray-1;
          font-family: 'Brand New Hyosung OTF';
          span {
            display: block;
            font-weight: 700;
            font-size: 12px;
            line-height: 8px;
            color: $dark-gray-1;
            margin-top: 6px;
          }
        }
      }
      .modal-body {
        padding: 0 60px 80px;
      }
      .ojt-program-list {
        margin-bottom: 30px;
      }
      .ojt-program-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: $dark-gray-1;
        margin-bottom: 10px;
      }
      .ojt-program-desc {
        > li {
          font-size: 16px;
          line-height: 24px;
          color: $dark-gray-1;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 3px;
            background-color: $dark-gray-1;
            border-radius: 50%;
            top: 10px;
            left: -16px;
          }
        }

        .ojt-desc-depth {
          margin: 14px 0;
          padding-left: 16px;
          li {
            font-size: 16px;
            line-height: 24px;
            color: $dark-gray-1;
            position: relative;
            &.ojt-depth-title {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              color: $dark-gray-1;
              margin-bottom: 6px;
              &::before {
                content: none;
              }
            }
            &::before {
              content: "";
              position: absolute;
              width: 3px;
              height: 3px;
              background-color: $dark-gray-1;
              border-radius: 50%;
              top: 10px;
              left: -16px;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      max-width: 100%;
      height: 100vh;
      margin: 0;
      border: 0;
      .modal-content {
        border-radius: 0;
        height: 100%;
        border: 0;
        overflow-x: scroll;
        .modal-header {
          border-bottom: 0;
          padding: 77px 18px 60px 18px;
          .btn {
            position: absolute;
            top: 10px;
            right: 0;
          }
          .modal-title {
            font-size: 28px;
            line-height: 36px;
            span {
              font-weight: 400;
              line-height: 18px;
            }
          }
        }
        .modal-body {
          padding: 0 36px 80px;
        }
        .ojt-program-title {
          font-size: 22px;
          line-height: 32px;
        }
        .ojt-program-desc {
          > li {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

.cate {
  a {
    font-size: 12px;
    color: #999999;
  }
  &:first-child {
    a {
      font-size: 18px;
      font-weight: bold;
      color: #555555;
    }
  }
}
.end1 {
  width: 30%;
  div {
    margin-bottom: 14px;
    font-size: 18px;
    color: #555555;
  }
  span {
    font-size: 12px;
    color: #555555;
  }
}
.end_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  padding: 40px 0;
  border-top: 1px solid #a0a0a0;
  border-bottom: 1px solid #a0a0a0;
}
