.privacy-visual {
  .content-head {
    padding: 160px 18px 100px;
    max-width: 1336px;
    margin: 0 auto;
  }
  .content-title {
    color: $dark-gray-1 !important;
    font-size: 48px;
    line-height: 57px;
  }
  .content-desc {
    font-size: 18px;
    line-height: 32px;
    color: $dark-gray-3;
    margin-top: 40px;
  }
  @include media-breakpoint-down(lg) {
    .content-title {
      font-size: 34px;
      line-height: 41px;
    }
    .content-desc {
      font-size: 16px;
      line-height: 26px;
      margin-top: 30px;
    }
  }
  .legal-head {
    padding: 160px 30px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-desc {
      color: #444;
    }
    @include media-breakpoint-down(lg) {
      padding: 40px 18px 120px;
      .content-desc {
        word-break: normal;
        margin-top: 60px;
        color: #333;
      }
    }
  }
  .legal-detail {
    padding-bottom: 180px;
    .content {
      margin-bottom: 100px;
      .content-title {
        font-size: 34px;
        line-height: 41px;
        margin-bottom: 30px;
      }
      .desc {
        font-size: 18px;
        line-height: 32px;
        color: #444;
      }
    }
    p {
      font-size: 18px;
      line-height: 32px;
      color: #444;
    }

    @include media-breakpoint-down(lg) {
      padding-bottom: 200px;
      .content {
        .content-title {
          font-size: 24px;
        }
        .desc {
          font-size: 16px;
          line-height: 26px;
          color: #333;
          word-break: normal;
        }
      }
      p {
        font-size: 16px;
        line-height: 26px;
        color: #333;
      }
    }
  }
}
.privacy-content {
  padding: 20px 0 180px;
  .acco-desc {
    margin-top: 100px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 32px;
    color: $dark-gray-3;
  }
  @include media-breakpoint-down(lg) {
    .acco-desc {
      margin-top: 50px;
      font-size: 16px;
      line-height: 26px;
    }
  }
  .nav-tabs {
    &.sub-tabs {
      justify-content: center;
      gap: 60px;
      margin-top: 100px;
      .nav-item {
        width: 120px;
        .nav-link {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          margin: 0 auto;
          &.active {
            background-color: $red;
            .icon-collection {
              background-image: url("../images/icon-collection-active.svg");
              background-size: unset;
            }
            .icon-use {
              background-image: url("../images/icon-use-active.svg");
              background-size: unset;
            }
            .icon-offer {
              background-image: url("../images/icon-offer-active.svg");
              background-size: unset;
            }
            .icon-destruction {
              background-image: url("../images/icon-destruction-active.svg");
              background-size: unset;
            }
          }
        }
      }
      .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: $dark-gray-4;
        margin-top: 30px;
      }
      @include media-breakpoint-down(lg) {
        flex-flow: wrap;
        gap: 50px;
        margin-top: 50px;
        .title {
          line-height: 32px;
          margin-top: 14px;
        }
      }
    }
  }
  .sub-tab-content {
    margin-top: 100px;
  }
  .privacy-sub-wrap {
    padding-top: 60px;
    .privacy-sub-title {
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      text-align: center;
      color: $dark-gray-1;
    }
    .privacy-sub-desc {
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      color: $dark-gray-3;
      margin-top: 30px;
    }
    @include media-breakpoint-down(lg) {
      padding-top: 0;
      .privacy-sub-title {
        font-size: 24px;
        line-height: 34px;
        text-align: left;
      }
      .privacy-sub-desc {
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        margin-top: 14px;
      }
    }
  }

  .privacy-content-box {
    margin-top: 100px;
    display: flex;
    gap: 40px;
    .privacy-content-txt {
      width: 65%;
    }
    .privacy-content-title {
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      color: $dark-gray-4;
    }
    .privacy-content-desc {
      font-size: 18px;
      line-height: 32px;
      color: $dark-gray-4;
      margin-top: 20px;
    }
    .privacy-content-ex {
      font-size: 12px;
      line-height: 20px;
      color: $gray-1;
    }
    @include media-breakpoint-down(lg) {
      margin-top: 37px;
      gap: 30px;
      .privacy-content-txt {
        width: 55%;
      }
      .privacy-content-title {
        font-size: 22px;
        line-height: 32px;
      }
      .privacy-content-desc {
        font-size: 16px;
        line-height: 26px;
        margin-top: 20px;
      }
      .privacy-content-ex {
        line-height: 22px;
      }
    }
  }
}
.process-accordion {
  &.privacy {
    .accordion-body li {
      justify-content: flex-start !important;
      .link-txt {
        text-decoration: underline;
      }
    }
    .text-wrap {
      padding: 56px 28px;
      border: 0;

      .select-wrap {
        display: flex;
        align-items: center;
        gap: 20px;
        .selectbox {
          position: relative;
          border: 1px solid #e5e5e5;
          border-radius: 24px;
          cursor: pointer;

          .privacy-select {
            display: flex;
            gap: 24px;
            align-items: center;
            border: 0 none;
            outline: 0 none;
            background: transparent;
            cursor: pointer;
            padding: 12px 30px 12px 30px;
            .icon-down {
              transition: 0.3s;
            }
            color: #333;
          }

          .optionlist {
            position: absolute;
            top: 55px;
            left: 5%;
            width: 90%;
            color: #777;
            padding: 0;
            background-color: #fff;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 0;
            transition: 0.3s ease-in;
            border: 1px solid #ddd;
            opacity: 0;
            filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.25));

            &::-webkit-scrollbar {
              width: 4px;
            }
            &::-webkit-scrollbar-track {
              background-color: #ddd;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #161618;
            }

            .optionitem {
              border-bottom: 1px solid #e5e5e5;
              padding: 8px 20px;
              transition: 0.1s;

              &:hover {
                background-color: #f5f5f5;
                transition-duration: 0.3s;
              }

              &:last-child {
                border-bottom: 0 none;
              }
            }
          }

          &.active {
            border: 1px solid #161618;
            .privacy-select {
              .icon-down {
                transform: rotate(-180deg);
              }
            }
            .optionlist {
              max-height: 200px;
              opacity: 1;
            }
          }
        }
        .btn {
          flex-shrink: 0;
        }
      }
    }
    .desc {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: $dark-gray-4;
      padding: 5px 0;
    }
    .privacy-list {
      > li {
        display: block;
        border-bottom: 0;
        padding: 20px 0;
      }
      .privacy-list-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $dark-gray-3;
        padding: 5px 0;

        margin-left: 17px;
        text-indent: -17px;
      }
      .privacy-list-desc {
        li {
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
          color: $dark-gray-4;
          position: relative;
          margin-top: 5px;
          border-bottom: 0;
        }
        &.old {
          li {
            display: flex;
            flex-flow: column;
            justify-content: start;
            align-items: inherit;
            span:nth-child(2) {
              margin-left: 15px;
            }
            &::before {
              content: none;
            }
          }
        }
        &.type-1 {
          li {
            padding: 0 0 0 24px;
            &::before {
              content: "";
              position: absolute;
              width: 3px;
              height: 3px;
              background-color: $dark-gray-3;
              border-radius: 50%;
              top: 10px;
              left: 12px;
            }
          }
        }
        &.type-2 {
          li {
            padding: 0 0 0 15px;
            margin-left: 13px;
            text-indent: -16px;
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .text-wrap {
        padding: 30px 0;
      }
      .desc {
        line-height: 26px;
      }
      .privacy-list-title {
        line-height: 26px;
      }
      .privacy-list-desc {
        li {
          line-height: 26px;
        }
      }
    }
  }
}
