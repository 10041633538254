@font-face {
  font-family:'Pretendard';
  font-weight: 100;
  font-style: normal;
  src: url('../fonts/Pretendard/Pretendard-Thin.woff') format('woff'),
  url('../fonts/Pretendard/Pretendard-Thin.ttf') format('TrueType'),
  url('../fonts/Pretendard/Pretendard-Thin.otf') format('OpenType')
}
@font-face {
  font-family:'Pretendard';
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/Pretendard/Pretendard-ExtraLight.woff') format('woff'),
  url('../fonts/Pretendard/Pretendard-ExtraLight.ttf') format('TrueType'),
  url('../fonts/Pretendard/Pretendard-ExtraLight.otf') format('OpenType')
}
@font-face {
  font-family:'Pretendard';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Pretendard/Pretendard-Light.woff') format('woff'),
  url('../fonts/Pretendard/Pretendard-Light.ttf') format('TrueType'),
  url('../fonts/Pretendard/Pretendard-Light.otf') format('OpenType')
}
@font-face {
    font-family:'Pretendard';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/Pretendard/Pretendard-Regular.woff') format('woff'),
    url('../fonts/Pretendard/Pretendard-Regular.ttf') format('TrueType'),
    url('../fonts/Pretendard/Pretendard-Regular.otf') format('OpenType')
}
@font-face {
    font-family:'Pretendard';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/Pretendard/Pretendard-Medium.woff') format('woff'),
    url('../fonts/Pretendard/Pretendard-Medium.ttf') format('TrueType'),
    url('../fonts/Pretendard/Pretendard-Medium.otf') format('OpenType')
}
@font-face {
    font-family:'Pretendard';
    font-weight: 600;
    font-style: normal;
    src: url('../fonts/Pretendard/Pretendard-SemiBold.woff') format('woff'),
    url('../fonts/Pretendard/Pretendard-SemiBold.otf') format('OpenType')
}
@font-face {
    font-family:'Pretendard';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/Pretendard/Pretendard-Bold.woff') format('woff'),
    url('../fonts/Pretendard/Pretendard-Bold.ttf') format('TrueType'),
    url('../fonts/Pretendard/Pretendard-Bold.otf') format('OpenType')
}
@font-face {
    font-family:'Pretendard';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/Pretendard/Pretendard-ExtraBold.woff') format('woff'),
    url('../fonts/Pretendard/Pretendard-ExtraBold.ttf') format('TrueType'),
    url('../fonts/Pretendard/Pretendard-ExtraBold.otf') format('OpenType')
}

// 효성 브랜드 폰트
@font-face {
  font-family: 'Brand New Hyosung OTF';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Hyosung/Brand-New-Hyosung-Light.woff') format('woff'),
  url('../fonts/Hyosung/Brand-New-Hyosung-Light.ttf') format('TrueType'),
  url('../fonts/Hyosung/Brand-New-Hyosung-Light.otf') format('OpenType'),
  url('../fonts/Hyosung/Brand-New-Hyosung-Light.eot') format('embedded-opentype')
}
@font-face {
  font-family: 'Brand New Hyosung OTF';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Hyosung/Brand-New-Hyosung-Medium.woff') format('woff'),
  url('../fonts/Hyosung/Brand-New-Hyosung-Medium.ttf') format('TrueType'),
  url('../fonts/Hyosung/Brand-New-Hyosung-Medium.otf') format('OpenType'),
  url('../fonts/Hyosung/Brand-New-Hyosung-Medium.eot') format('embedded-opentype')
}
@font-face {
  font-family: 'Brand New Hyosung OTF';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Hyosung/Brand-New-Hyosung-Bold.woff') format('woff'),
  url('../fonts/Hyosung/Brand-New-Hyosung-Bold.ttf') format('TrueType'),
  url('../fonts/Hyosung/Brand-New-Hyosung-Bold.otf') format('OpenType'),
  url('../fonts/Hyosung/Brand-New-Hyosung-Bold.eot') format('embedded-opentype')
}