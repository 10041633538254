.etc-form {
  padding: 110px 0 180px;
  &.etc-form-tc {
    // text-align: center;
    text-align: left;
  }
  .logo {
    margin: 0 0 30px 0;
  }
  .top-text {
    margin-bottom: 50px;
    .icon-caution {
      margin-bottom: 30px;
    }
    h1 {
      margin-bottom: 10px;
      font-weight: 700;
      color: $dark-gray-1;
      font-size: 34px;
      line-height: 41px;
      font-family: 'Brand New Hyosung OTF';
    }
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $gray-1;
    }
  }
  .text {
    margin-bottom: 50px;
    color: $dark-gray-4;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .box {
    padding: 30px 0;
    background-color: $light-gray-3;
    border: 1px solid $light-gray-2;
    font-weight: 400;
    text-align: center;
    p {
      margin-bottom: 10px;
      color: $dark-gray-4;
      font-size: 18px;
      line-height: 32px;
    }
    span {
      font-size: 14px;
      line-height: 22px;
      color: $gray-1;
    }
  }
  .btn-box {
    margin-top: 80px;
    text-align: center;
    .btn {
      color: $dark-gray-1;
      border: 1px solid $dark-gray-1;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 12px 0 200px;
    &.etc-form-tc {
      text-align: left;
    }
    .logo {
      display: block !important;
      margin: 0 0 20px 0;
    }
    .top-text {
      margin-bottom: 30px;
      .icon-caution {
        margin-bottom: 20px;
      }
      h1 {
        font-size: 24px;
        line-height: 34px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .text {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 26px;
    }
    .box {
      padding: 30px 0 30px 40px;
      text-align: left;
      p {
        font-size: 14px;
        line-height: 22px;
      }
      span {
        font-size: 12px;
      }
    }
    .btn-box {
      .btn {
        width: 100%;
        padding: 15px 0;
      }
    }
  }
}
