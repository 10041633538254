.vision-wrap{height: 100vh;width:100%;overflow: hidden;}

.vision-section{
  position: absolute;
  width: 100%;height: 100vh;
  .bg{
    width: 100%;height: 100%;
    background-size: cover !important;
    transition: transform .6s ease-in-out;
  }
  .container{position: absolute;left: 0;top: 0;right: 0;bottom: 0;display: flex;flex-direction: column; align-items: center;justify-content: center;}
}

.vision-section-title{color: $white;font-size: 24px;text-align: center;font-weight: 700;margin-bottom: 20px;transform: translateY(100px);opacity: 0;}
.vision-section-desc{color: $white;font-size: 14px;text-align: center;transform: translateY(100px);opacity: 0;}
@include media-breakpoint-up(lg){
  .vision-section-title{font-size: 38px;margin-bottom: 40px;}
  .vision-section-desc{font-size: 24px;}
}

.vision-section-1{
  opacity: 0;transform: translateY(150px);
  clip-path: circle(600px at 50% 50%);
  transition: clip-path .4s ease-in-out;
  .bg{background: url('../images/vision-section-1.png') no-repeat 50% 50%;}
  &.expand{
    clip-path: circle(100% at 50% 50%);transition-duration: .8s;
    .bg{transform: scale(1.15);}
  }
  @include media-breakpoint-down(lg){
    clip-path: circle(calc(50vw - 18px) at 50% 470px);
    .bg{background-image: url('../images/vision-section-1-mo.png');transform: none;}
  }
  @include media-breakpoint-down(sm){
    clip-path: circle(calc(50vw - 18px) at 50% 250px);
  }
}
.vision-section-2{
  opacity: 0;
  .bg{
    background: url('../images/vision-section-2.png') no-repeat 50% 50%;
    @include media-breakpoint-down(lg){
      background-image: url('../images/vision-section-2-mo.png');
    }
  }
}
.vision-section-3{
  opacity: 0;
  .bg{
    background: url('../images/vision-section-3.png') no-repeat 50% 50%;
    @include media-breakpoint-down(lg){
      background-image: url('../images/vision-section-3-mo.png');
    }
  }
}

.vision-bottom{
  background: $dark-gray-3;min-height: 100vh;width: 100%;overflow-x: hidden;
  &.action01 {
    .vision-item-1 { transform: translateX(0);opacity: 1;  }
  }
  &.action02 {
    .vision-item-2 { transform: translateX(0);opacity: 1;  }
    .vision-item-1 { opacity: .2;  }
  }
  &.action03 {
    .vision-item-3 { transform: translateX(0);opacity: 1;  }
    .vision-item-2 { opacity: .2;  }
  }
  &.action04 {
    .vision-item-4 { transform: translateX(0);opacity: 1;  }
    .vision-item-3 { opacity: .2;  }
  }
  .vision-item{
    opacity: .2;transition: all .8s ease-out;
    width: 100%;transform: translateX(100vw);
    .img{
      flex: 0 0 73px;width: 73px;height: 73px;margin-right: 1rem;
      img{object-fit: cover;width: 100%;height: 100%;}
    }
    .txt{
      flex-grow: 1;
      strong{
        display: flex;align-items: flex-start;
        span.title{color: $white;font-size: 22px;font-weight: 700;}
        span.sub{color: $primary;font-size: 14px;font-weight: 500;margin-left: 10px;}
      }
      p { color: $light-gray-2;font-size: 14px;margin-top:15px }
    }
  }
  &.after{
    .vision-item-1,
    .vision-item-2,
    .vision-item-3,
    .vision-item-4 { opacity: 1; }
  }
  @include media-breakpoint-down(lg){
    padding: 100px 18px;
    .position-relative{display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;}
    .vision-item + .vision-item { margin-top:50px }


  }
  @include media-breakpoint-up(lg){
    .vision-item{
      position: absolute;
      .img{
        flex: 0 0 220px;height: 220px;height: 220px;margin-right: 42px;transition: .8s ease;
      }
      .txt{
        strong{
          span{font-size: 28px;}
        }
        p{font-size: 16px;}
      }
      &-1{
        left: 6vw;top: 67vh;
        .img{flex: 0 0 180px;width: 180px;height: 180px;}
      }
      &-2{
        left: 14vw;top: 14vh;
        .img{flex: 0 0 150px;width: 150px;height: 150px;}
      }
      &-3{
        left: 30vw;top: 30vh;
        .img{flex: 0 0 250px;width: 250px;height: 250px;}
      }
      &-4{
        left: 61vw;top: 52vh;
        .img{flex: 0 0 160px;width: 160px;height: 160px;}
      }
    }
    &.after{
      .img{flex: 0 0 220px !important;width: 220px !important;height: 220px !important;}
      .vision-item-1{left: 12vw; top:52vh }
      .vision-item-2{left: 23vw; top:17vh }
      .vision-item-3{left: 54vw; top:36vh }
      .vision-item-4{left: 43vw; top:72vh }
      .txt{
        strong{
          span.title{ font-size: 28px;}
        }
      }
    }
  }
}