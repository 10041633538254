@keyframes background-scale {
  0% {
    background-size: 125% 125%;
  }
  100% {
    background-size: 100% 100%;
  }
}
.customer-visual {
  height: 460px;
  position: relative;
  overflow: hidden;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("../images/customer-visual.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    animation: background-scale 0.8s ease-in Alternate;
  }
  .content-title {
    font-size: 48px;
    line-height: 57px;
    color: #fff;
  }
  .content-desc {
    font-size: 16px;
    line-height: 30px;
    margin-top: 30px;
    color: #fff;
    z-index: 1;
  }

  @include media-breakpoint-down(lg) {
    &.case-1 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    height: 200px;
    .content-title {
      font-size: 34px;
      line-height: 41px;
      text-align: center;
    }
    .content-title.case-1 {
      font-size: 26px;
      line-height: 31px;
    }
    .content-desc {
      line-height: 26px;
      margin-top: 10px;
      text-align: center;
    }
  }
}
.customer-content {
  &.accordion {
    padding: 160px 0 180px;
    @include media-breakpoint-down(lg) {
      padding: 50px 0 200px;
    }
  }
}
.process-accordion {
  .accordion-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 30px;
    margin-bottom: -1px;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    line-height: 24px;
    color: $dark-gray-4;
    background-color: $light-gray-3;
    border: 1px solid $light-gray-1;
    &::after {
      display: inline-block;
      width: 48px;
      height: 48px;
      content: "";
      border: 1px solid $light-gray-1;
      border-radius: 100%;
      background-image: url("../images/icon-plus.svg");
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: center;
      transition: 0s;
    }
    &.active {
      background: $dark-gray-4;
      color: $white;
      &::after {
        background-image: url("../images/icon-minus.svg");
        background-color: $light-gray-3;
        border-color: $dark-gray-2;
      }
    }
  }
  .accordion-body {
    display: none;
    background-color: $white;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 42px;
      border-bottom: 1px solid $light-gray-3;
      &:last-child {
        border-bottom: 0;
      }
      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $dark-gray-3;
      }
    }
    button {
      border: 1px solid $dark-gray-1;
    }
  }
  @include media-breakpoint-down(lg) {
    .accordion-item {
      .accordion-button {
        padding: 20px;
        font-size: 18px;
        line-height: 22px;
        &::after {
          width: 30px;
          height: 30px;
        }
      }
    }
    .accordion-body {
      padding: 0 20px;
      li {
        padding: 40px 0;
        p {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
    &.privacy {
      .accordion-body {
        padding: 0;
      }
    }
  }
}
.inquiry-list {
  li {
    display: flex;
    padding: 30px;
    border-bottom: 1px solid $light-gray-3;
    &:last-child {
      border-bottom: 0;
    }
    .title {
      width: 20%;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $gray-3;
    }
    .desc {
      width: 80%;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: $dark-gray-3;
    }
    @include media-breakpoint-down(lg) {
      display: block;
      .title {
        width: 100%;
        display: inline-block;
        font-size: 14px;
        line-height: 22px;
      }
      .desc {
        width: 100%;
        display: inline-block;
        font-size: 16px;
        line-height: 26px;
        margin-top: 10px;
      }
    }
  }
}

.notice-content {
  padding: 160px 0 180px;
  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    color: $dark-gray-1;
  }
  h2 {
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    color: $dark-gray-1;
  }
  .desc {
    font-size: 18px;
    line-height: 32px;
    color: $dark-gray-4;
    margin-top: 30px;
  }
  .form-container {
    margin-top: 100px;
  }
  @include media-breakpoint-down(lg) {
    padding: 100px 0 200px;
    h1 {
      font-size: 34px;
      line-height: 41px;
    }
    h2 {
      font-size: 24px;
      line-height: 34px;
    }
    .desc {
      font-size: 16px;
      line-height: 26px;
      color: $dark-gray-3;
    }
  }
}
