.search-bar{
    display: flex;
    align-items: center;
    .btn-sm {width: 100%;}
    .form-control{
        min-width: 350px;
        background-color: $light-gray-2;
        color: $dark-gray-1;
        border-color: $light-gray-2;
        &::placeholder{
            color: $gray-2;
        }
        &:disabled{
            background-color: $light-gray-3;
            color: $gray-2;
            border-color: $light-gray-3;
        }
    }
    @include media-breakpoint-down(lg){
        .form-control{
            min-width: 200px;
            max-height: 50px;
            padding: 14px 20px;
            font-size: 14px;
        }
        .btn-md{
            padding: 14px 23px;
            max-height: 50px;
            font-size: 14px;
        }
    }
}
